import { Helmet } from 'react-helmet-async';
import { useLocales } from 'src/locales';
import { useWorkspace } from 'src/providers/workspace';
import { useParams } from 'src/routes/hooks';
// sections
import HomeView from 'src/sections/overview/dashboards/views/dashboard-general';

// ----------------------------------------------------------------------

export default function Home() {
  useWorkspace(true)

  const {t} = useLocales()
  return (
    <>
      <Helmet>
        <title>{t('home')}</title>
      </Helmet>

      <HomeView />
    </>
  );
}
