import { useCallback, useEffect, useMemo, useRef } from "react";
import { useCardsQuery, useChartsQuery, useConfigQuery, useListQuery, useMetricsQuery, usePushConfigMutation, useTableQuery } from "src/context/api/explorer";
import { Explorer } from "src/context/api/explorer/models";
import { useCurrentWorkspace } from "src/context/reducers/app-settings";
import { getOptions, selectDatePreset, setDateSelection, setEnd, setStart, storeObjects, useAttributionOptions } from "src/context/reducers/attribution-settings";
import { useParams, useRouter, useSearchParams } from "src/routes/hooks";
import { isEqual } from "lodash";
import { AttributionWindow } from "src/context/api/shared/models";
import { useDispatch, useSelector } from "react-redux";
import { selectAdGroup, selectAdType, selectDraft, selectTagIds, setAdGroup, setAdType, setDraftAction } from "src/context/reducers/explorer/slice";
import { useCurrentUserQuery } from "src/context/api/auth";
import { ResourceDepth } from "src/components/generic-select/resource-select";
import { presetValues } from "./use-date-preset";
import { fDate } from "src/utils/format-time";
import { deepClone } from "@mui/x-data-grid/utils/utils";

interface RouteParams {
  name: string;
}

export type Metric = Explorer.Filter & { output: string; }
type Section = keyof Pick<Explorer.Config, "charts" | "cards" | "table">

export default function useExplorerConfiguration() {
  const dispatch = useDispatch()
  const { name } = useParams() as Readonly<RouteParams>
  const { workspace, adAccountId } = useExplorerQuery()
  const searchParams = useSearchParams()
  const { data: config, isLoading, isFetching, isUninitialized, ...configState } = useConfigQuery({ workspace, adAccountId, name, mode: searchParams.get('mode') as "analysis" | "comparison" }, {
    skip: !name || !workspace || !adAccountId
  })

  useEffect(() => {
    if (!isUninitialized && !isFetching && !isLoading) {
      configState.refetch()
    }
  }, [name, workspace, adAccountId])

  const { data: metrics_ } = useMetricsQuery({ type: "metrics" })
  const user = useCurrentUserQuery()
  const draft = useSelector(selectDraft)

  const { update, duplicate } = useMutateConfig(config as Explorer.Config)

  const editable = useMemo(() => {
    return typeof config?.owner === "number" ? config?.owner === user.data?.id : config?.owner?.id === user.data?.id
  }, [config, user])

  const setDraft = useCallback((draft_: Explorer.Config) => {
    dispatch(setDraftAction(draft_))
  }, [dispatch])

  useEffect(() => {
    if (draft && !isEqual(draft, config)) {
      setDraft(draft)
    }
  }, [config, draft])

  const metrics = useCallback((section: Section) => {
    return config?.[section].metrics
  }, [config])


  const output = useCallback((section: Section) => metrics(section)?.map(m => m.output), [metrics])

  const tableHEAD = useMemo(() => [{
    id: "name",
    label: "AD",
    sort: true,
    format: (row: any) => row.body.title || row?.body.name.replace(/\d+-\d+-\d+-.+$/, ""),
    alignRight: false,
    width: 250,
  }].concat(metrics("table")?.map((t) => ({
    id: t.output,
    label: t.name,
    sort: true,
    format: true,
    alignRight: true,
    width: 250,
  })) || [] as any), [metrics])

  return {
    loading: isLoading || isFetching || isUninitialized,
    raw: config as Explorer.Config,
    metrics,
    output,
    filters: metrics_ || [],
    tableHEAD,
    editable,
    update,
    duplicate,
    draft,
    setDraft,
  }
}

const useExplorerFilters = (config: Explorer.Config) => {
  const attributionWindow = useAttributionOptions("attributionWindow") as AttributionWindow
  const dateSelection = useAttributionOptions("dateSelection") as "custom" | "preset"
  const datePreset = useAttributionOptions("datePreset") as string
  const start = useAttributionOptions("start") as string
  const end = useAttributionOptions("end") as string
  const adAccountId = useAttributionOptions("account")?.[0] as string
  const campaign = useAttributionOptions("campaigns") as string[]
  const adsets = useAttributionOptions("adsets") as string[]
  const ads = useAttributionOptions("ads") as string[]
  return {
    attributionWindow,
    dateSelection,
    datePreset,
    start,
    end,
    adAccountId,
    campaign,
    adsets,
    ads,
  }
}

const useMutateConfig = (config: Explorer.Config) => {
  const dispatch = useDispatch()
  const { workspace, adAccountId, name, attributionWindow, adType, group } = useExplorerQuery()
  const filters = useExplorerFilters(config)
  const [pushConfig, pushConfigResult] = usePushConfigMutation()
  const { refetch: refetchList } = useListQuery({ workspace })
  const router = useRouter()

  const update = useCallback(async (draft_: Explorer.Config | undefined = undefined) => {
    const config_ = deepClone(draft_ || config)
    config_.filter = config_.filter ? config_.filter :
      {
        account: [
          ""
        ],
        ad_group: "creative",
        ad_type: [
          "carousel",
          "video",
          "image"
        ],
        attribution_window: [
          "7d_click",
          "1d_view"
        ],
        date_preset: "last_14d",
        date_selected: "preset",
        end: "2024-10-21",
        resource_id: [
          ""
        ],
        resource_type: "account",
        start: "2024-07-23"
      }
    config_.filter.date_preset = filters.datePreset
    config_.filter.date_selected = filters.dateSelection
    config_.filter.start = filters.start
    config_.filter.end = filters.end
    config_.filter.ad_type = adType
    config_.filter.ad_group = group
    config_.filter.attribution_window = attributionWindow
    config_.filter.campaign = filters.campaign
    config_.filter.adset = filters.adsets
    config_.filter.ad = filters.ads

    return pushConfig({ workspace, adAccountId, name, attributionWindow, config: config_ as any }).unwrap().then((response) => {
      if (name !== response.url) {
        router.replace(`/explore/${response.url}`)
      }
    })

  }, [config, dispatch, pushConfig, workspace, adAccountId, name, attributionWindow, refetchList, filters])

  const duplicate = useCallback(async (newConfig: any) => {
    return pushConfig({ workspace, adAccountId, name, attributionWindow, config: newConfig || config as any, action: "duplicate" }).unwrap()
      .then((response) => {
        if (name !== response.url) {
          router.replace(`/explore/${response.url}`)
        }
      })
  }, [config, dispatch, pushConfig, workspace, adAccountId, name, attributionWindow, refetchList])

  return {
    update,
    duplicate,
  }
}

export const useProcessConfiguration = (config: Explorer.Config) => {
  const dispatch = useDispatch()
  const { attributionWindow, dateSelection, datePreset, start, end, adAccountId } = useExplorerFilters(config)
  const adType = useSelector(selectAdType) as string[]
  const group = useSelector(selectAdGroup) as string

  const hasProcessedConfig = useRef(false)

  useEffect(() => {
    hasProcessedConfig.current = false
  }, [config])

  useEffect(() => {
    if (!config) {
      return
    }
    dispatch(storeObjects(["ads", config?.filter?.[ResourceDepth.AD]]))
    dispatch(storeObjects(["adsets", config?.filter?.[ResourceDepth.ADSET]]))
    dispatch(storeObjects(["campaigns", config?.filter?.[ResourceDepth.CAMPAIGN]]))
    dispatch(storeObjects(["account", [config?.account_fid]]))

    const { start, end, date_preset, date_selected } = config?.filter || {}

    if (start && end && date_selected) {
      dispatch(setStart(start))
      dispatch(setEnd(end))
      dispatch(setDateSelection(date_selected))
    }
    else if (date_preset) {
      dispatch(selectDatePreset(date_preset))
      dispatch(setDateSelection("preset"))
    }
    else {
      dispatch(setStart(fDate(presetValues.last_14d.start, "yyyy-MM-dd")))
      dispatch(setEnd(fDate(presetValues.last_14d.end, "yyyy-MM-dd")))
      dispatch(selectDatePreset("last_14d"))
      dispatch(setDateSelection("preset"))
    }

    dispatch(setAdType(config?.filter?.ad_type || ["CAROUSEL", "VIDEO", "IMAGE"]))
    dispatch(setAdGroup(config?.filter?.ad_group || "creative"))

  }, [config])

  useEffect(() => {
    if (!config) {
      return
    }
    const configHasDate = config?.filter?.start && config?.filter?.end
    const configHasDatePreset = config?.filter?.date_preset
    const configHasAdType = config?.filter?.ad_type
    const configHasAdGroup = config?.filter?.ad_group

    const hasProcessedDate = !configHasDate || (config?.filter?.start === start) && (config?.filter?.end === end)
    const hasProcessedDatePreset = !configHasDatePreset || (config?.filter?.date_preset === datePreset)
    const hasProcessedAdType = !configHasAdType || (config?.filter?.ad_type === adType)
    const hasProcessedAdGroup = !configHasAdGroup || (config?.filter?.ad_group === group)

    if (!hasProcessedConfig.current && !hasProcessedDate && !hasProcessedDatePreset && !hasProcessedAdType && !hasProcessedAdGroup) {
      return
    }
    hasProcessedConfig.current = true
  }, [attributionWindow, dateSelection, datePreset, start, end, adAccountId, adType, group, config])

  return {
    processed: hasProcessedConfig.current
  }
}

const useExplorerQuery = () => {
  const { name } = useParams() as { name: string }
  const workspace = useCurrentWorkspace()?.id as number
  const { resourceIds, resourceType, adAccountId, start, end, attributionWindow } = getOptions()

  const adType = useSelector(selectAdType) as string[]
  const group = useSelector(selectAdGroup) as string

  return {
    workspace,
    name,
    resourceIds,
    resourceType,
    adAccountId,
    startDate: start,
    endDate: end,
    attributionWindow,
    adType,
    group,
  }
}

export function useExplorer() {
  const config = useExplorerConfiguration()

  const tagIds = useSelector(selectTagIds)

  const queryOptions = useExplorerQuery()

  const hasMissingOptions = useMemo(() => {
    return Object.values(queryOptions).some(value => !value)
  }, [queryOptions])

  const table = useTableQuery({ ...queryOptions, tagIds, mode: config.raw?.mode }, {
    skip: hasMissingOptions,
    refetchOnMountOrArgChange: true,
  })
  const analytics = useChartsQuery({ ...queryOptions, tagIds, mode: config.raw?.mode }, {
    skip: hasMissingOptions,
    refetchOnMountOrArgChange: true,
  })
  const cards = useCardsQuery({ ...queryOptions, tagIds, mode: config.raw?.mode }, {
    skip: hasMissingOptions,
    refetchOnMountOrArgChange: true,
  })

  const url = useMemo(() => {
    return config.raw?.url
  }, [config.raw])

  return {
    table,
    analytics,
    cards,
  }
}