import React, { useState, useMemo, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Popover,
  DialogActions,
  Stack,
} from '@mui/material';
import Scrollbar from '../scrollbar';
import Iconify from '../iconify/iconify';
import { t } from 'i18next';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { adTagged, Tags, useAddAdMutation, useCreateTagMutation, useDeleteTagMutation, useEditTagMutation, useGetTagsQuery, useRemoveAdMutation } from 'src/context/api/tags';

const PREDEFINED_COLORS = [
  { bg: '#BBDEFB', text: '#1565C0', hover: '#90CAF9', value: 'blue' },
  { bg: '#E1BEE7', text: '#6A1B9A', hover: '#CE93D8', value: 'purple' },
  { bg: '#C8E6C9', text: '#2E7D32', hover: '#A5D6A7', value: 'green' },
  { bg: '#FFE0B2', text: '#E65100', hover: '#FFCC80', value: 'orange' },
  { bg: '#F8BBD0', text: '#AD1457', hover: '#F48FB1', value: 'pink' },
  { bg: '#FFCDD2', text: '#B71C1C', hover: '#EF9A9A', value: 'red' },
  { bg: '#FFF9C4', text: '#F57F17', hover: '#FFF59D', value: 'yellow' },
  { bg: '#C5CAE9', text: '#283593', hover: '#9FA8DA', value: 'indigo' },
];

interface tagToEdit {
  tagId: number
  old: string
  new: string
}

interface TagManagementModalProps {
  externalAdId: string
}

const TagManagementModal: React.FC<TagManagementModalProps> = ({
  externalAdId,
}) => {
  const workspace = useCurrentWorkspace();

  const { data: tags } = useGetTagsQuery({
    workspace: workspace?.id || -1,
  });

  const [open, setOpen] = useState<boolean>(false);

  const [newTag, setNewTag] = useState<string>('');

  const filteredTags = useMemo(() => {
    if (newTag && newTag != "") {
      return tags?.filter((tag) => tag.name.toLowerCase().includes(newTag.toLowerCase())) || []
    }
    return tags
  }, [tags, newTag]);

  const selectedTagIds = useMemo(() => {
    return (tags || []).reduce((acc, tag) => {
      if (tag.ads_tagged.some((ad: any) => ad.external_id === externalAdId)) {
        acc.push(Number(tag.id))
      }
      return acc;
    }, [] as number[])
  }, [tags])

  const adsByTagId = useMemo(() => {
    return (tags || []).reduce((acc, tag) => {
      acc[tag.id] = tag.ads_tagged
      return acc;
    }, {} as Record<number, adTagged[]>)
  }, [tags])


  const [expandedTagId, setExpandedTagId] = useState<number | null>(null);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [tagIdToDelete, setTagIdToDelete] = useState<number | null>(null);

  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const [tagToEdit, setTagToEdit] = useState<tagToEdit>({ tagId: -1, old: "", new: "" });

  const [colorAnchorEl, setColorAnchorEl] = useState<HTMLElement | null>(null);
  const [activeColorTagId, setActiveColorTagId] = useState<number | null>(null);

  const [createTag] = useCreateTagMutation();
  const [editTag] = useEditTagMutation();
  const [deleteTag] = useDeleteTagMutation();
  const [addAd] = useAddAdMutation();
  const [removeAd] = useRemoveAdMutation();

  const getTagData = (tagId: number) => {
    return (tags || []).find(tag => tag.id === tagId);
  };

  const getTagColor = (colorName: string) => {
    return PREDEFINED_COLORS.find(c => c.value === colorName) || PREDEFINED_COLORS[0];
  };

  const handleColorClick = (tagId: number, event: React.MouseEvent<HTMLElement>) => {
    setColorAnchorEl(event.currentTarget);
    setActiveColorTagId(tagId);
  };

  const handleColorClose = () => {
    setColorAnchorEl(null);
    setActiveColorTagId(null);
  };

  const handleColorChange = (tagId: number, colorValue: string) => {
    editTag({
      workspace: (workspace?.id || -1),
      tag_id: tagId,
      color: colorValue
    })
    handleColorClose();
  };

  const handleKeyDownSearchBar = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addTag(newTag);
    }
  };

  const handleKeyDownEdit = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleEditTag(tagToEdit);
    }
  };

  const unSelectTag = (tagId: number) => {
    removeAd({
      tag_id: tagId,
      object_external_id: externalAdId
    });
  };

  const selectTag = (tagId: number) => {
    if (!selectedTagIds.includes(tagId)) {
      addAd({
        tag_id: tagId,
        object_external_id: externalAdId
      });
    }
  };

  const addTag = (tagName: string) => {
    if (workspace) {
      createTag({
        workspace: workspace.id,
        tag_name: tagName,
        color: "blue"
      })
      setNewTag('');
    }
  };


  const handleDeleteTag = (tagId: number) => {
    deleteTag({
      workspace: (workspace?.id || -1),
      tag_id: tagId,
    })
    setDeleteDialogOpen(false);
    setTagIdToDelete(null);
  };

  const handleEditTag = (tagToEdit: tagToEdit) => {
    editTag({
      workspace: (workspace?.id || -1),
      tag_id: tagToEdit.tagId,
      tag_name: tagToEdit.new
    })
    setEditDialogOpen(false);
    setTagToEdit({ tagId: -1, old: "", new: "" });
  };

  return (
    <>
      <Stack
        direction="column" // Arrange items in a column
        spacing={2} // Space between the first and second groups
        alignItems="stretch" // Ensure the stack takes up the full width
        sx={{ width: '100%' }} // Explicitly ensure full width        
      >
        <Stack
          direction="row" // Align second group horizontally
          justifyContent="space-between" // Place items at opposite ends
          alignItems="stretch" // Ensure the stack takes up the full width
          sx={{ width: '100%' }} // Explicitly ensure full width
        >
          <Typography
            sx={{
              fontWeight: 'bold',
              whiteSpace: 'nowrap', // Prevent breaking onto a new line
            }}
          >
            Tags :
          </Typography>
          <Button
            variant="outlined"
            startIcon={<Iconify icon="ep:circle-plus-filled" />}
            onClick={() => setOpen(true)}
            sx={{
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            Add Tag
          </Button>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            flexWrap: 'wrap',  // Allow items to wrap to the next line
          }}
        >
          {selectedTagIds.map((tagId) => {
            const tagData = getTagData(tagId);
            return (
              <Button
                key={`${tagId}_clickable`}
                variant="outlined"
                endIcon={<Iconify icon="ep:circle-close-filled" />}
                onClick={(e) => {
                  e.stopPropagation();
                  setTagIdToDelete(tagId);
                  setDeleteDialogOpen(true);}}
                sx={{
                  paddingLeft: 1,
                  paddingRight: 1,
                  backgroundColor: getTagColor(tagData?.color || "").bg,
                  '&:hover': {
                    bgcolor: getTagColor(tagData?.color || "").hover,
                  },
                }}
              >
                {tagData?.name}
              </Button>
            )
          })}
        </Stack>
      </Stack>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth

        sx={{
          '& .MuiDialog-paper': {
            paddingBottom: 3,
          },
        }}
      >
        <DialogTitle>Tag Management</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            <Box sx={{ position: 'relative' }}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="New tag..."
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  onKeyDown={handleKeyDownSearchBar}
                />
                <Button
                  variant="contained"
                  onClick={() => newTag && addTag(newTag)}
                >
                  <Iconify icon="mdi:plus" />
                </Button>
              </Box>
            </Box>

            <Scrollbar sx={{ height: 500 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                {(filteredTags || []).map((tag) => {
                  const colors = getTagColor(tag.color);
                  const border = expandedTagId === tag.id ? `3px solid ${colors.bg}` : '3px solid #ffffff'
                  return (
                    <Accordion
                      key={`${tag.id}_accordion`}
                      expanded={expandedTagId === tag.id}
                      onChange={() => setExpandedTagId(expandedTagId === tag.id ? null : tag.id)}
                      sx={{
                        bgcolor: selectedTagIds.includes(tag.id) ? colors.bg : 'grey.100',
                        color: selectedTagIds.includes(tag.id) ? colors.text : 'text.primary',
                        borderRadius: 1,
                        border: border,
                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                      }}
                    >
                      <AccordionSummary>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
                          <IconButton
                            size="medium"
                            onClick={(e) => {
                              e.stopPropagation();
                              selectedTagIds.includes(tag.id) ? unSelectTag(tag.id) : selectTag(tag.id);
                            }}
                          >
                            {selectedTagIds.includes(tag.id) ? <Iconify icon="mdi:close" /> : <Iconify icon="mdi:plus" />}
                          </IconButton>

                          <Typography sx={{ flex: 1 }}>{tag.name}</Typography>
                          <Typography variant="body2" color="text.secondary">
                            {(adsByTagId || [])[tag.id]?.length || 0} ads
                          </Typography>

                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleColorClick(tag.id, e);
                            }}
                          >
                            <Iconify icon="mdi:palette" />
                          </IconButton>

                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              setTagToEdit({ tagId: tag.id, old: tag.name, new: tag.name });
                              setEditDialogOpen(true);
                            }}
                          >
                            <Iconify icon="mdi:pencil" />
                          </IconButton>

                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              setTagIdToDelete(tag.id);
                              setDeleteDialogOpen(true);
                            }}
                          >
                            <Iconify icon="mdi:delete" />
                          </IconButton>

                          <Iconify icon="mdi:chevron-down" />
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box sx={{ pl: 2 }}>
                          <Typography variant="subtitle2" gutterBottom>
                            Ads using this tag:
                          </Typography>
                          {(adsByTagId || [])[tag.id]?.length ? (
                            <Box component="ul" sx={{ m: 0, pl: 2 }}>
                              {(adsByTagId || [])[tag.id].map((ad) => (
                                <Typography key={`${tag.id}_${ad.name}_${ad.id}_tagged`} component="li" variant="body2">
                                  {ad.name}
                                </Typography>
                              ))}
                            </Box>
                          ) : (
                            <Typography variant="body2" color="text.secondary">
                              No ads
                            </Typography>
                          )}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Box>
            </Scrollbar>

          </Box>
        </DialogContent>

        <Popover
          open={Boolean(colorAnchorEl)}
          anchorEl={colorAnchorEl}
          onClose={handleColorClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: 1, p: 2 }}>
            {PREDEFINED_COLORS.map((color) => (
              <Box
                key={color.value}
                onClick={() => handleColorChange(activeColorTagId || -1, color.value)}
                sx={{
                  width: 36,
                  height: 36,
                  bgcolor: color.bg,
                  borderRadius: 1,
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: color.hover,
                  },
                  border: (filteredTags || []).find((tag) => tag.id === activeColorTagId)?.color === color.value ? 2 : 0,
                  borderColor: 'primary.main',
                }}
              />
            ))}
          </Box>
        </Popover>
        <Dialog
          open={editDialogOpen}
          onClose={() => setEditDialogOpen(false)}
        >
          <DialogTitle>Edit Tag</DialogTitle>
          <DialogContent>
            <TextField
              value={tagToEdit.new}
              onChange={(e) => setTagToEdit((prev) => ({
                ...prev,
                new: e.target.value
              }))}
              onKeyDown={handleKeyDownEdit}
              // onBlur={handleBlur}
              autoFocus
              size="small"
              variant="outlined"

            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditDialogOpen(false)}>{t("Cancel")}</Button>
            <Button
              onClick={() => handleEditTag(tagToEdit)}
              color="warning"
              variant="contained"
            >
              {t("Edit")}
            </Button>
          </DialogActions>
        </Dialog>
        <Box />
      </Dialog>
      <Dialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        >
          <DialogTitle>Delete Tag</DialogTitle>
          <DialogContent>
          <Typography>
            {t('Are you sure you want to delete the tag ')}
            <Typography component="span" variant="h6" style={{ fontWeight: 'bold' }}>
              {getTagData(tagIdToDelete || -1)?.name}
            </Typography>
            {t(' ?')}
          </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialogOpen(false)}>{t("Cancel")}</Button>
            <Button
              onClick={() => tagIdToDelete && handleDeleteTag(tagIdToDelete)}
              color="error"
              variant="contained"
            >
              {t("Delete")}
            </Button>
          </DialogActions>
        </Dialog>
    </>
  );
};

export default TagManagementModal;