import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useCurrentUserQuery, useSigninMutation } from 'src/context/api/auth';
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';

import { RouterLink } from 'src/routes/components';

import { useBoolean } from 'src/hooks/use-boolean';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
// auth

import Iconify from 'src/components/iconify';
import { useRouter } from 'src/routes/hooks';
import { useLocales } from 'src/locales';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Avatar, Button, Divider } from '@mui/material';
import { useLocation } from 'react-router';

export default function Signin() {
  const router = useRouter();
  const [signin, response] = useSigninMutation();
  const password = useBoolean();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const { t } = useLocales();
  const { search } = useLocation();
  const signinSchema = Yup.object().shape({
    email: Yup.string().required(t('fieldRequired', { field: "email" })),
    password: Yup.string().required(t('fieldRequired', { field: t("auth.form.signin.password") }))
  });
  const user = useCurrentUserQuery();

  const query = new URLSearchParams(search);
  const returnTo = decodeURI(query.get('to') || "");

  const methods = useForm({
    resolver: yupResolver(signinSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  useEffect(() => {
    const error = response.error as any;
    if (response.isError) {
      const reason = error?.data.message || 'UnknownError';
      setErrorMsg(t('auth.' + reason));
    } else if (response.data) {
      user.refetch();
    }
  }, [response]);

  useEffect(() => {
    if (user.data) {
      if (query.has('to')) {
        router.replace(returnTo)
      }
      else {
        router.reload();
      }
    }
  }, [user.data]);

  const signInWithGoogle = () => window.location.href = BENLY_API_URL + "/auth/signin/google"

  return (
    <FormProvider methods={methods} onSubmit={methods.handleSubmit(signin)}>
      <Stack spacing={2} sx={{ mb: 5 }}>
        <Typography variant="h4">{t('auth.form.signin.signinTo')}</Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">{t('auth.form.signin.newUser')}</Typography>

          <Link component={RouterLink} href={'/auth/register'} variant="subtitle2">
            {t('auth.form.signin.createAccount')}
          </Link>
        </Stack>
      </Stack>
      <Stack spacing={2.5}>
        {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
        {query.has("error") && <Alert severity="error">Error signing in, try again.</Alert>}
        <Stack direction="row" justifyContent="center" spacing={2}>
          <Button startIcon={<Avatar sx={{ width: 18, height: 18 }} src="/assets/icons/socials/ic_google.svg" />} sx={{ fontSize: 14 }} onClick={signInWithGoogle}>
            Sign in with google
          </Button>
        </Stack>
        <Divider
          sx={{
            my: 2.5,
            typography: 'overline',
            color: 'text.disabled',
            '&::before, ::after': {
              borderTopStyle: 'dashed',
            },
          }}
        >
          OR
        </Divider>

        <RHFTextField shrink name="email" label={t('auth.form.signin.Email')} />

        <RHFTextField
          shrink
          name="password"
          label={t('auth.form.signin.password')}
          type={password.value ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={password.onToggle} edge="end">
                  <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Link
          component={RouterLink}
          href={'/auth/forgot'}
          variant="body2"
          color="inherit"
          underline="always"
          sx={{ alignSelf: 'flex-end' }}
        >
          {t('auth.form.signin.ForgotPassword?')}
        </Link>

        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={methods.formState.isSubmitting}
        >
          {t('auth.form.signin.Login')}
        </LoadingButton>
        <Typography
          component="div"
          sx={{
            color: 'text.secondary',
            mt: 2.5,
            typography: 'caption',
            textAlign: 'center',
          }}
        >
          {t('auth.form.register.iAgreeTo') + ' '}
          <Link href="https://benly.ai/terms-and-conditions/" underline="always" color="text.primary">
            {t("auth.form.register.termsOfService")}
          </Link>
          {` ${t('and')} `}
          <Link href="https://benly.ai/privacy-policy/" underline="always" color="text.primary">
            {t("auth.form.register.privacyPolicy")}
          </Link>
          .
        </Typography>
      </Stack>
    </FormProvider>
  );
}
