import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { QueryAdAccount, QueryAttribution, QueryDateRange, QueryMode, QueryResourceIds, QueryTimeStep, QueryWorkspace } from '../shared/query';
import { DetailResponse, TopCopyResponse, TopCreativeResponse, TopLandingResponse, TopVideoResponse } from './models';
import { formatQS } from 'src/utils/format-qs';
import { Explorer } from '../explorer/models';

type QueryArg = QueryDateRange & QueryAdAccount & QueryWorkspace & QueryAttribution & Partial<QueryResourceIds> & QueryMode;

export interface Grouped {
  grouping: "creative" | "landing" | "copywriting" | "video" | "ad_name",
  adType: ("VIDEO" | "IMAGE" | "CAROUSEL" | "INSTANT_EXPERIENCE")[]
}
interface ByCreative {
  creativeId: number;
}

interface Limit {
  limit: number;
}

type PreviewType = "preview" | "video" | "image" | "thumbnail"

const adAccounts = createApi({
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: BENLY_API_URL + '/ad_creatives',
  }),
  reducerPath: 'api.ad_creatives',
  tagTypes: ['ad_creatives'],
  endpoints: (builder) => ({
    topCreatives: builder.query<Explorer.TopAdsCreative, QueryArg & Grouped & Limit>({
      query: ({adAccountId, ...args}) => {
        let url = `/${adAccountId}/top_creatives?${formatQS(args)}`;
        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    }),
    topCreativeConfig: builder.query<Explorer.Config, QueryArg & Grouped & Limit>({
      query: ({adAccountId, ...args}) => {
        let url = `/${adAccountId}/top_creatives/config?${formatQS(args)}`;
        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    }),
    topCreativesAnalytics: builder.query<Explorer.Analytics, QueryArg & QueryTimeStep & Grouped & Limit>({
      query: ({adAccountId, ...args}) => {
        let url = `/${adAccountId}/top_creatives/analytics?${formatQS(args)}`;
        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    }),
    topCreativesTable: builder.query<Explorer.Table, QueryArg & Grouped & Limit>({
      query: ({adAccountId, ...args}) => {
        let url = `/${adAccountId}/top_creatives/table?${formatQS(args)}`;
        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    }),
    creativePreview: builder.query<[PreviewType, any], ByCreative & { previewType?: PreviewType, width?: number, height?: number, ad_format?: string }>({
      query: (args) => {
        let url = `/${args.creativeId}/preview`;
        if (args.previewType) {
          url += `?preview_type=${args.previewType}`;
          if (args.width) {
            url += `&width=${args.width}`;
          }
          if (args.height) {
            url += `&height=${args.height}`;
          }
          if (args.ad_format) {
            url += `&ad_format=${args.ad_format}`;
          }
        }
        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    }),
    creativeDetail: builder.query<DetailResponse, QueryArg & ByCreative & {timeStep?: string}>({
      query: ({adAccountId, creativeId, ...args}) => {
        let url = `/${adAccountId}/creative/${creativeId}/detailed?${formatQS(args)}`;
        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    }),
  }),

});

export const { useTopCreativesQuery, useTopCreativeConfigQuery, useCreativeDetailQuery, useCreativePreviewQuery, useTopCreativesAnalyticsQuery, useTopCreativesTableQuery } = adAccounts;
export default adAccounts;
