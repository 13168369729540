import { Helmet } from 'react-helmet-async';
import { useLocales } from 'src/locales';
import { useWorkspace } from 'src/providers/workspace';
import { useParams } from 'src/routes/hooks';
// sections
import { OverviewAnalyticsView } from 'src/sections/overview/analytics/view';

// ----------------------------------------------------------------------

export default function OverviewAnalyticsPage() {
  useWorkspace(true)

  const {type} = useParams()
  const {t} = useLocales()
  return (
    <>
      <Helmet>
        <title> {t('applications.connect.'+type)} Analytics</title>
      </Helmet>

      <OverviewAnalyticsView />
    </>
  );
}
