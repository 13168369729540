import numeral from 'numeral';

// ----------------------------------------------------------------------

type InputValue = string | number | null;


export function fNumber(number: InputValue) {
  return numeral(number).format();
}

export function fCurrency(number: number, currency: string = "USD", isCompact: boolean = true) {
    const notation = isCompact ? "compact" : "standard"  
    return (number).toLocaleString(
      undefined,
      {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        notation: notation,
      }
    ).trim()
}

export function fPercent(number: InputValue) {
  const format = numeral(Number(number) / 100).format('0.00%');

  return result(format, '.00');
}

export function fShortenNumber(number: InputValue) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number: InputValue) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format: string, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}
