import { useSnackbar } from "src/components/snackbar";
import { useEffect } from "react";
import { useGetSocialAccountsQuery } from "src/context/api/social";
import { Workspace, useUpdateWorkspaceMutation } from "src/context/api/workspaces";

export default function useSocial() {
    const {enqueueSnackbar} = useSnackbar()
    const [update, updateResult] = useUpdateWorkspaceMutation()
    const {data: {providers: accounts} = {}, isFetching} = useGetSocialAccountsQuery()
    const connect = (socialType: string) => () => {
        window.location.href = BENLY_API_URL + `/social/${socialType}/connect`;
    }
    const addToWorkspace = (workspace: Workspace, object: any) => {
        return update({
            ...workspace,
            objects: [object]
        })
    }

    useEffect(() => {
        if (updateResult.isSuccess) {
            enqueueSnackbar("Workspace successfully connected to datasource!", {variant: "success"})
        }
        if (updateResult.isError) {
            enqueueSnackbar("Cannot connect workspace to datasource", {variant: "error"})
        }
    }, [updateResult])

    return {accounts, connect, addToWorkspace, fetching: isFetching}
}