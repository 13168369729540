import { snakeCase } from "./change-case"

export const formatQS = (query: any) => Object.entries({...query}).filter(([,v]) => !!v).reduce((prev , [k, v]) => {
    if(Array.isArray(v)) {
        v.forEach(v => prev.push(`${snakeCase(k)}=${v}`))
    }
    else
        prev.push(`${snakeCase(k)}=${v}`)

    return prev
}, [] as string[]).join("&")