import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import { useWorkspace } from "src/providers/workspace";
import { TopCreativeDashboard } from "src/sections/overview/dashboards/views/dashboard-top-creative";

export function DashboardCreative() {
  useWorkspace(true)

  const {t} = useLocales()
  return (
    <>
      <Helmet>
        <title>{t('home')}</title>
      </Helmet>

      <TopCreativeDashboard />
    </>
  );
}