import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const media = createApi({
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: BENLY_API_URL,
  }),
  reducerPath: 'api.media',
  tagTypes: ['media'],
  endpoints: (builder) => ({
    upload: builder.mutation<{ url: string }, FormData>({
      query: (arg) => ({
        url: '/medias/upload',
        method: 'PUT',
        body: arg,
        headers: {
          "Accept": "application/json",
          "Cache-Control": "max-age=0"
        }
      }),
    }),
  }),
});

export const {useUploadMutation} = media;
export default media