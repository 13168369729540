import { Box, Button, ButtonBase, Checkbox, FormControlLabel, FormGroup, Stack, TextField } from "@mui/material";
import Iconify from "../iconify";
import { usePopover } from "../custom-popover";
import CustomPopover from "../custom-popover/custom-popover";
import { useCallback, useEffect, useState } from "react";
import Scrollbar from "../scrollbar";


interface Option { id: any; label: string }

interface Props<T extends Option[], M extends boolean> {
    variant?: "light" | any;
    multiple: M;
    label: string;
    options: T;
    onChange?: (selected: M extends true ? number[] : number) => void;
    onSelect?: (selected: M extends true ? number[] : number) => void;
    value?: any | any[];
    searchable?: boolean;
    onApply?: (selected: M extends true ? number[] : number) => void;
    onCancel?: () => void;
}

export default function ButtonSelect<T extends Option[], M extends boolean>({ options, onChange, label, multiple, value, variant, searchable, onApply, onCancel, onSelect }: Props<T, M>) {
    const displayOptions = usePopover()
    const [currentSelection, setCurrentSelection] = useState<null | number[] | number>(multiple ? value || [] : options[0]?.id as number)
    const [selected, setSelected] = useState<null | number | number[]>(multiple ? value || [] : options[0]?.id as number)


    useEffect(() => {
        if (value) {
            setSelected(value)
        }
    }, [value])

    const handleSelect = (id: number) => {
        if (multiple === true) {
            const multipleCurrentSelection = currentSelection as number[]
            if (multipleCurrentSelection.includes(id)) {
                setCurrentSelection((multipleCurrentSelection as number[]).filter((i) => i !== id))
            }
            else {
                setCurrentSelection([...multipleCurrentSelection, id])
            }
        }
        else {
            if (currentSelection === id) {
                setCurrentSelection(null)
            }
            else {
                setCurrentSelection(id as number)
            }
        }
    }

    const applySelection = () => {
        setSelected(currentSelection)
        displayOptions.onClose()
        onApply?.(currentSelection as M extends true ? number[] : number)
    }

    const cancelSelection = () => {
        setCurrentSelection(selected as M extends true ? number[] : number)
        displayOptions.onClose()
        onCancel?.()
    }

    useEffect(() => {
        onChange?.(selected as M extends true ? number[] : number)
    }, [selected])

    useEffect(() => {
        onSelect?.(currentSelection as M extends true ? number[] : number)
    }, [currentSelection])

    const [search, setSearch] = useState<string>("")

    // make sure the options are unique over id
    const uniqueOptions = options.filter((option, index, self) =>
        index === self.findIndex((t) => t.id === option.id)
    );
    
    return (
        <>
            <ButtonBase
                onClick={displayOptions.onOpen}
                sx={{
                    pl: 1,
                    py: 0.5,
                    pr: 0.5,
                    borderRadius: 1,
                    typography: 'subtitle2',
                    bgcolor: variant !== "light" ? 'background.neutral' : "white",
                }}
            >
                {label}
                <Iconify
                    width={16}
                    icon={
                        displayOptions.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'
                    }
                    sx={{ ml: 0.5 }}
                />
            </ButtonBase>

            <CustomPopover
                open={displayOptions.open}
                onClose={cancelSelection}
                sx={{
                    minWidth: "fit-content",
                }}
            >
                {searchable && (
                    <TextField
                        label="Search"
                        variant="outlined"
                    fullWidth
                    sx={{
                        mb: 2
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value)
                    }}
                    />
                )}
                <Scrollbar sx={{ maxHeight: "300px", minWidth: "fit-content" }}>
                    {uniqueOptions.filter(option => option.label.toLowerCase().includes(search.toLowerCase())).map((option, idx) => (
                        <FormGroup key={idx} sx={{ minWidth: "fit-content" }}>
                            <FormControlLabel 
                                sx={{
                                    marginLeft: "-2px",
                                    minWidth: "max-content"
                                }}
                                control={
                                    <Checkbox
                                        checked={multiple ? (currentSelection as number[] || value).includes(option.id) : (currentSelection || value) === option.id}
                                        onChange={() => handleSelect(option.id)}
                                    />
                                }
                                label={option.label}
                            />
                        </FormGroup>
                    ))}
                </Scrollbar>
                <Stack pt={1} justifyContent={'space-between'} direction={'row-reverse'}>
                    <Button
                        sx={{ ml: 2 }}
                        color="primary"
                        variant="contained"
                        onClick={applySelection}
                    >
                        Ok
                    </Button>
                    <Button
                        variant="soft"
                        onClick={cancelSelection}
                    >
                        Cancel
                    </Button>
                </Stack>
            </CustomPopover>
        </>
    )
}