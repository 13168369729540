import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const social = createApi({
    baseQuery: fetchBaseQuery({
      credentials: 'include',
      baseUrl: BENLY_API_URL + '/social',
    }),
    reducerPath: 'api.social',
    tagTypes: ['social'],
    endpoints: (builder) => ({
      getSocialAccounts: builder.query<{ providers: string[] }, void>({
        query: () => ({
          url: `/`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        }),
      }),
      getSocialAccountCurrency: builder.query<any, string>({
        query: (id: string) => ({
          url: `/${id}/currency`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        }),
      }),
    }),
  });
  
  export const { useGetSocialAccountsQuery, useGetSocialAccountCurrencyQuery } = social;
  export default social;