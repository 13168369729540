import { Middleware } from '@reduxjs/toolkit';
import { RootState } from 'src/context/store';
import { setMode } from '../attribution-settings';

const appSettingsMiddleware: Middleware = (store) => (next) => (action) => {
  const state: RootState = store.getState();
  let modeFromLocalStorage;
  let workspaceId;

  if (action.type === 'appSettings/setCurrentWorkspace' && action.payload) {
    workspaceId = action.payload.id;
    modeFromLocalStorage = JSON.parse(localStorage.getItem('mode_settings') || '{}')[workspaceId];
  }

  const result = next(action);
  if (modeFromLocalStorage && workspaceId) {
    console.log('modeFromLocalStorage', workspaceId, modeFromLocalStorage);
    store.dispatch(setMode(modeFromLocalStorage));
  }

  return result;
};

export default appSettingsMiddleware;
