import { useAccountCurrency } from 'src/hooks/use-account-currency';
import { fCurrency, fPercent } from 'src/utils/format-number';

// ---------------------------------------------------------

export const useMarketingNumberFormat = () => {
  const currency = useAccountCurrency();

  return (type: string, input: number): { compressed: string, raw: string } => {
    input = Number(input.toFixed(2));
    switch (type) {
      case 'app_install':
      case 'omni_add_to_cart':
      case 'outbound_clicks':
      case 'impressions':
      case 'omni_initiated_checkout':
      case 'conversions':
        return { compressed: input.toLocaleString(undefined, { maximumFractionDigits: 0 }), raw: input.toLocaleString(undefined, { maximumFractionDigits: 0 }) };
      case 'spend':
      case 'revenue':
      case 'cost':
      case 'cpc':
      case 'cpa':
      case 'aov':
      case 'cpm':
      case 'omni_purchase_value':
      case 'omni_initiated_checkout_value':
        return { compressed: fCurrency(input, currency, true), raw: fCurrency(input, currency, false) };
      case 'cos':
      case 'COS':
      case 'cvr':
      case 'ctr':
      case 'hook_rate':
      case 'thumbstop_click_rate':
      case 'video_completion_rate':
      case 'click_ratio:omni_initiated_checkout':
      case 'click_ratio:omni_add_to_cart':
        return { compressed: fPercent(input), raw: fPercent(input) };
      case 'conversions':
        return {
          compressed: (input / 1000) > 0.9
            ? `${(input / 1000).toFixed(2).replace('.00', '')}K`
            : input.toFixed(0),
          raw: input.toFixed(0)
        };
      default:
        return {
          compressed: (input / 1000) > 0.9
            ? `${(input / 1000).toLocaleString(undefined, { maximumFractionDigits: 2 }).replace('.00', '')}K`
            : input.toLocaleString(undefined, { maximumFractionDigits: 2 }),
          raw: input.toLocaleString(undefined, { maximumFractionDigits: 2 })
        };
    }
  };
};
