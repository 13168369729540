import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { ReactElement } from "react";
import Scrollbar from "src/components/scrollbar";
import { useLocales } from "src/locales";

interface Props {
  children: any
}

export default function AttributionWindowTable({children}: Props) {
  const {t} = useLocales()
  return (
    <TableContainer sx={{ overflow: 'unset', mt: 0 }}>
      <Scrollbar>
        <Table sx={{ minWidth: 320, minHeight: "463px", borderRadius: 5 }}>
          <TableHead>
            <TableRow>
              <TableCell width={40}></TableCell>
              <TableCell width={240}>{t("Selected attribution window")}</TableCell>
              <TableCell width={40}></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {children}
          </TableBody>
        </Table>
      </Scrollbar>
    </TableContainer>
  )
}