import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Icon, List, ListItem, Stack, TextField, Typography, createFilterOptions, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { RHFAutocomplete, RHFAutosuggest, RHFTextField } from "src/components/hook-form";
import FormProvider from "src/components/hook-form/form-provider";
import { Workspace } from "src/context/api/workspaces";
import { useBoolean } from "src/hooks/use-boolean";
import WorkspacePoppver from "src/layouts/_common/workspace-popover";
import workspaceServices from "src/services/workspaces";
import { useWorkspaceCreateOrUpdateForm, useWorkspaceDatasourceForm } from "./use-workspace-form";
import useSocial from "src/services/social";
import Iconify from "src/components/iconify";
import { ObjectType, useObjectsQuery } from "src/context/api/marketing";
import LoadingButton from "@mui/lab/LoadingButton";
import { useLocales } from "src/locales";
import Connect from "src/sections/overview/datasources/connect-form";


enum FormStep {
    name,
    editors,
    datasource,
    connect,
    finished,
    loading
}

enum ConnectableStatus {
    disabled,
    active,
}


export default function Workflow({ children }: any) {
    const {t} = useLocales()
    const social = useSocial();
    const workspaces = workspaceServices()
    const adAccounts = useObjectsQuery({type: ObjectType.AD_ACCOUNT})
    const dialog = useBoolean(true);
    const [display, setDisplay] = useState(FormStep.name);

    const methods = useWorkspaceCreateOrUpdateForm();
    const datasourceForm = useWorkspaceDatasourceForm()

    const theme = useTheme()

    const fields = methods.watch()

    const handleChangeDisplay = (display: FormStep) => () => setDisplay(display)

    const sortedWorkspaces = useMemo(() => ([] as Workspace[]).concat((workspaces.list || [])).sort((a, b) => new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf()), [workspaces.list])

    const hasWorkspaces = (workspaces.list || []).length > 0
    const hasSocialAccount = (social.accounts || []).length > 0
    const workpsaceHasDatasource = workspaces.list?.some(workspace => !!workspace.objects && workspace.objects.length > 0) || false

    useEffect(() => {
        if (workspaces.fetching || workspaces.pendingUpdate || social.fetching) {
            setDisplay(FormStep.loading)
        }
        else if (!hasWorkspaces) {
            if (display !== FormStep.editors && display !== FormStep.connect) {
                setDisplay(FormStep.name)
            }
        }
        else if (!hasSocialAccount && !workspaces.list?.some(wp => (wp.objects || []).length > 0)) {
            setDisplay(FormStep.connect)
        }
        else if (!workpsaceHasDatasource) {
            setDisplay(FormStep.datasource)
        }
        else {
            setDisplay(FormStep.finished)
        }


    }, [hasSocialAccount, hasWorkspaces, workpsaceHasDatasource, workspaces, social])

    useEffect(() => {
        if (sortedWorkspaces.length > 0) {
            datasourceForm.setValue("workspace", { label: sortedWorkspaces[0]?.name, value: sortedWorkspaces[0] })
        }
    }, [sortedWorkspaces])

    const skipAction = [FormStep.editors, FormStep.name].includes(display) ? {onClick: handleChangeDisplay(FormStep.connect)} : {href: "/apps"}

    return (
        <>
            <Dialog open={dialog.value} >
                <FormProvider methods={methods} onSubmit={methods.handleSubmit(workspaces.create)}>
                    {display === FormStep.name && <DialogTitle>{t("👋 Welcome to Benly!  Create a brand Workspace")}</DialogTitle>}
                    {display === FormStep.editors && <DialogTitle>{t("Bring your team together in one place")}</DialogTitle>}
                    {display === FormStep.connect && <DialogTitle>{t("Now, let’s make your dashboards together!")}</DialogTitle>}
                    {display === FormStep.datasource && <DialogTitle>{t("Now, let’s make your dashboards together!")}</DialogTitle>}
                    {display === FormStep.finished && <DialogTitle>{t("Select Workspace to start analyze")}</DialogTitle>}
                    {display === FormStep.loading && <DialogTitle>{t("Please wait a bit while retrieving data")}</DialogTitle>}
                    <DialogContent sx={{ display: display !== FormStep.name ? "none" : undefined }}>
                        <Alert severity="info">{t("Name you Workspace&#46 You can provide the name you want but normally this is the name of your brand or your customer’s brand&#46")}</Alert>
                        <Stack alignItems={"center"} justifyContent={"center"} alignContent={"center"} spacing={2} direction={"column"} my={3}>
                            <Stack width={{
                                xs: "100%",
                                md: "50%"
                            }} direction={"column"} spacing={2}>
                                <RHFTextField name="name" label={"Workspace name"} size="small" fullWidth />
                                <Button onClick={handleChangeDisplay(FormStep.editors)} sx={{ backgroundColor: theme.palette.primary.main }} variant="contained">
                                    {t("Create Workspace")}
                                </Button>
                            </Stack>
                        </Stack>
                    </DialogContent>
                    <DialogContent sx={{ display: display !== FormStep.editors ? "none" : undefined }}>
                        <Alert severity="info">{t("Bring your team on board! Just pop in their email addresses to invite them to join your Workspace&#46")}</Alert>
                        <Stack alignItems={"center"} justifyContent={"center"} alignContent={"center"} spacing={2} direction={"column"} my={3}>
                            <Stack width={{
                                xs: "100%",
                                md: "70%"
                            }} direction={"column"} spacing={2}>
                                <Stack direction={"column"} spacing={2}>
                                    <RHFAutosuggest helperText={t("Add coma for separator, press enter to confirm")} limitTags={3} fullWidth freeSolo multiple options={[]} label={t("Add Editors")} name="editors" />
                                    <Button type="submit" onClick={handleChangeDisplay(FormStep.connect)} fullWidth sx={{ backgroundColor: theme.palette.primary.main }} variant="contained">
                                        {t("Invite to your workspace")}
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                    </DialogContent>
                </FormProvider>
                <DialogContent sx={{ display: display !== FormStep.connect ? "none" : undefined }}>
                    <Alert severity="info">{t("Link your ad account to unlock Benly’s customized dashboard capabilities&#46 Quick and easy, this step is your gateway to tailored, data-driven insights for your campaigns&#46")}</Alert>
                    <Connect />
                </DialogContent>
                <FormProvider methods={datasourceForm} onSubmit={datasourceForm.handleSubmit((data) => {
                    const promises: any = data.datasources?.map((ds) => (data.workspace as any)?.value && social.addToWorkspace((data.workspace as any).value as Workspace, ds.value)).filter(value => !!value)

                    Promise.all(promises)
                        .then(() => {
                            workspaces.reload()
                        })
                })}>
                    <DialogContent sx={{ display: display !== FormStep.datasource ? "none" : undefined }}>
                        <Alert severity="info">{t("Last step! Quickly connect newly created workspace to datasource&#46")}</Alert>
                        {
                            sortedWorkspaces && adAccounts.data
                                ?
                                <>
                                    <RHFAutocomplete getOptionKey={(option: any) => `${option.label}-${option.value.id}`} isOptionEqualToValue={(option, value) => option.value.id === value.value.id} options={sortedWorkspaces.map((workspace) => ({ label: workspace.name, value: workspace }))} sx={{ mt: 3 }} size="small" fullWidth name="workspace" label="Workspace" />
                                    <Stack m={2} direction={"row"}>
                                        <Iconify fontSize={42} icon={"eva:arrow-ios-downward-fill"} />
                                    </Stack>
                                    <RHFAutocomplete name="datasources" isOptionEqualToValue={(option, value) => option.value === value.value} placeholder={t("Select datasource")} multiple options={adAccounts.data?.objects?.map((option) => ({
                                        label: option.name,
                                        value: option.id
                                    })) || []} />
                                </>
                                :
                                <Stack direction={"row"} width={"100%"} justifyContent={"center"}>
                                    <CircularProgress />
                                </Stack>
                        }

                        <LoadingButton sx={{ mt: 2 }} fullWidth type="submit" variant="contained">
                            {t("Finish")}
                        </LoadingButton>
                    </DialogContent>
                </FormProvider>
                {
                    <DialogContent sx={{ display: display !== FormStep.finished ? "none" : undefined }}>
                        <Alert severity="info">
                            {t("You can select a workspace to start viewing data on your dashboards, or go back to workspace management")}
                        </Alert>
                        <Stack direction={"row"} justifyContent={"center"} m={3}>
                            <WorkspacePoppver />
                        </Stack>
                    </DialogContent>
                }
                <DialogContent sx={{ display: display !== FormStep.loading ? "none" : undefined }}>
                    <Stack direction={"row"} justifyContent={"center"} my={5}>
                        <CircularProgress />
                    </Stack>
                </DialogContent>
                {
                    display !== FormStep.finished && display !== FormStep.loading
                        ?

                        <DialogActions sx={{ justifyContent: "space-between", flexDirection: "row-reverse" }}>
                            <Button {...skipAction} size="small" variant="contained" sx={{ backgroundColor: theme.palette.grey[300], color: theme.palette.grey[600] }}>
                                {t("skip this step")}
                            </Button>
                            {
                                (display !== FormStep.name && display !== FormStep.connect) && (
                                    <Button size="small" variant="contained" onClick={handleChangeDisplay(FormStep.name)}>
                                        {t("Previous step")}
                                    </Button>
                                )
                            }
                        </DialogActions>
                        :
                        display === FormStep.finished
                            ?
                            <DialogActions sx={{ justifyContent: "space-between", flexDirection: "row-reverse" }}>
                                <Button href="/workspaces" size="small" variant="contained" sx={{ backgroundColor: theme.palette.grey[300], color: theme.palette.grey[600] }}>
                                    {t("Go back to workspaces")}
                                </Button>
                            </DialogActions>
                            :
                            ""
                }
            </Dialog>
        </>
    )
}