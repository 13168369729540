import {
    Alert, Avatar, AvatarGroup, Box, Button, Card, Container, Dialog, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment, OutlinedInput, Pagination, Stack, Typography
} from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import Iconify from "src/components/iconify";
import { useSettingsContext } from "src/components/settings";
import { useBoolean } from "src/hooks/use-boolean";
import { useLocales } from "src/locales";
import WorkspaceCreate from "../workspace-create";
import { Workspace, useGetWorkspacesQuery } from "src/context/api/workspaces";
import { mediaUrl } from "src/utils/media-url";
import { fDateTime } from "src/utils/format-time";
import { Fragment, useMemo, useState } from "react";
import CustomPopover, { usePopover } from "src/components/custom-popover";
import { useRouter } from "src/routes/hooks";
import { paths } from "src/routes/paths";
import { stringAvatar } from "src/utils/avatar";
import { useDispatch } from "react-redux";
import { setCurrentWorkspace } from "src/context/reducers/app-settings";

export default function WorkspaceGeneralView() {
    const settings = useSettingsContext();
    const { t } = useLocales()
    const newWorkspaceModal = useBoolean(false)
    const workspaces = useGetWorkspacesQuery(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemPerPage = 6

    const indexOfLastPost = currentPage * itemPerPage;
    const indexOfFirstPost = indexOfLastPost - itemPerPage;

    const pageItems = useMemo(() => {
        return workspaces.data?.slice(indexOfFirstPost, indexOfLastPost) || null;
    }, [workspaces, indexOfFirstPost, indexOfLastPost])

    const totalPage = useMemo(() => {
        if (!workspaces.data) return 1;
        return Math.ceil(workspaces.data?.length / itemPerPage) || 1;
    }, [workspaces, itemPerPage])

    return (
        <Box height={"100%"}>
            <Container sx={{ height: "100%" }} maxWidth={settings.themeStretch ? false : 'xl'}>
                <CustomBreadcrumbs
                    heading={t('workspaces.heading')}
                    links={[
                        { name: t('workspaces.breadcrumb') }
                    ]}
                    sx={{
                        mb: 0,
                    }}
                    action={
                        <Button
                            onClick={newWorkspaceModal.onTrue}
                            variant="contained"
                            startIcon={<Iconify icon="mingcute:add-line" />}>
                            {t('New Workspace')}
                        </Button>
                    }
                />
                <FormControl sx={{ my: 1, p: 0 }}>
                    <OutlinedInput
                        id="outlined-adornment-amount"
                        placeholder={t("Search...")}
                        size="small"
                        startAdornment={<InputAdornment position="start"><Iconify icon={"eva:search-fill"} /></InputAdornment>}
                    />
                </FormControl>
                <Grid spacing={2} container>
                    {pageItems?.map((workspace, idx) => {
                        return (
                            <WorkspaceCard key={idx} workspace={workspace} />
                        )
                    }) || (
                            [1, 2, 3, 4, 5].map((idx) => <WorkspaceCard workspace={undefined} loading key={idx} />)
                        )}
                </Grid>
                <Dialog open={newWorkspaceModal.value} onClose={newWorkspaceModal.onFalse}>
                    <DialogTitle> {t("workspaces.dialog_create.title")}
                        <Alert sx={{ mt: 3 }} severity="info">{t("Only Editor user can be added when you create a new Workspace")}</Alert></DialogTitle>
                    <DialogContent>
                        <WorkspaceCreate onSuccess={() => {
                            workspaces.refetch().unwrap().then(() => {
                                newWorkspaceModal.onFalse()
                            });
                        }} onCancel={() => {
                            newWorkspaceModal.onFalse()
                        }} />
                    </DialogContent>
                </Dialog>
            </Container>
            <Stack flexDirection={"column"} justifyContent={"center"}>
                <Box>
                    <Pagination onChange={(e, value) => setCurrentPage(value)} page={currentPage} count={totalPage} sx={{ ".MuiPagination-ul": { justifyContent: "center" } }} />
                </Box>
            </Stack>
        </Box>
    )
}

type WorkspaceCardProps = {
    loading?: false;
    workspace: Workspace;
} | {
    loading: true;
    workspace: undefined;
}

// Workspace card
function WorkspaceCard<T extends boolean>({ workspace, loading }: WorkspaceCardProps) {
    const action = usePopover()
    const { t } = useLocales()
    const router = useRouter()
    const editWorkspaceModal = useBoolean(false)
    const workspaces = useGetWorkspacesQuery(null);
    const renderIsActive = (
        <>
            <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
            <Typography color={"success.main"}>{t("Active")}</Typography>
        </>
    )
    const dispatch = useDispatch();

    return (
        <Fragment>
            <Grid item xs={12} md={4}>
                <Card>
                    <Stack direction={"column"} sx={{ borderBottom: "dashed 1px #c4c4c4" }}>
                        <Stack p={0} direction={"row"}>
                            {
                                loading === true
                                    ?
                                    <Skeleton variant="circular" animation={"wave"} sx={{margin: 3}} width={75} height={75} />
                                    :
                                    workspace.logos
                                        ?
                                        <Avatar
                                            imgProps={{ style: { objectFit: 'contain' } }} sizes="large" sx={{ margin: 3, width: 75, height: 75 }} src={mediaUrl(workspace.logos)} alt={workspace.name} />
                                        :
                                        <Avatar sizes="large" {...stringAvatar(workspace.name)} />
                            }
                            <Box flexGrow={1} />
                            <IconButton onClick={action.onOpen} sx={{ alignItems: "unset", height: "45px", borderRadius: 0, borderBottomLeftRadius: 5 }}><Iconify icon={"eva:more-vertical-fill"} /></IconButton>
                        </Stack>
                        <Stack my={0} p={3} py={1}>
                            <Typography variant="h6">{loading === true ? <Skeleton animation={"wave"} /> : workspace.name}</Typography>
                        </Stack>
                        <Stack my={0} p={3} py={1}>
                            <Typography color={"text.disabled"} variant="caption">
                                {
                                    loading === true ? <Skeleton animation={"wave"} /> : (
                                        `last fetch: ${workspace.last_fetch ? fDateTime(workspace.last_fetch) : "N/A"}`
                                    )
                                }
                            </Typography>
                        </Stack>
                        <Stack direction={"row"} px={3} pb={1}>
                            {
                                loading === true
                                    ?
                                    <Skeleton variant="rounded" animation={"wave"}>
                                        {renderIsActive}
                                    </Skeleton>
                                    :
                                    renderIsActive
                            }
                        </Stack>
                    </Stack>
                    <Stack padding={3} pt={2} direction={"row"}>
                        <Stack direction={"column"}>
                            <Stack direction={"row"} alignItems={"center"}>
                                <Iconify fontSize={"small"} color={"text.disabled"} icon={"solar:user-rounded-bold"} />
                                <Typography variant="caption" color={"text.disabled"}>{t("Users")}</Typography>
                            </Stack>
                            <AvatarGroup max={3}>
                                {
                                    loading === true
                                        ?
                                        <Skeleton variant="circular" animation={"wave"}><Avatar/></Skeleton>
                                        :
                                        workspace.users.map(({ profile }, idx) => <Avatar key={idx} src={profile?.picture ? mediaUrl(profile.picture) : undefined} />)
                                }
                            </AvatarGroup>
                        </Stack>
                        <Box flexGrow={1} />
                        {
                            loading === true
                                ?
                                <Skeleton variant="rounded" animation={"wave"}><Button size="small" sx={{ padding: 1 }}>{t("pending invitations")}</Button></Skeleton>
                                :
                                <Button size="small" sx={{ color: "text.disabled", padding: 1 }}>{workspace.invitations_len} {t("pending invitations")}</Button>
                        }
                    </Stack>
                </Card>
            </Grid>
            <CustomPopover onClose={action.onClose} open={action.open}>
                <Stack direction={"column"} spacing={1}>
                    <Button onClick={() => router.replace(paths.dashboard.home + `?workspace=${workspace?.id}`)} sx={{ justifyContent: "left" }} startIcon={<Iconify icon={"solar:eye-bold"} />} variant={"text"}>{t("workspaces.view_data")}</Button>
                    {
                        workspace && workspace.role !== "GUEST" &&
                        (
                            <>
                                <Button sx={{ justifyContent: "left" }} startIcon={<Iconify icon={"solar:square-arrow-right-up-broken"} />} onClick={() => router.replace(paths.workspaces.workspace(workspace.id))} variant={"text"}>{t("workspaces.view_detils")}</Button>
                                <Button sx={{ justifyContent: "left" }} onClick={editWorkspaceModal.onTrue} startIcon={<Iconify icon={"solar:pen-bold"} />} variant={"text"}>{t("workspaces.edit")}</Button>
                                <Button sx={{ justifyContent: "left" }} color="error" startIcon={<Iconify icon={"solar:trash-bin-trash-bold"} />} variant={"text"}>{t("workspaces.delete")}</Button>
                            </>
                        )
                    }
                </Stack>
            </CustomPopover>
            {
                workspace && workspace.role !== "GUEST" && (
                    <Dialog open={editWorkspaceModal.value} onClose={editWorkspaceModal.onFalse}>
                        <DialogTitle>{workspace?.id ? t("workspaces.dialog_edit.title") : t("workspaces.dialog_create.title")}
                            <Alert sx={{ mt: 3 }} severity="info">{t("Only Editor user can be added when you create a new Workspace")}</Alert></DialogTitle>
                        <DialogContent>
                            <WorkspaceCreate values={{
                                id: workspace.id,
                                name: workspace.name,
                                editors: workspace.users.filter(({ role }) => role === "editor").map(({ email }) => email).concat(workspace.invitations.map(({ email }) => email)).filter((email) => !!email),
                                objects: workspace.objects
                            }} datasource={workspace.objects} onSuccess={() => {
                                workspaces.refetch().unwrap().then(() => {
                                    editWorkspaceModal.onFalse()
                                });
                            }} onCancel={() => {
                                editWorkspaceModal.onFalse()
                            }} />
                        </DialogContent>
                    </Dialog>
                )
            }
        </Fragment>
    )
}