import { useMemo, useState } from 'react';
import { Stack, Typography, Grid } from '@mui/material';

import { DetailResponse, useCreativeDetailQuery, useCreativePreviewQuery } from 'src/context/api/ad-creatives';
import { AttributionWindowClick, AttributionWindowView } from 'src/context/api/shared/models';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { getOptions, selectDatePreset, selectTimeStep, setDateSelection, setEnd, setStart, useAttributionOptions } from 'src/context/reducers/attribution-settings';
import { useAccountCurrency } from 'src/hooks/use-account-currency';
import { fDate } from 'src/utils/format-time';
import { dateRange } from 'src/utils/date-utils';
import CustomModal from 'src/components/custom-modal';
import DateRangeSelect, {
  useDateRangeSelect,
} from 'src/components/generic-select/date-range-select';
import Loading from './loading';
import { ChartCard, getChartData } from './charts';
import CreativeDetailOverview from './overview';
import { parse } from 'date-fns';
import { presetValues } from 'src/hooks/use-date-preset';
import { useDispatch } from 'react-redux';

interface CreativeDetailProps {
  id: number;
  type: 'video' | 'creative' | 'landing';
  open: boolean;
  onClose: () => void;
  resourceIds?: string[];
}

export default function CreativeDetail({ id, type, open, onClose, resourceIds }: CreativeDetailProps) {
  const { start, end, timeStep } = getOptions(["start", "end", "timeStep"])
  const preset = useAttributionOptions("datePreset")
  const dateSelected = useAttributionOptions("dateSelection")
  const presetValue = presetValues[preset as keyof typeof presetValues]
  const dispatch = useDispatch()

  const display = useMemo(() => {
    if (dateSelected === "custom") {
      return `${start} - ${end}`
    }
    return presetValue.displayName
  }, [start, end, dateSelected, presetValue])

  const startDate = parse(start, "yyyy-MM-dd", new Date())
  const endDate = parse(end, "yyyy-MM-dd", new Date())

  const dateRangeSelect = useDateRangeSelect({
    start: startDate,
    end: endDate,
    step: timeStep as any,
    preset: preset as any,
    selected: dateSelected as any,
  })



  return (
    <CustomModal
      size="large"
      open={open}
      onClose={onClose}
      title="Creative Detail"
      rightElement={
        <DateRangeSelect
          features={{ time_step: true }}
          startDate={dateRangeSelect.current.start}
          endDate={dateRangeSelect.current.end}
          selected={dateRangeSelect.display}
          onChangeEndDate={dateRangeSelect.onChangeEndDate}
          onChangeStartDate={dateRangeSelect.onChangeStartDate}
          onChangePreset={dateRangeSelect.onChangePreset}
          onChangeTimeStep={dateRangeSelect.onChangeTimeStep}
          preset={dateRangeSelect.preset.preset}
          timeStep={dateRangeSelect.current.step}
        />
      }
    >
      <Content id={id} type={type} dateRangeSelect={dateRangeSelect} resourceIds={resourceIds} />
    </CustomModal>
  );
}


function Content({ id, type, dateRangeSelect, resourceIds }: { id: number, type: 'video' | 'creative' | 'landing', dateRangeSelect: ReturnType<typeof useDateRangeSelect>, resourceIds?: string[] }) {
  const labels: Date[] = dateRange(dateRangeSelect.current.start, dateRangeSelect.current.end, {step: dateRangeSelect.current.step});
  const workspace = useCurrentWorkspace();
  const currency = useAccountCurrency();
  const adAccountId = useAttributionOptions('account')?.[0] as string;
  const attributionWindow = useAttributionOptions('attributionWindow') as [AttributionWindowClick, AttributionWindowView]
  const mode = useAttributionOptions('mode') as string
  const detail = useCreativeDetailQuery(
    {
      startDate: fDate(dateRangeSelect.current.start, 'yyyy-MM-dd'),
      endDate: fDate(dateRangeSelect.current.end, 'yyyy-MM-dd'),
      attributionWindow,
      workspace: workspace?.id as number,
      creativeId: id,
      adAccountId: adAccountId,
      timeStep: dateRangeSelect.current.step,
      resourceIds,
      mode
    },
    { skip: !adAccountId }
  );

  const chartData = getChartData(type, detail.data as DetailResponse, labels, currency);
  
  if (detail.isLoading) {
    return <Loading />;
  }
  
  if (!detail.data) {
    return <Typography>No data</Typography>;
  }
  

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Stack direction="column" spacing={2}>
          {chartData.map((chart, index) => (
            <ChartCard key={index} chart={chart} />
          ))}
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <CreativeDetailOverview detail={detail.data} id={id} />
      </Grid>
    </Grid>
  );
}
