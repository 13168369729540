import * as Yup from 'yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FormProvider, {
  RHFAutocomplete,
  RHFTextField,
} from 'src/components/hook-form';
import { countries } from 'src/assets/data';
import Iconify from 'src/components/iconify';
import userService from 'src/services/user';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

export default function AccountGeneralSettings() {
  const {user, updateUser} = userService()
  const {t} = useLocales()

  const UpdateUserSchema = Yup.object().shape({
    profile: Yup.object().shape({
      id: Yup.number().nullable(),
      firstname: Yup.string(),
      lastname: Yup.string(),
      phone: Yup.string().required(t('Phone number is required')),
      job: Yup.string().nullable(),
    }),
    address: Yup.object().shape(
      {
        id: Yup.number().nullable(),
        city: Yup.string().optional(),
        country: Yup.string().optional(),
      }
    ),
    email: Yup.string().required(t('Email is required')).email(t('Email must be a valid email address')),
  });

  const defaultValues = {
    profile: {
      id: user?.profile?.id || null,
      firstname: user?.profile?.firstname || '',
      lastname: user?.profile?.lastname || '',
      phone: user?.profile?.phone || '',
      job: user?.profile?.job || '',
    },
    address: {
      id: user?.addresses?.[0]?.id || null,
      city: user?.addresses?.[0]?.city || '',
      country: user?.addresses?.[0]?.country || '',
    },
    email: user?.email || '',
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    setValue('profile.id', user?.profile?.id || undefined);
    setValue('profile.firstname', user?.profile?.firstname || '');
    setValue('profile.lastname', user?.profile?.lastname || '');
    setValue('address.id', user?.addresses?.[0]?.id || undefined);
    setValue('address.country', user?.addresses?.[0]?.country || '');
    setValue('address.city', user?.addresses?.[0]?.city || '');
    setValue('profile.phone', user?.profile?.phone || '');
    setValue('profile.job', user?.profile?.job || '');
    setValue('email', user?.email || '');
  }, [user]);


  return (

    <FormProvider autoComplete="off" methods={methods} onSubmit={handleSubmit(updateUser)}>
      <input type="hidden" {...methods.register('profile.id')} />
      <Box
        rowGap={3}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        }}
      >
        <RHFTextField name="profile.firstname" label={t("First name")} />
        <RHFTextField name="profile.lastname" label={t("Last name")} />
        <RHFTextField disabled name="email" label={t("Email Address")} />
        <RHFTextField name="profile.phone" label={t("Phone Number")} />
        <RHFAutocomplete
          name="address.country"
          label={t("Country")}
          options={countries.map((country) => country.label)}
          getOptionLabel={(option) => option}
          renderOption={(props, option) => {
            const { code, label, phone } = countries.filter(
              (country) => country.label === option
            )[0] || {};

            if (!label) {
              return null;
            }

            return (
              <li {...props} key={label}>
                <Iconify
                  key={label}
                  icon={`circle-flags:${code.toLowerCase()}`}
                  width={28}
                  sx={{ mr: 1 }}
                />
                {label} ({code}) +{phone}
              </li>
            );
          }}
        />
        <RHFTextField name="address.city" label={t("City")} />
        <RHFTextField name="profile.job" label={t("Job")} />
      </Box>
      <Stack direction="row" justifyContent="flex-end" spacing={1}>

        <LoadingButton sx={{ mt: 3 }} type="submit" variant="contained" loading={isSubmitting}>
          {t("Save Changes")}
        </LoadingButton>
      </Stack>
    </FormProvider>
  )
}