import { Helmet } from 'react-helmet-async';
import { useWorkspace } from 'src/providers/workspace';
import WorkspaceGeneralView from 'src/sections/workspaces/view/general';
// sections

// ----------------------------------------------------------------------

export default function Workspace() {
  useWorkspace(false)

  return (
    <>
      <Helmet>
        <title> Workspaces - General</title>
      </Helmet>
      <WorkspaceGeneralView/>
    </>
  );
}
