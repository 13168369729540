import { Helmet } from 'react-helmet-async';
import { useWorkspace } from 'src/providers/workspace';
import UserProfileView from 'src/sections/account/view';

// ----------------------------------------------------------------------

export default function AccountGeneral() {
  useWorkspace(false)

  
  return (
    <>
      <Helmet>
        <title> Account: General</title>
      </Helmet>
      <UserProfileView/>
    </>
  )
}
