import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { QueryArg } from './query';
import { AttributionResponse, Report } from './models';

const metrics = createApi({
    baseQuery: fetchBaseQuery({
        credentials: 'include',
        baseUrl: BENLY_API_URL + '/metrics',
    }),
    reducerPath: 'api.metrics',
    tagTypes: ['metrics'],
    endpoints: (builder) => ({
        report: builder.query<
            Report,
            QueryArg>({
                query: (args) => {
                    let url = `/${args.resourceType}/report?start_date=${args.startDate}&end_date=${args.endDate}`;
                    if (args.step) {
                        url = url.concat('&time_step=' + args.step);
                    }
                    if (args.attributionWindow) {
                        url = url.concat(
                            `&attribution_window=${args.attributionWindow[0]}&attribution_window=${args.attributionWindow[1] ?? ""}`
                        );
                    }

                    if (args.metrics?.length) {
                        url = url.concat("&metrics=" + args.metrics.join("&metrics="))
                    }

                    url += '&' + args.resourceIds.map((rid) => 'resource_ids=' + rid).join('&');

                    return {
                        url,
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Cache-Control': 'max-age=0',
                        },
                    };
                },
            }),
        totalsAttribution: builder.query<
            AttributionResponse,
            Omit<QueryArg, 'metrics' | 'step' | 'attributionWindow'>
        >({
            query: (args) => {
                let url = `/${args.resourceType}/attribution/totals?start_date=${args.startDate}&end_date=${args.endDate}`;
                url += ("&" + args.resourceIds.map((id) => 'resource_ids=' + id).join("&"))
                return {
                    url,
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Cache-Control': 'max-age=0',
                    },
                };
            },
        })
    }),

});

export const { useReportQuery, useTotalsAttributionQuery } = metrics;
export default metrics;