import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import {
  AnnualPerformanceResponse,
  FlowPerformanceResponse,
  SparkLinesResponse,
  TopAdsPerformanceResponse,
} from './models';
import {
  QueryAdAccount,
  QueryAttribution,
  QueryDateRange,
  QueryMode,
  QueryResourceIds,
  QueryTimeStep,
  QueryWorkspace,
} from '../shared/query';
import { formatQS } from 'src/utils/format-qs';

export const performance = createApi({
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: BENLY_API_URL + '/performance',
  }),
  reducerPath: 'api.performance',
  tagTypes: ['performance'],
  endpoints: (builder) => ({
    sparklines: builder.query<
      SparkLinesResponse,
      QueryAttribution &
        QueryDateRange &
        QueryResourceIds &
        QueryTimeStep &
        QueryAdAccount &
        QueryWorkspace &
        QueryMode
    >({
      query: (args) => {
        let url = `/sparklines?${formatQS(args)}`
        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      },
    }),
    monthly: builder.query<
      AnnualPerformanceResponse,
      QueryAttribution &
        QueryDateRange &
        QueryResourceIds &
        QueryAdAccount &
        QueryWorkspace &
        QueryTimeStep &
        QueryMode
    >({
      query: (args) => {
        let url = `/monthly?${formatQS(args)}`;
        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      },
    }),
    topAds: builder.query<
      TopAdsPerformanceResponse[],
      QueryDateRange &
        QueryAttribution &
        QueryWorkspace &
        QueryAdAccount &
        QueryResourceIds &
        QueryMode
    >({
      query: (args) => {
        let url = `/top_ads?${formatQS(args)}`
        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      },
    }),
    flow: builder.query<
      FlowPerformanceResponse[],
      QueryDateRange &
        QueryAttribution &
        QueryWorkspace &
        QueryAdAccount &
        QueryResourceIds & { scope: 'campaign' | 'adset' } & QueryMode
    >({
      query: (args) => {
        let url = `/flow?${formatQS(args)}`
        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      },
    }),
  }),
});

export const { useSparklinesQuery, useMonthlyQuery, useTopAdsQuery, useFlowQuery } = performance;
