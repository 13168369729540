import { Helmet } from "react-helmet-async";
import { useWorkspace } from "src/providers/workspace";
import ExploreList from "src/sections/explorer/view/explore-list";

export default function Builds() {
    useWorkspace(true)

    return (
        <>
            <Helmet>
                <title>Build your own dash</title>
            </Helmet>
            <ExploreList/>
        </>
    )
}