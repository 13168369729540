import { useEffect } from "react"
import { useGetSocialAccountCurrencyQuery } from "src/context/api/social"
import { useGetWorkspaceObjectsQuery } from "src/context/api/workspaces"
import { useAttributionOptions } from "src/context/reducers/attribution-settings"
import workspaceServices from "src/services/workspaces"


export const useAccountCurrency = () => {
  const adAccountId = useAttributionOptions("account")?.[0]
  const currency = useGetSocialAccountCurrencyQuery(adAccountId as string, {skip: !adAccountId})

  useEffect(() => {

  }, [adAccountId])

  return currency.data?.currency as string
}