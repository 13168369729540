import { Alert, Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import { useRouter, useSearchParams } from 'src/routes/hooks';

export default function SelectBuildType() {
  const searchParams = useSearchParams();
  const theme = useTheme();
  const router = useRouter();

  const redirect = (mode: string) => {
    searchParams.set('mode', mode);
    router.push('/explore/default?' + searchParams.toString());
  };

  return (
    <Stack spacing={2} direction={'column'}>
      <Stack
        spacing={2}
        p={2}
        borderRadius={1}
        border={'solid 1px ' + theme.palette.divider}
        flex={'0 0 auto'}
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ cursor: 'pointer' }}
        onClick={() => redirect('analysis')}
      >
        <SvgColor
          sx={{
            width: 64,
            minWidth: 64,
            maxWidth: 64,
            height: 64,
            mask: `url(/assets/icons/navbar/ic_analytics.svg) 0 0 / 64px 64px`,
            WebkitMask: `url(/assets/icons/navbar/ic_analytics.svg) 0 0 / 64px 64px`,
          }}
          src="/assets/icons/navbar/ic_analytics.svg"
          color={theme.palette.primary.light}
        />
        <Typography fontSize={12}>
          <Typography component={'span'} fontSize={14} fontWeight={'bold'}>
            Analysis Mode
          </Typography>
          <br />
          Select and visualize specific metrics. Customize your dashboard by picking the exact data
          points you want to track and analyze. Ideal for detailed insights and performance
          monitoring.
        </Typography>
        <IconButton
          onClick={() => {
            router.push('/explore/default');
          }}
        >
          <Iconify icon="eva:arrow-ios-forward-fill" />
        </IconButton>
      </Stack>
      <Stack
        spacing={2}
        p={2}
        borderRadius={1}
        border={'solid 1px ' + theme.palette.divider}
        flex={'0 0 auto'}
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        onClick={() => redirect('comparison')}
        sx={{ cursor: 'pointer' }}
      >
        <SvgColor
          sx={{
            width: 64,
            minWidth: 64,
            maxWidth: 64,
            height: 64,
            mask: `url(/assets/icons/setting/ic_align_left.svg) 0 0 / 64px 64px`,
            WebkitMask: `url(/assets/icons/setting/ic_align_left.svg) 0 0 / 64px 64px`,
          }}
          src="/assets/icons/setting/ic_align_left.svg"
          color={theme.palette.primary.light}
        />
        <Typography fontSize={12}>
          <Typography component={'span'} fontSize={14} fontWeight={'bold'}>
            Comparison Mode
          </Typography>
          <br />
          Compare groups of creatives based on campaign names, ad sets, ads, or ad types. Group and
          analyze data to understand performance differences and identify trends across your
          marketing efforts. Perfect for comparative analysis and optimization.
        </Typography>
        <IconButton>
          <Iconify icon="eva:arrow-ios-forward-fill" />
        </IconButton>
      </Stack>
    </Stack>
  );
}

export function NewBuildHeading({ children }: { children?: JSX.Element }) {
  return (
    <>
      <Stack spacing={2} direction={'row'} alignItems={'center'} justifyContent={'center'}>
        <Iconify icon="solar:add-circle-bold" />
        <Typography variant="h6">Create Dashboard</Typography>
        <Box flexGrow={1} />
        {children ? children : ''}
      </Stack>
      <Alert severity="info">
        Create your custom dashboard by choosing between detailed metric analysis or comparing
        creative performance across campaigns, ad sets, and ad types
      </Alert>
    </>
  );
}
