import { Container, Grid, Stack } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import AttributionSelect from 'src/components/generic-select/attribution-select';
import DateRangeSelect, {
  useDateRangeSelect,
} from 'src/components/generic-select/date-range-select';
import { useSettingsContext } from 'src/components/settings';
import { useHookRateQuery, useTableQuery } from 'src/context/api/hook-rate';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { SplashScreen } from 'src/components/loading-screen';
import { useLocales } from 'src/locales';
import Creative from 'src/sections/explorer/card';
import Table__ from 'src/sections/explorer/table';
import TopCreativePerformance from 'src/sections/overview/dashboards/home-creative-performance';
import GenericFilter from 'src/components/generic-select';
import { getOptions, useAttributionOptions } from 'src/context/reducers/attribution-settings/selectors';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { useDispatch } from 'react-redux';
import { resetAttributionSettings } from 'src/context/reducers/attribution-settings';
import EmptyResult from 'src/components/no-data/empty-result';

export default function HookRate() {
  const settings = useSettingsContext();
  const workspaceService = useCurrentWorkspace();
  const { t } = useLocales();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetAttributionSettings());
  }, []);

  const {
    attributionWindow,
    start: startDate,
    end: endDate,
    resourceType,
    resourceIds,
    timeStep,
    adAccountId,
  } = getOptions();
  const mode = useAttributionOptions('mode');

  const {
    data: { cards } = {},
    isFetching: isFetchingCards,
    isLoading: isLoadingCards,
    isUninitialized: isUninitializedCards,
  } = useHookRateQuery(
    {
      section: 'cards',
      resourceIds,
      resourceType,
      adAccountId,
      startDate,
      endDate,
      timeStep,
      attributionWindow,
      workspace: workspaceService?.id as number,
      mode,
    },
    { skip: !workspaceService }
  );
  const {
    data: { lines } = {},
    isFetching: isFetchingLines,
    isLoading: isLoadingLines,
    isUninitialized: isUninitializedLines,
  } = useHookRateQuery(
    {
      section: 'lines',
      resourceIds,
      resourceType,
      adAccountId,
      startDate,
      endDate,
      timeStep,
      attributionWindow,
      workspace: workspaceService?.id as number,
      mode,
    },
    { skip: !workspaceService }
  );
  const {
    data: { table } = {},
    isFetching: isFetchingTable,
    isLoading: isLoadingTable,
    isUninitialized: isUninitializedTable,
  } = useHookRateQuery(
    {
      section: 'table',
      resourceIds,
      resourceType,
      adAccountId,
      startDate,
      endDate,
      timeStep,
      attributionWindow,
      workspace: workspaceService?.id as number,
      mode,
    },
    { skip: !workspaceService }
  );

  const isFetching = isFetchingCards || isFetchingLines || isFetchingTable;
  const isLoading = isLoadingCards || isLoadingLines || isLoadingTable;
  const isUninitialized = isUninitializedCards || isUninitializedLines || isUninitializedTable;
  const isEmpty = useMemo(() => cards?.length === 0 && !isFetching && !isUninitialized, [cards, isFetching, isUninitialized]);

  if (isFetching || isLoading || isUninitialized) {
    return <SplashScreen />;
  }



  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Engagement')}
        links={[
          {
            name: t(
              'Delve into essential video engagement analytics like Hook Rate, Thumbstop Rate, and Video Completion Rate in our Engagement Analytics Dashboard, offering actionable insights to optimize your creative campaigns'
            ),
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Stack direction={'row'} spacing={2}>
            <GenericFilter />
          </Stack>
        </Grid>
        <Grid item container spacing={2} xs={12}>
          {isEmpty ? (
            <EmptyResult />
          ) : (
            cards?.slice(0, 8).map((data) => (
              <Grid key={data.creative_id + 'esh'} item xs={12} md={3}>
              <Creative
                name={data.ad_name}
                applyColor
                id={data.creative_pk}
                metrics={Object.fromEntries(
                  Object.entries(data)
                    .filter(([key]) =>
                      ['hook_rate', 'thumbstop_click_rate', 'video_completion_rate'].includes(key)
                    )
                    .map(([key, value]) => [key, [key, value]])
                )}
              />
              </Grid>
            ))
          )}
        </Grid>
        <Grid item xs={12}>
          {!isEmpty && (
            <TopCreativePerformance
              title={t('Video Completion Rate over time')}
              chart={{
                series: [
                {
                  type: t('Video Completion Rate'),
                  id: 'video_completion_rate',
                  data:
                    lines?.map((creative) => {
                      return {
                        name: creative.ad_name || (creative as any).info?.[0]?.ad_name,
                        data: creative.data.video_completion_rate,
                      } as any;
                    }) || [],
                },
                {
                  type: t('Thumbstop Click Rate'),
                  id: 'thumbstop_click_rate',
                  data:
                    lines?.map((creative) => {
                      return {
                        name: creative.ad_name || (creative as any).info?.[0]?.ad_name,
                        data: creative.data.thumbstop_click_rate,
                      } as any;
                    }) || [],
                },
                {
                  type: t('Hook Rate'),
                  id: 'hook_rate',
                  data:
                    lines?.map((creative) => {
                      return {
                        name: creative.ad_name || (creative as any).info?.[0]?.ad_name,
                        data: creative.data.hook_rate,
                      } as any;
                    }) || [],
                },
              ],
              }}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {!isEmpty && (
            <Table__
              loading={isFetchingTable}
              data={table}
              tableHead={[
              {
                id: 'thumbnail',
                label: 'Name',
                alignRight: false,
              },
              {
                id: 'hook_rate',
                label: 'Hook Rate',
                alignRight: false,
                format: (row: any) => row.hook_rate.toFixed(2) + '%',
              },
              {
                id: 'video_completion_rate',
                label: 'Completion Rate',
                alignRight: false,
                format: (row: any) => row.video_completion_rate.toFixed(2) + '%',
              },
              {
                id: 'thumbstop_click_rate',
                label: 'Thumbstop Click Rate',
                alignRight: false,
                format: (row: any) => row.thumbstop_click_rate.toFixed(2) + '%',
              },
              mode === 'sales' ? {
                id: 'roas',
                label: 'Return on Ad Spend (ROAS)',
                alignRight: false,
                format: (row: any) => row.roas.toFixed(2),
              } : {
                id: 'lead',
                label: 'Leads',
                alignRight: false,
                format: (row: any) => row.lead,
              },
              mode === 'sales' ? {
                id: 'cpa',
                label: 'Cost Per Acquisition (CPA)',
                alignRight: false,
                format: true,
              } : {
                id: 'cost_per_lead  ',
                label: 'Lead Cost',
                alignRight: false,
                format: true,
              },
              {
                id: 'cpc',
                label: 'Cost Per Click (CPC)',
                alignRight: false,
                format: true,
              },
              {
                id: 'cpm',
                label: 'Cost Per Thousand Impressions (CPM)',
                alignRight: false,
                format: true,
              },
              {
                id: 'ctr',
                label: 'Click-Through Rate (CTR)',
                alignRight: false,
                format: (row: any) => row.ctr.toFixed(2) + '%',
              },
              mode === 'sales' ? {
                id: 'aov',
                label: 'Average Order Value (AOV)',
                alignRight: false,
                format: true,
              } : null,
              mode === 'sales' ? {
                id: 'click_ratio:omni_add_to_cart',
                label: 'Click to ATC Ratio',
                alignRight: false,
                format: (row: any) => row['click_ratio:omni_add_to_cart'].toFixed(2) + '%',
              } : null,
              mode === 'sales' ? {
                id: 'click_ratio:omni_purchase',
                label: 'Click to Purchase Ratio',
                alignRight: false,
                format: (row: any) => row['click_ratio:omni_purchase'].toFixed(2) + '%',
              } : null,
              mode === 'sales' ? {
                id: 'atc_conversion_value',
                label: 'ATC Conversion Value',
                alignRight: false,
                format: true,
              } : null,
              mode === 'sales' ? {
                id: 'checkout_conversion_value',
                label: 'Checkout Conversion Value',
                alignRight: false,
                format: true,
              } : null,
              {
                id: 'video_p25_watched_actions',
                label: 'Video Play Rate (25%)',
                alignRight: false,
                format: (row: any) => row.video_p25_watched_actions.toFixed(2),
              },
              {
                id: 'video_p50_watched_actions',
                label: 'Video Play Rate (50%)',
                alignRight: false,
                format: (row: any) => row.video_p50_watched_actions.toFixed(2),
              },
              {
                id: 'video_p75_watched_actions',
                label: 'Video Play Rate (75%)',
                alignRight: false,
                format: (row: any) => row.video_p75_watched_actions.toFixed(2),
              },
              {
                id: 'video_p100_watched_actions',
                label: 'Video Play Rate (100%)',
                alignRight: false,
                format: (row: any) => row.video_p100_watched_actions.toFixed(2),
              },
            ].filter(Boolean) as any}
            button={false}
            toolbar
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

function applyFilter({
  inputData,
  comparator,
  filters,
}: {
  inputData: any[];
  comparator: (a: any, b: any) => number;
  filters: { name: string };
}) {
  const { name } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (name.length > 0) {
    inputData = inputData.filter((row) => row.creative.body.name.includes(name as string));
  }

  return inputData;
}
