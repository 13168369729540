import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { TimeStep, initialState } from './state';
import { ResourceDepth } from 'src/components/generic-select/resource-select';

const attributionSettings = createSlice({
  name: 'attribution-settings',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    selectDatePreset(state, action: PayloadAction<typeof initialState.datePreset>) {
      state.datePreset = action.payload;
    },
    storeObjects(state, action: PayloadAction<["ads" | "adsets" | "campaigns" | "account", string[] | any]>) {
      const [depth, objects] = action.payload
      state[depth] = objects
    },
    storeObjectType(state, action: PayloadAction<string>) {
      state.resourceTypeSelected = action.payload;
    },
    selectAttributionWindow(state, action: PayloadAction<[string | null, string | null]>) {
      state.attributionWindow = action.payload;
    },
    selectTimeStep(state, action: PayloadAction<TimeStep>) {
      state.timeStep = action.payload;
    },
    setStart(state, action: PayloadAction<number | string>) {
      state.start = action.payload;
    },
    setEnd(state, action: PayloadAction<number | string>) {
      state.end = action.payload;
    },
    setDateSelection(state, action: PayloadAction<'custom' | 'preset'>) {
      state.dateSelection = action.payload;
    },
    resetAttributionSettings(state) {
      state.start = initialState.start;
      state.end = initialState.end;
      state.dateSelection = initialState.dateSelection;
      state.attributionWindow = initialState.attributionWindow;
      state.resourceTypeSelected = initialState.resourceTypeSelected;
      state.datePreset = initialState.datePreset;
      state.loading = initialState.loading;
    },
    setMode(state, action: PayloadAction<'lead' | 'sales'>) {
      state.mode = action.payload;
    }
  },
});

export const {
  storeObjects,
  selectAttributionWindow,
  selectDatePreset,
  storeObjectType,
  setStart,
  setEnd,
  setDateSelection,
  setLoading,
  resetAttributionSettings,
  selectTimeStep,
  setMode,
} = attributionSettings.actions;

export default attributionSettings.reducer;
