import React from 'react';
import { Modal, Card, CardContent, CardHeader, CardActions, Typography, IconButton, Box } from '@mui/material';
import { Icon } from '@iconify/react';

interface CustomModalProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  actions?: React.ReactNode;
  rightElement?: React.ReactNode;
  size?: 'small' | 'semiSmall' | 'medium' | 'large';
}

const sizeMap = {
  small: '500px',
  semiSmall: '700px',
  medium: '1000px',
  large: '1300px',
};

export default function CustomModal({ open, onClose, title, children, actions, rightElement, size = 'medium' }: CustomModalProps) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(255, 255, 255, 0.8)"
        }
      }}
    >
      <Card
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: sizeMap[size],
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          overflowY: 'scroll',
          height: '90%',
        }}
      >
        {title && (
          <CardHeader
            title={<Typography variant="h6">{title}</Typography>}
            action={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {rightElement && (
                  <Box sx={{ marginRight: 2 }}>
                    {rightElement}
                  </Box>
                )}
                <IconButton onClick={onClose}>
                  <Icon icon="mdi:close" width={24} height={24} />
                </IconButton>
              </Box>
            }
          />
        )}
        <CardContent>{children}</CardContent>
        {actions && <CardActions>{actions}</CardActions>}
      </Card>
    </Modal>
  );
}
