import {
  Stack,
  Typography,
  Button,
  List,
  Icon,
  ListItem,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useLocales } from 'src/locales';
import useSocial from 'src/services/social';
import { getProviders } from './providers';


const TABLE_HEAD = [
  { id: 'name', label: 'Name', width: 220 },
];

export default function Connect() {
  const { t } = useLocales()
  const social = useSocial();

  const providers = getProviders()

  const ConnectorStatus = ({ provider }: { provider: string }) => {
    let icon = "eva:checkmark-circle-2-fill"
    let color = "success.main"
    let text = "Active"
    let connectText = "Connected"

    const isActive = social.accounts?.includes(provider)
    if (!isActive) {
      icon = "eva:close-circle-fill"
      color = "error.main"
      text = "Inactive"
      connectText = "Connect"
    }

    return (
      <>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
          spacing={1}
        >
          <Iconify fontSize={14} icon={icon} color={color} />
          <Typography fontSize={14} color={color}>
            {t(text)}
          </Typography>
        </Stack>
        <Button sx={{ ml: 1 }} size="medium" variant="contained" onClick={social.connect(provider)}>{t(connectText)}</Button>
      </>
    )
  }

  return (
    <Stack alignItems={"center"} justifyContent={"center"} alignContent={"center"} spacing={2} direction={"column"} my={3}>
      <List sx={{ width: "100%" }}>
        {Object.entries(providers).map(([provider, providerData]) => (
          <ListItem sx={{ mt: 2 }} key={`${provider}_connector`}>
            <Icon component={"img"} src={providerData.img}/>
            <Typography ml={2} sx={{ whiteSpace: 'nowrap' }}>{providerData.name}</Typography>
            <Stack width={"100%"} direction={"row"} justifyContent={"flex-end"}>
              {providerData.available ?
                <ConnectorStatus provider={provider} />
                :
                <>
                  <Iconify fontSize={14} py={"auto"} icon={"eva:close-circle-fill"} color={"error.main"} />
                  <Typography fontSize={14} color={"error.main"}>{t("Inactive")}</Typography>
                </>
              }
            </Stack>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}
