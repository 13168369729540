import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { QueryExternalObjectIds, QueryWorkspace } from '../shared/query';
import {  AddOrRemoveAd, CreateTag, DeleteTag, EditTag, Tags, MarketingObjectTag, Tag } from './models';
import { formatQS } from 'src/utils/format-qs';

const tags = createApi({
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: BENLY_API_URL + '/tags',
  }),
  reducerPath: 'api.tags',
  tagTypes: ['tags', 'getTags'],
  endpoints: (builder) => ({
    GetTags: builder.query<Tags, QueryWorkspace & QueryExternalObjectIds>({
      providesTags: ['getTags'],
      query: (args) => {
        let url = `/get-tags?${formatQS(args)}`;
        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    }),
    CreateTag: builder.mutation<Tag, CreateTag>({
      invalidatesTags: ['getTags'],
      query: (args) => {
        let url = `/create`;
        return {
          url,
          method: 'POST',
          body: args,
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    }),
    EditTag: builder.mutation<Tag, EditTag>({
      invalidatesTags: ['getTags'],
      query: (args) => {
        let url = `/edit`;
        return {
          url,
          method: 'PUT',
          body: args,
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    }),
    DeleteTag: builder.mutation<{message: string, deleted_tag: Tag}, DeleteTag>({
      invalidatesTags: ['getTags'],
      query: (args) => {
        let url = `/delete`;
        return {
          url,
          method: 'DELETE',
          body: args,
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    }),
    AddAd: builder.mutation<{message: string, deleted_tag: MarketingObjectTag}[], AddOrRemoveAd>({
      invalidatesTags: ['getTags'],
      query: (args) => {
        let url = `/ad/add`;
        return {
          url,
          method: 'POST',
          body: args,
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    }),
    RemoveAd: builder.mutation<{message: string, deleted_tag: MarketingObjectTag}[], AddOrRemoveAd>({
      invalidatesTags: ['getTags'],
      query: (args) => {
        let url = `/ad/remove`;
        return {
          url,
          method: 'PUT',
          body: args,
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    }),

  }),
});

export const { useGetTagsQuery, useCreateTagMutation, useEditTagMutation, useDeleteTagMutation, useAddAdMutation, useRemoveAdMutation } = tags;
export default tags;
