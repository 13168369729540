import { Button } from "@mui/material";
import { Stack } from "@mui/system";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import CustomPopover, { usePopover } from "src/components/custom-popover";
import { getOptions, useAttributionOptions } from "src/context/reducers/attribution-settings";
import { selectAdGroup, selectAdType } from "src/context/reducers/explorer/slice";
import useExplorerConfiguration from "src/hooks/use-explorer";

export default function ExplorerPopover() {
    const actionPopover = usePopover()
    const config = useExplorerConfiguration()
    const options = getOptions()
    const adType = useSelector(selectAdType)
    const adGroup = useSelector(selectAdGroup)
    const dateSelection = useAttributionOptions("dateSelection")
    const datePreset = useAttributionOptions("datePreset")


    const processConfig = useCallback(() => {
        const newFilter = Object.assign({}, config.raw.filter)
        newFilter.ad_type = adType || ["image", "video", "carousel"]
        newFilter.ad_group = adGroup || "creative"
        if (options.attributionWindow) {
            newFilter.attribution_window = options.attributionWindow
        }

        if (options.start && options.end) {
            newFilter.start = options.start
            newFilter.end = options.end
        }

        if (dateSelection) {
            newFilter.date_selected = dateSelection
        }

        if (datePreset) {
            newFilter.date_preset = datePreset as any
        }

        if (options.resourceIds) {
            newFilter.resource_id = options.resourceIds
            newFilter.resource_type = options.resourceType

            newFilter[options.resourceType as keyof typeof newFilter] = options.resourceIds as any
        }

        return newFilter
    }, [config, adType, adGroup, options, dateSelection, datePreset])


    return (
        <>
            {
                config.editable ? (
                    <Button variant="contained" onClick={() => {
                        config.update(Object.assign({}, config.draft, {
                            filter: processConfig() as any
                        }) as any)
                    }}>
                        Save current report
                    </Button>
                )
                    :
                    <Button variant="contained" onClick={() => {
                        config.duplicate(Object.assign({}, config.draft, {
                            filter: processConfig() as any
                        }) as any)
                    }}>
                        Save as new report
                    </Button>
            }
            <Button variant="outlined" onClick={actionPopover.onOpen}>
                More options +
            </Button>
            <CustomPopover
                open={actionPopover.open}
                onClose={actionPopover.onClose}
            >
                <Stack>
                    {
                        config.editable && (
                            <Button fullWidth sx={{ textAlign: "left", justifyContent: "flex-start" }}>
                                Save as new report
                            </Button>
                        )
                    }
                    <Button color="error" fullWidth sx={{ textAlign: "left", justifyContent: "flex-start" }}>
                        Delete
                    </Button>
                </Stack>
            </CustomPopover>
        </>
    )
}