import {
  Box,
  Checkbox,
  Stack,
  SxProps,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme
} from "@mui/material";

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
} as const;

type Props = {
  order?: 'asc' | 'desc';
  orderBy?: string;
  headLabel: any[];
  rowCount?: number;
  numSelected?: number;
  onSort?: (id: string) => void;
  onSelectAllRows?: (checked: boolean) => void;
  sx?: SxProps<Theme>;
  stack?: boolean;
};

export default function TableHeadCustom(props: Props) {
  if (props.stack) {
    return <TableHeadCustomStack {...props} />
  }

  const { onSelectAllRows, numSelected, rowCount, order, orderBy, onSort, sx, headLabel } = props;

  return (
    <TableHead sx={{ ...sx, height: "56px" }}>
      <TableRow>
        {headLabel.map((headCell, idx) => (
          <TableCell
            className={`head-${headCell.id}`}
            key={idx}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ width: headCell.width, minWidth: headCell.minWidth }}
          >
            <Stack sx={{
              width: "36px",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}>
              {(onSelectAllRows && idx === 0) && (
                <Checkbox
                  indeterminate={!!numSelected && numSelected < (rowCount as number)}
                  checked={!!rowCount && numSelected === rowCount}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onSelectAllRows(event.target.checked)
                  }
                />
              )}
            </Stack>
            {onSort ? (
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => onSort(headCell.id)}
              >
                {headCell.label}

                {orderBy === headCell.id ? (
                  <Box sx={{ ...visuallyHidden }}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export function TableHeadCustomStack({
  headLabel,
  onSelectAllRows,
  numSelected,
  rowCount,
  order,
  orderBy,
  onSort,
}: Props) {
  return (
    <Stack padding={'0 8px 0 16px'} direction={'row'}>
      {onSelectAllRows && (
        <Stack padding="checkbox">
          <Checkbox
            indeterminate={!!numSelected && numSelected < (rowCount as number)}
            checked={!!rowCount && numSelected === rowCount}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onSelectAllRows(event.target.checked)
            }
          />
        </Stack>
      )}

      {headLabel.map((headCell, idx) => (
        <Stack
          alignItems={headCell.align || (headCell.alignRight ? "right" : "left")}
          justifyContent={headCell.align || (headCell.alignRight ? "right" : "left")}
          direction={'row'}
          className={`head-${headCell.id}`}
          key={idx}
          sx={{ width: headCell.width, minWidth: headCell.minWidth }}
        >
          {onSort ? (
            <TableSortLabel
              hideSortIcon
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={() => onSort(headCell.id)}
            >
              {headCell.label}

              {orderBy === headCell.id ? (
                <Box sx={{ ...visuallyHidden }}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          ) : (
            headCell.label
          )}
        </Stack>
      ))}
    </Stack>
  )
}