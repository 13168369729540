import { ButtonBase, InputAdornment, MenuItem, TextField } from "@mui/material";
import CustomPopover from "./custom-popover";
import { MenuPopoverProps } from "./types";
import usePopover from "./use-popover";
import Iconify from "../iconify";
import { useState } from "react";
import Scrollbar from "../scrollbar";


interface AdvancedPopoverProps<T> {
    options: T[]
    onSelect: (option: T) => void
    renderOption: (option: T) => React.ReactNode
    renderValue: (option: T) => React.ReactNode
    value: T
}

interface Option {
    label: string
    value: string
}

export default function AdvancedPopover<T extends Option>({
    options,
    onSelect,
    renderOption,
    renderValue,
    value,
}: AdvancedPopoverProps<T>) {
    const displayOptions = usePopover()
    const [search, setSearch] = useState<string>("")

    return (
        <>
            <ButtonBase
                onClick={displayOptions.onOpen}
                sx={{
                    pl: 1,
                    py: 0.5,
                    pr: 0.5,
                    borderRadius: 1,
                    typography: 'subtitle2',
                    bgcolor: 'background.neutral',
                }}
            >
                {renderValue(value)}
                <Iconify
                    width={16}
                    icon={
                        displayOptions.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'
                    }
                    sx={{ ml: 0.5 }}
                />
            </ButtonBase>
            <CustomPopover sx={{height: 300, overflow: 'hidden'}} open={displayOptions.open} onClose={displayOptions.onClose}>
                <TextField
                    fullWidth
                    InputProps={{
                        startAdornment: <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" />
                        </InputAdornment>
                    }}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search"
                />
                <Scrollbar>
                    {options.filter((option) => option.label.toLowerCase().includes(search.toLowerCase())).map((option) => (
                        <MenuItem key={option.value} onClick={() => {
                            onSelect(option)
                            displayOptions.onClose()
                        }}>
                            {renderOption(option)}
                        </MenuItem>
                    ))}
                </Scrollbar>
            </CustomPopover>
        </>
    )
}