import {
  Skeleton,
  Stack
} from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useAttributionOptions } from 'src/context/reducers/attribution-settings/selectors';
import { useDispatch } from 'react-redux';
import {
  TimeStep,
  selectAttributionWindow,
  selectDatePreset,
  setDateSelection,
  setEnd,
  setStart,
  storeObjectType,
  selectTimeStep
} from 'src/context/reducers/attribution-settings';
import { AttributionWindowClick, AttributionWindowView } from 'src/context/api/shared/models';
import DateRangeSelect, { useDateRangeSelect } from './date-range-select';
import { ResourceDepth, useObjects, useObjectsSelect } from './resource-select';
import AttributionSelect from './attribution-select';
import Select from '../select/advanced';
import { parse } from 'date-fns';
import { fDate } from 'src/utils/format-time';
import { presetValues } from 'src/hooks/use-date-preset';

interface Item {
  id: number;
  name: string;
  selected?: boolean;
  default?: boolean;
}

interface Features {
  presets?: boolean;
  time_step?: boolean;
}

interface FilterProps {
  variant?: "light";
  onStartDateChange?: (date?: Date | null) => void;
  onEndDateChange?: (date?: Date | null) => void;
  features?: Features;
  level?: ResourceDepth;
}

export default function GenericFilter(props: FilterProps) {
  const dispatch = useDispatch();

  const datePreset = useAttributionOptions('datePreset');
  const timeStep = useAttributionOptions('timeStep');
  const start = useAttributionOptions('start');
  const end = useAttributionOptions('end');
  const dateSelection = useAttributionOptions('dateSelection');
  const attributionWindow = useAttributionOptions('attributionWindow');


  const [startDate, endDate] = useMemo(() => {
    if (start && end) {
      return [parse(start as string, "yyyy-MM-dd", new Date()), parse(end as string, "yyyy-MM-dd", new Date())]
    }
    return [undefined, undefined]
  }, [start, end])

  const { isFetching, isUninitialized, objectByType } = useObjects(startDate as Date, endDate as Date, attributionWindow as [string, string])

  function opts<T>(depth: T) {
    return [
      startDate as Date,
      endDate as Date,
      attributionWindow as [string, string],
      depth
    ] as const
  }

  const selectOpts = useCallback(opts, [startDate, endDate, attributionWindow])

  const selectAds = useObjectsSelect(...selectOpts("ad" as const))
  const selectAdsets = useObjectsSelect(...selectOpts("adset" as const))
  const selectCampaigns = useObjectsSelect(...selectOpts("campaign" as const))
  const selectAccount = useObjectsSelect(...selectOpts("account" as const))

  useEffect(() => {
    if (!!selectAccount.selected) {
      dispatch(storeObjectType("account"))
    }
    else if (selectAds.selected.length > 0) {
      dispatch(storeObjectType("ad"))
    }
    else if (selectAdsets.selected.length > 0) {
      dispatch(storeObjectType("adset"))
    }
    else if (selectCampaigns.selected.length > 0) {
      dispatch(storeObjectType("campaign"))
    }
  }, [selectAds, selectAdsets, selectCampaigns, selectAccount])


  if (isFetching || isUninitialized) {
    return <Skeleton animation="wave" width="50%" height={36} />
  }

  return (
    <Stack spacing={1} flexDirection={{ sm: 'column', md: 'row' }}>
      <DateRangeSelect
        features={{time_step: true}}
        variant={props.variant}
        selected={dateSelection === "preset" ? presetValues[datePreset as string].displayName : `${start} - ${end}`}
        onChangeEndDate={(date) => {
          dispatch(setDateSelection("custom"))
          dispatch(setEnd(fDate(date, "yyyy-MM-dd")))
        }}
        onChangeStartDate={(date) => {
          dispatch(setDateSelection("custom"))
          dispatch(setStart(fDate(date, "yyyy-MM-dd")))
        }}
        onChangePreset={(preset) => {
          dispatch(setDateSelection("preset"))
          dispatch(selectDatePreset(preset))
          dispatch(setStart(fDate(presetValues[preset].start, "yyyy-MM-dd")))
          dispatch(setEnd(fDate(presetValues[preset].end, "yyyy-MM-dd")))
        }}
        onChangeTimeStep={(timeStep) => {
          dispatch(selectTimeStep(timeStep))
        }}
        startDate={startDate as Date}
        endDate={endDate as Date}
        preset={datePreset as string}
        timeStep={timeStep as TimeStep}
      />
      {objectByType(ResourceDepth.AD_ACCOUNT).length > 0 && <Select
        label="Account"
        variant={props.variant}
        {...selectAccount}
      />}
      {objectByType(ResourceDepth.CAMPAIGN).length > 0 && <Select
        label='Campaigns'
        variant={props.variant}
        {...selectCampaigns}
      />}
      {objectByType(ResourceDepth.ADSET).length > 0 && <Select
        label='Adsets'
        variant={props.variant}
        {...selectAdsets}
      />}
      {objectByType(ResourceDepth.AD).length > 0 && <Select
        label='Ads'
        variant={props.variant}
        {...selectAds}
      />}
      <AttributionSelect
        variant={props.variant}
        onWindowChange={(attributionWindow) => {
          dispatch(selectAttributionWindow(attributionWindow));
        }}
        attributionWindow={attributionWindow as [AttributionWindowClick, AttributionWindowView]}
      />
    </Stack>
  );
}
