import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import { useWorkspace } from "src/providers/workspace";
import { TopVideoDashboard } from "src/sections/overview/dashboards/views/dashboard-top-videos";

export function DashboardVideo() {
  
  useWorkspace(true)

  const {t} = useLocales()
  return (
    <>
      <Helmet>
        <title>{t('home')}</title>
      </Helmet>

      <TopVideoDashboard />
    </>
  );
}