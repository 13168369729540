import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Link, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useForm } from 'react-hook-form';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import Iconify from 'src/components/iconify';
import { useResetRequestMutation } from 'src/context/api/auth';
import { useLocales } from 'src/locales';
import { RouterLink } from 'src/routes/components';
import * as Yup from 'yup';

export default function ForgotPassword() {
  const { t } = useLocales();
  const schema = Yup.object({
    email: Yup.string().required(t('auth.form.email_must_be_filled')),
  });
  const [requestReset, requestResult] = useResetRequestMutation()
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  });
  const {
    formState: { isSubmitting },
  } = methods;
  return (
    <FormProvider methods={methods} onSubmit={methods.handleSubmit(requestReset)}>
      <Stack spacing={2} sx={{ mb: 5 }}>
        <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
          <Typography variant="h4">{t('auth.form.forgot')}</Typography>

          <Stack direction="row" spacing={0.5}>
            <Typography textAlign={"center"} fontSize={12} variant="subtitle2">
              Please enter the email address associated with your account, and we'll email you a link to reset your password.
            </Typography>
          </Stack>
          <Stack direction={"row"}>

          { requestResult.isSuccess
              ?
              <Alert severity='info'>
                If the email is found with an account, we will send you a mail containing reset password procedure
              </Alert>
              :
              requestResult.isError
              ?
              <Alert severity='error'>
                Unknown error occured, please retry later
              </Alert>
              :
              ""
            }
          </Stack>
        </Stack>
        <RHFTextField name="email" label={t('auth.form.signin.Email')} />
        <LoadingButton
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          fullWidth
          loading={isSubmitting}
        >
          {t('auth.form.resquest_reset')}
        </LoadingButton>
      </Stack>
      <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
        <Iconify icon={"heroicons:chevron-left-16-solid"}/> <Link fontSize={13} fontWeight={"bold"} sx={{textDecoration: "none"}} color={"black"} href="/auth/signin">Return to signin</Link>
      </Stack>
    </FormProvider>
  );
}
