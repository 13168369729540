import { Alert, Button, Container, Dialog, DialogContent, DialogTitle, Grid, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import AttributionSelect from "src/components/generic-select/attribution-select";
import DateRangeSelect, { useDateRangeSelect } from "src/components/generic-select/date-range-select";
import { ResourceDepth } from "src/components/generic-select/resource-select";
import { useSettingsContext } from "src/components/settings";
import { useFatigueQuery, useTableQuery } from "src/context/api/hook-rate";
import { AttributionWindowClick, AttributionWindowView } from "src/context/api/shared/models";
import { useCurrentWorkspace } from "src/context/reducers/app-settings";
import { fDate } from "src/utils/format-time";
import { dateRange } from "src/utils/date-utils";
import { TableEmptyRows, TableHeadCustom, TableNoData, emptyRows, getComparator, useTable } from "src/components/table";
import { useMarketingNumberFormat } from "src/hooks/marketing-format";
import Scrollbar from "src/components/scrollbar";
import { SplashScreen } from "src/components/loading-screen";
import AnalyticsWebsiteVisits from "src/sections/overview/analytics/analytics-website-visits";
import Iconify from "src/components/iconify";
import { useBoolean } from "src/hooks/use-boolean";
import { useLocales } from "src/locales";
import GenericFilter from "src/components/generic-select";
import { getSelectedObjects, useAttributionOptions } from "src/context/reducers/attribution-settings/selectors";

export default function AdFatigue() {
    const settings = useSettingsContext()
    const workspaceService = useCurrentWorkspace()
    const dateRangeSelect = useDateRangeSelect({
        preset: "last_30d",
        step: "week"
    });
    const account = useAttributionOptions("account")
    const [resourceType, resourceIds] = getSelectedObjects()
    const step = dateRangeSelect.current.step
    const info = useBoolean(true)
    const categories = dateRange(
        dateRangeSelect.current.start,
        dateRangeSelect.current.end,
        { step: dateRangeSelect.current.step }
    );
    const mode = useAttributionOptions("mode")
    const categoriesOptions = categories.map((c) => fDate(c, step === "day" ? "yyyy-MM-dd" : step === "month" ? "yyyy-MM" : step === "week" ? "yyyy-ww" : "yyyy"))
    const {t} = useLocales()
    const [attributionWindow, setAttributionWindow] = useState<[AttributionWindowClick, AttributionWindowView]>([AttributionWindowClick.d1, AttributionWindowView.d7]);

    const fatigue = useFatigueQuery({
        resourceIds,
        resourceType,
        adAccountId: account?.[0] as string,
        startDate: fDate(dateRangeSelect.current.start, "yyyy-MM-dd"),
        endDate: fDate(dateRangeSelect.current.end, "yyyy-MM-dd"),
        timeStep: dateRangeSelect.current.step,
        attributionWindow,
        workspace: workspaceService?.id as number,
        mode
    }, { skip: !workspaceService || !resourceIds || !resourceType || !account })

    const options = {
        yaxis: {
            labels: {
                formatter: (value: any) => value / 1000 > 0.9 ? `${(value / 1000).toFixed(2).replace(".00", "")}K` : value > 1 ? value.toFixed(0) : value.toFixed(2)
            }
        },
    }


    if (fatigue.isFetching) {
        return <SplashScreen />
    }

    return (
        <Container maxWidth={settings.themeStretch ? false : 'xl'}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Stack direction={"row"} spacing={2}>
                        <GenericFilter/>
                    </Stack>
                </Grid>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={12} md={4}>
                        <Button onClick={info.onTrue} startIcon={<Iconify icon={"eva:info-fill"} />} variant="contained">
                            {t("How to read this dash")}
                        </Button>
                    </Grid>
                </Grid>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={12} md={6}>
                        <AnalyticsWebsiteVisits
                            title="CPC"
                            chart={{
                                options,
                                categories: categories.map(c => fDate(c)),
                                series: fatigue.data?.lines.flatMap((data: any) => ["cpc"].map((metric) => ({
                                    name: (Object.values(data.data)[0] as any)?.creative.body.name.replace(/\d+-\d+-\d+-.+$/, ""),
                                    type: "line",
                                    data: categoriesOptions.map(c => data?.data?.[c]?.[metric as keyof typeof data.data] || 0)
                                }))) || []
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>

                        <AnalyticsWebsiteVisits
                            title={t("CTR")}
                            chart={{
                                options,
                                categories: categories.map(c => fDate(c)),
                                series: fatigue.data?.lines.flatMap((data: any) => ["ctr"].map((metric) => ({
                                    name: (Object.values(data.data)[0] as any)?.creative.body.name.replace(/\d+-\d+-\d+-.+$/, ""),
                                    type: "line",
                                    data: categoriesOptions.map((c) => data?.data?.[c]?.[metric as keyof typeof data.data] || 0)
                                }))) || []
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AnalyticsWebsiteVisits
                            title={t("Frequency")}
                            chart={{
                                options,
                                categories: categories.map(c => fDate(c)),
                                series: fatigue.data?.lines.flatMap((data: any) => ["frequency"].map((metric) => ({
                                    name: (Object.values(data.data)[0] as any)?.creative.body.name.replace(/\d+-\d+-\d+-.+$/, ""),
                                    type: "line",
                                    data: categoriesOptions.map((c) => data?.data?.[c]?.[metric as keyof typeof data.data] || 0)
                                }))) || []
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>

                        <AnalyticsWebsiteVisits
                            title={t("ROAS")}
                            chart={{
                                options,
                                categories: categories.map(c => fDate(c)),
                                series: fatigue.data?.lines.flatMap((data: any) => ["roas"].map((metric) => ({
                                    name: (Object.values(data.data)[0] as any)?.creative.body.name.replace(/\d+-\d+-\d+-.+$/, ""),
                                    type: "line",
                                    data: categoriesOptions.map((c) => data?.data?.[c]?.[metric as keyof typeof data.data] || 0)
                                }))) || []
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <HookRateTable
                    data={fatigue.data?.table}
                />
            </Grid>
            <Dialog onClose={info.onFalse} open={info.value}>
                <DialogTitle>{t("How to read Ad Fatigue")}</DialogTitle>
                <DialogContent>
                    <Alert sx={{ fontSize: 12, mb: 2 }} severity="info">
                        <Typography fontSize={15} fontWeight={"bold"}>{t("Short-Term Rule (7 Days)")}:</Typography>
                        <ol>
                            <li>{t("CTR Rule: If CTR decreases by more than 20% over the past 7 days")}</li>
                            <li>{t("CPC Rule: If CPC increases by more than 25% over the past 7 days")}</li>
                            <li>{t("Frequency Rule: If Frequency exceeds 3")}</li>
                            <li>{t("ROAS Rule: If ROAS decreases by more than 25% over the past 7 days")}</li>
                        </ol>
                    </Alert>
                    <Alert sx={{ fontSize: 12, mb: 2 }} severity="info">
                        <Typography fontSize={15} fontWeight={"bold"}>{t("Medium-Term Rule (14 Days)")}:</Typography>
                        <ol>
                            <li>{t("CTR Rule: If CTR decreases by more than 15% over the past 7 days")}</li>
                            <li>{t("CPC Rule: If CPC increases by more than 20% over the past 7 days")}</li>
                            <li>{t("Frequency Rule: If Frequency exceeds 3")}.5</li>
                            <li>{t("ROAS Rule: If ROAS decreases by more than 25% over the past 7 days")}</li>
                        </ol>
                    </Alert>
                    <Alert sx={{ fontSize: 12, mb: 2 }} severity="info">
                        <Typography fontSize={15} fontWeight={"bold"}>{t("Long-Term Rule (30+ Days)")}:</Typography>
                        <ol>
                            <li>{t("CTR Rule: If CTR decreases by more than 10% over the past 7 days")}</li>
                            <li>{t("CPC Rule: If CPC increases by more than 15% over the past 7 days")}</li>
                            <li>{t("Frequency Rule: If Frequency exceeds 4")}</li>
                            <li>{t("ROAS Rule: If ROAS decreases by more than 15% over the past 7 days")}</li>
                        </ol>
                    </Alert>
                </DialogContent>
            </Dialog>
        </Container>
    )
}

interface Props {
    data?: any
}

function HookRateTable({
    data
}: Props) {
    const {t} = useLocales()
    const TABLE_HEAD = [
        {
            id: 'name',
            label: t("Name"),
            alignRight: false,
            sort: true,
            format: false,
        },
        {
            id: 'spend',
            label: t("Cost"),
            alignRight: true,
            sort: true,
            format: true,
        },
        {
            id: 'revenue',
            label: t("Purchase Conversion Values"),
            alignRight: true,
            sort: true,
            format: true,
        },
        {
            id: 'roas',
            label: t("ROAS"),
            alignRight: true,
            sort: true,
            format: true,
        },
        {
            id: 'cpa',
            label: t("CPA"),
            alignRight: true,
            sort: true,
            format: true,
        },
        {
            id: 'cpc',
            label: t("CPC"),
            alignRight: true,
            sort: true,
            format: true,
        },
        {
            id: 'ctr',
            label: t("CTR"),
            alignRight: true,
            sort: true,
            format: true,
        },
        {
            id: 'cpm',
            label: t("CPM"),
            alignRight: true,
            sort: true,
            format: true,
        },
        {
            id: 'aov',
            label: t("AOV"),
            alignRight: true,
            sort: true,
            format: true,
        },
        {
            id: 'click_to_atc',
            label: t("Click To ATC Ratio"),
            alignRight: true,
            sort: true,
            format: true,
        },
        {
            id: 'click_to_purchase',
            label: t("Click to Purchase Ratio"),
            alignRight: true,
            sort: true,
            format: true,
        },
        {
            id: 'atc_conversions',
            label: t("ATC Conversions Value"),
            alignRight: true,
            sort: true,
            format: true,
        },
        {
            id: 'checkout_values',
            label: t("Checkout Conversions Value"),
            alignRight: true,
            sort: true,
            format: true,
        },
        {
            id: 'video_p25_watched',
            label: t("Video play rate (25%)"),
            alignRight: true,
            sort: true,
            format: true,
        },
        {
            id: 'video_p50_watched',
            label: t("Video play rate (25%)"),
            alignRight: true,
            sort: true,
            format: true,
        },
        {
            id: 'video_p75_watched',
            label: t("Video play rate (25%)"),
            alignRight: true,
            sort: true,
            format: true,
        },
        {
            id: 'video_p100_watched',
            label: t("Video play rate (25%)"),
            alignRight: true,
            sort: true,
            format: true,
        },
    ]
    const table = useTable();
    const [filters, setFilters] = useState({});
    const formatter = useMarketingNumberFormat()

    const dataFiltered = applyFilter({
        inputData: data || [],
        comparator: getComparator("desc", "spend"),
        filters: filters as any,
    })

    const denseHeight = table.dense ? 52 : 72;

    const renderTable = (
        <TableContainer>
            <Scrollbar>
                <Table
                    size={table.dense ? 'small' : 'medium'}
                    sx={{ minWidth: 960 }}
                >
                    <TableHeadCustom
                        order={table.order}
                        orderBy={table.orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={Object.keys(data || {})?.length || 0}
                        onSort={table.onSort}
                    />

                    <TableBody>
                        {
                            dataFiltered.slice(0, 15).map((row) =>
                            (
                                <TableRow>
                                    <TableCell align="left">{row.creative.body.name.replace(/\d+-\d+-\d+-.+$/, "")}</TableCell>
                                    {TABLE_HEAD.slice(1, TABLE_HEAD.length).map(({ id, alignRight }) => (<TableCell align={alignRight ? "right" : "left"}>{formatter(id, row[id]).compressed}</TableCell>))}
                                </TableRow>
                            ))
                        }
                        <TableEmptyRows
                            height={denseHeight}
                            emptyRows={emptyRows(table.page, table.rowsPerPage, Object.keys(data || {}).length || 0)}
                        />

                        <TableNoData notFound={!dataFiltered.length} />
                    </TableBody>
                </Table>
            </Scrollbar>
        </TableContainer>
    )

    return (
        <>

            <Stack
                spacing={2}
                alignItems={{ xs: 'flex-end', md: 'center' }}
                direction={{
                    xs: 'column',
                    md: 'row',
                }}
                sx={{
                    p: 2.5,
                    pr: { xs: 2.5, md: 1 },
                }}
            >
            </Stack >
            {
                dataFiltered?.length === 0 ? (
                    <Skeleton variant="rectangular" width="100%">
                        {renderTable}
                    </Skeleton>
                ) : (
                    renderTable
                )
            }
        </>
    )
}


function applyFilter({
    inputData,
    comparator,
    filters,
}: {
    inputData: any[];
    comparator: (a: any, b: any) => number;
    filters: { campaigns: string[] };
}) {
    const { campaigns } = filters;

    const stabilizedThis = inputData.map((el, index) => [el, index] as const);

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    inputData = stabilizedThis.map((el) => el[0]);

    // if (campaigns.length > 0) {
    //     inputData = inputData.filter((row) => campaigns.includes(row.parent.parent?.external_id as string));
    // }

    return inputData;
}