// OrderedList.js
import React, { useEffect, useState } from "react";
import { Reorder } from "framer-motion";

interface Props {
  initialItems: any[]
  style: React.CSSProperties
  render: (item: any, idx: number) => any
  layoutScroll?: boolean
}

export function OrderedList({ initialItems, style, render, layoutScroll=false}: Props) {
  const [items, setItems] = useState(initialItems);
  
  useEffect(() => {
    setItems(initialItems)
  }, [initialItems])

  return (
    <Reorder.Group 
    axis="y" 
    values={items} onReorder={setItems}
    layoutScroll={layoutScroll}
    style={style}
    >
      {items.map((item, idx) => (
        <Reorder.Item key={item.output} value={item}>
          {render(item, idx)}
        </Reorder.Item>
      ))}
    </Reorder.Group>
  )
}

