import { Explorer } from "src/context/api/explorer/models";

export default function normalizeQuery(
  query: Explorer.ConfigQuery | Explorer.ConfigComparisonQuery | Explorer.ConfigQuery[] | Explorer.ConfigComparisonQuery[]
): Explorer.ConfigComparisonQuery[] {
  if (Array.isArray(query)) {
    return query.map((q) => {
      if ("logical_operator" in q) {
        return {
          ...q,
          logical_operator: (q.query.length > 1 ? q.logical_operator || "and" : null) as Explorer.ConditionalOperator,
          query: q.query.map(transformValueToValues),
        };
      }
      return {
        logical_operator: query.length > 1 ? "and" : null,
        name: "",
        query: [transformValueToValues(q as Explorer.ConfigQuery)],
      };
    }) as Explorer.ConfigComparisonQuery[];
  }
  if ("logical_operator" in query) {
    return [
      {
        ...query,
        logical_operator: (query.query.length > 1 ? query.logical_operator || "and" : null) as Explorer.ConditionalOperator,
        query: query.query.map(transformValueToValues),
      } as Explorer.ConfigComparisonQuery,
    ];
  }
  return [
    {
      logical_operator: null,
      name: "",
      query: [transformValueToValues(query as Explorer.ConfigQuery)],
    },
  ];
}

const transformValueToValues = (obj: any) => {
  if (obj.values && Array.isArray(obj.values)) {
    // Normalize the `values` array
    return {
      ...obj,
      metric: obj.metric, // Ensure metric is retained
      op: obj.values.length === 0 ? obj.values[0].op : "",
      values: normalizeValues(obj.values),
      logical_operator: obj.values.length > 1 ? "or" : null, // Always include logical_operator
    };
  }

  if ("value" in obj && !obj.values) {
    const { value, ...rest } = obj; // Extract `value` and keep the rest
    return {
      ...rest,
      logical_operator: null, // Always include logical_operator, even if null
      metric: obj.metric, // Ensure metric is retained
      op: rest.op || value.op || "",
      values: [
        {
          exact_type: value.exact_type || "",
          logical_operator: value.logical_operator || null,
          op: value.op || "",
          payload: value.payload || "",
          type: value.type || "",
        },
      ],
    };
  }

  return obj; // Return the object unchanged if no transformation is needed
};

const normalizeValues = (values: any[]) => {
  return values.map((item) => { // Ensure a return value for each iteration

    if ("value" in item) {
      // Normalize objects with a nested `value`
      return {
        exact_type: item.value.exact_type || "",
        logical_operator: item.value.logical_operator || null,
        op: item.op || "",
        payload: item.value.payload || "",
        type: item.value.type || "",
      };
    }

    // Retain objects that already conform to the correct structure
    return {
      exact_type: item.exact_type || "",
      logical_operator: item.logical_operator || null,
      op: item.op || "",
      payload: item.payload || "",
      type: item.type || "",
    };
  });
};