import { QueryStatus } from "@reduxjs/toolkit/query";
import { useSnackbar } from "src/components/snackbar";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { auth, useCurrentUserQuery, useUpdateAccountMutation } from "src/context/api/auth";
import { UpdateAccountQueryArg } from "src/context/api/auth/query";

export default function userService() {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch()
    const {data: user, ...userRequest} = useCurrentUserQuery()
    const [updateAccount, updateRequest] = useUpdateAccountMutation()

    const updateUser = useCallback((profile: UpdateAccountQueryArg) => {
        updateAccount(profile).unwrap().then(() => dispatch(auth.util.patchQueryData("currentUser", undefined, [
            {
                op: "replace",
                path: ["profile"],
                value: profile.profile
            },
        ]) as any))
    }, [])

    useEffect(() => {
      try {
        if (!updateRequest.isError && updateRequest.status === QueryStatus.fulfilled) {
          enqueueSnackbar('Update success!');
        } else if (updateRequest.isError) {
          enqueueSnackbar<'error'>('Cannot update', { variant: 'error' });
        }
      } catch (error) {
        console.error(error);
      }
    }, [updateRequest]);

    return {
        user,
        updateUser,
    }
}