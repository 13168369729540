import { Box, Grid, TableCell, TableRow, Typography, useTheme } from '@mui/material';
import { Container } from '@mui/system';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import AnalyticsClusterizedReport from '../../../analytics-funnel';
import { useLocales } from 'src/locales';
import AnalyticsWebsiteVisits from '../../../analytics-website-visits';
import { Fragment, useEffect, useMemo, useState } from 'react';
import AttributionWindowTable from '../../../attribution-window-table';
import { fCurrency } from 'src/utils/format-number';
import GenericFilter from 'src/components/generic-select';
import { useAttributionOptions } from 'src/context/reducers/attribution-settings/selectors';
import { SplashScreen } from 'src/components/loading-screen';
import {
  useAttributedQuery,
  useAttributionQuery,
  useTablesQuery,
} from 'src/context/api/attribution/api';
import { fDate } from 'src/utils/format-time';
import { AttributionWindow } from 'src/context/api/shared/models';
import { presetValues } from 'src/hooks/use-date-preset';
import workspaceServices from 'src/services/workspaces';
import { useMarketingNumberFormat } from 'src/hooks/marketing-format';
import { ResourceDepth } from 'src/components/generic-select/resource-select';
import { resetAttributionSettings } from 'src/context/reducers/attribution-settings';
import { useDispatch } from 'react-redux';

export default function MetaAttributionAnalytics() {
  const { t } = useLocales();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetAttributionSettings());
  }, []);

  const labels: any = {
    landing_page_view: t('Landing Page View'),
    omni_search: t('Searches'),
    omni_view_content: t('Content View'),
    omni_add_to_cart: t('Add to Cart'),
    omni_initiated_checkout: t('Checkout initiated'),
    omni_purchase: t('Payment'),
    lead: 'Lead',
  };

  const theme = useTheme();
  const settings = useSettingsContext();
  const windows = useAttributionOptions('attributionWindow');
  const account = useAttributionOptions('account');
  const campaigns = useAttributionOptions('campaigns');
  const adsets = useAttributionOptions('adsets');
  const start = useAttributionOptions('start');
  const end = useAttributionOptions('end');
  const ads = useAttributionOptions('ads');
  const workspaceService = workspaceServices();
  const formatter = useMarketingNumberFormat();
  const accountId = account?.[0];
  const mode = useAttributionOptions('mode');

  const colors = {
    omni_search: ['rgb(81, 35, 183)', 'rgb(198, 132, 255)'],
    omni_view_content: ['rgb(0, 108, 156)', 'rgb(97, 243, 243)'],
    omni_add_to_cart: ['rgb(183, 110, 0)', 'rgb(255, 214, 102)'],
    omni_initiated_checkout: ['rgb(183, 29, 24)', 'rgb(255, 172, 130)'],
    omni_purchase: ['rgb(17, 141, 87)', 'rgb(119, 237, 139)'],
    lead: ['rgb(17, 141, 87)', 'rgb(119, 237, 139)'],
  };

  const genderLabel = {
    male: t('Male'),
    female: t('Female'),
  };
  const metrics =
    mode === 'lead'
      ? ['omni_view_content', 'omni_search', 'lead']
      : [
          'omni_view_content',
          'omni_search',
          'omni_add_to_cart',
          'omni_initiated_checkout',
          'omni_purchase',
        ];

  const resourceIds = useMemo(() => {
    if (ads && ads.length > 0) return ads;
    if (adsets && adsets.length > 0) return adsets;
    if (campaigns && campaigns.length > 0) return campaigns;
    if (account && account.length > 0) return account;
    return [];
  }, [account, campaigns, adsets, ads]);

  const resourceType =
    ads && ads.length > 0
      ? 'ad'
      : (adsets ?? []).length > 0
      ? 'adset'
      : (campaigns ?? []).length > 0
      ? 'campaign'
      : 'account';

  const skip = useMemo(
    () =>
      !start ||
      !end ||
      !resourceIds ||
      !windows ||
      !accountId ||
      !workspaceService.current.workspace?.id,
    [start, end, resourceIds, windows, accountId, workspaceService.current.workspace?.id]
  );

  const report = useAttributionQuery(
    {
      startDate: fDate(start, 'yyyy-MM-dd'),
      endDate: fDate(end, 'yyyy-MM-dd'),
      resourceIds: resourceIds,
      attributionWindow: windows as AttributionWindow,
      adAccountId: accountId as string,
      resourceType,
      workspace: workspaceService.current.workspace?.id as any,
      mode,
    },
    { skip }
  );
  const tables = useTablesQuery(
    {
      startDate: fDate(start, 'yyyy-MM-dd'),
      endDate: fDate(end, 'yyyy-MM-dd'),
      resourceIds: resourceIds,
      attributionWindow: windows as AttributionWindow,
      adAccountId: accountId as string,
      resourceType,
      workspace: workspaceService.current.workspace?.id as any,
      mode,
    },
    { skip }
  );
  const attributed = useAttributedQuery(
    {
      startDate: fDate(start, 'yyyy-MM-dd'),
      endDate: fDate(end, 'yyyy-MM-dd'),
      resourceIds: resourceIds,
      attributionWindow: windows as AttributionWindow,
      adAccountId: accountId as string,
      resourceType,
      workspace: workspaceService.current.workspace?.id as any,
      mode,
    },
    { skip }
  );

  const filters = <GenericFilter level={ResourceDepth.AD} />;

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t(`Attribution Data`)}
        links={[
          {
            name: t(
              'Explore comprehensive event reporting with current CTA & VTA settings in our Attribution Dashboard, providing a clear view across all data windows.'
            ),
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          {filters}
        </Grid>
        {tables.isFetching || report.isFetching || attributed.isFetching ? (
          <SplashScreen />
        ) : (tables.isLoading && !tables.isFetching) ||
          (report.isLoading && !report.isFetching) ||
          (attributed.isLoading && !attributed.isFetching) ? (
          <SplashScreen />
        ) : tables.isUninitialized || report.isUninitialized || attributed.isUninitialized ? (
          <SplashScreen />
        ) : (
          <Grid item container xs={12} spacing={3}>
            <Grid item xs={12}>
              <AnalyticsClusterizedReport
                title={t('Funnel')}
                subheader={t('With breakdown by Gender & Age')}
                cluster={{
                  total: [
                    {
                      id: 'view',
                      label: t('View Content'),
                      value: report.data?.funnel.omni_view_content,
                    },
                    {
                      id: 'searches',
                      label: t('Searches'),
                      value: report.data?.funnel.omni_search,
                    },
                    {
                      id: 'add_to_cart',
                      label: t('Add to cart'),
                      value: mode === 'sales' ? report.data?.funnel.omni_add_to_cart : 0,
                    },
                    {
                      id: 'initiated_checkout',
                      label: t('Checkout initiated'),
                      value: mode === 'sales' ? report.data?.funnel.omni_initiated_checkout : 0,
                    },
                    {
                      id: 'conversions',
                      label: t('Payment'),
                      value: mode === 'sales' ? report.data?.funnel.omni_purchase : 0,
                    },
                    {
                      id: 'lead',
                      label: 'Lead',
                      value: mode === 'lead' ? report.data?.funnel.lead : 0,
                    },
                  ].filter((item) => item.value && item.value !== 0),
                  gender: metrics
                    .filter(
                      (item) => !!report.data?.funnel[item as keyof typeof report.data.funnel]
                    )
                    .map((metric) => [
                      {
                        id: 'male',
                        label: t('Male'),
                        value: report.data?.waffle[metric as keyof typeof report.data.waffle]?.male,
                      },
                      {
                        id: 'female',
                        label: t('Female'),
                        value:
                          report.data?.waffle[metric as keyof typeof report.data.waffle]?.female,
                      },
                    ]),
                  age: metrics
                    .filter(
                      (item) => !!report.data?.funnel[item as keyof typeof report.data.funnel]
                    )
                    .map((metric) =>
                      [
                        ['18-24', '_18_24'],
                        ['25-34', '_25_34'],
                        ['35-44', '_35_44'],
                        ['45-54', '_45_54'],
                        ['55-64', '_55_64'],
                        ['65+', '_65_999'],
                      ].map(([label, key]) => ({
                        id: label,
                        label,
                        value:
                          report.data?.bar[metric as keyof typeof report.data.bar]?.[
                            label as keyof (typeof report.data.bar)[keyof typeof report.data.bar]
                          ],
                      }))
                    ),
                }}
              />
            </Grid>
            <Grid item container spacing={2} xs={12}>
              {([] as string[])
                .concat(metrics)
                .reverse()
                .filter((item) => !!report.data?.funnel[item as keyof typeof report.data.funnel])
                .map((metric) => {
                  return (
                    <Fragment key={'grid-' + metric}>
                      <Grid item xs={4}>
                        <AnalyticsWebsiteVisits
                          title={labels[metric]}
                          subheader={t('Click through window (1d, 7d, 28d)')}
                          chart={{
                            options: { plotOptions: { bar: { columnWidth: '15px' } } },
                            labels: [t('1 Day Click'), t('7 Days Click'), t('28 Days Click')],
                            series: [
                              {
                                type: 'bar',
                                name: labels[metric],
                                data: [
                                  attributed.data?.[metric as keyof typeof attributed.data]?.click[
                                    '1d_click'
                                  ] || 0,
                                  attributed.data?.[metric as keyof typeof attributed.data]?.click[
                                    '7d_click'
                                  ] || 0,
                                  attributed.data?.[metric as keyof typeof attributed.data]?.click[
                                    '28d_click'
                                  ] || 0,
                                ],
                                fill: colors[metric as keyof typeof colors][0],
                              },
                            ],
                            colors: [colors[metric as keyof typeof colors][0]],
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <AnalyticsWebsiteVisits
                          title={labels[metric]}
                          subheader={t('View through window (1d, 7d, 28d)')}
                          chart={{
                            options: { plotOptions: { bar: { columnWidth: '15px' } } },
                            labels: [t('1 Day View'), t('7 Days View'), t('28 Days View')],
                            series: [
                              {
                                type: 'bar',
                                name: labels[metric],
                                data: [
                                  attributed.data?.[metric as keyof typeof attributed.data]?.view[
                                    '1d_view'
                                  ] || 0,
                                  attributed.data?.[metric as keyof typeof attributed.data]?.view[
                                    '7d_view'
                                  ] || 0,
                                  attributed.data?.[metric as keyof typeof attributed.data]?.view[
                                    '28d_view'
                                  ] || 0,
                                ],
                                fill: colors[metric as keyof typeof colors][1],
                              },
                            ],
                            colors: [colors[metric as keyof typeof colors][1]],
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <AttributionWindowTable>
                          <TableRow>
                            <TableCell>1</TableCell>
                            <TableCell>{t('Total attributed events')}</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>
                              {tables.data?.[metric as keyof typeof tables.data]?.total || 0}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>2</TableCell>
                            <TableCell>CPA</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>
                              {
                                formatter(
                                  'CPA',
                                  tables.data?.[metric as keyof typeof tables.data]?.CPA || 0
                                ).compressed
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>3</TableCell>
                            <TableCell>{t('Click through events')}</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>
                              {tables.data?.[metric as keyof typeof tables.data]?.click || 'N/A'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>4</TableCell>
                            <TableCell>{t('View through events')}</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>
                              {tables.data?.[metric as keyof typeof tables.data]?.view || 'N/A'}
                            </TableCell>
                          </TableRow>
                        </AttributionWindowTable>
                      </Grid>
                    </Fragment>
                  );
                })}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
