import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { QueryAdAccount, QueryAttribution, QueryDateRange, QueryMode, QueryResourceIds, QueryTimeStep, QueryWorkspace } from '../shared/query';
import { Explorer } from '../explorer/models';


type QueryArg = QueryResourceIds & QueryAdAccount & QueryDateRange & QueryAttribution & QueryWorkspace & QueryTimeStep & QueryMode


const hookRate = createApi({
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: BENLY_API_URL + '/hook-rate',
  }),
  reducerPath: 'api.hook-rate',
  tagTypes: ['hook-rate'],
  endpoints: (builder) => ({
    hookRate: builder.query<{
      lines: Explorer.Analytics,
      cards: Explorer.TopAdsCreative,
      table: Explorer.Table,
    }, QueryArg & {section: "lines" | "cards" | "table"}>({
      query: ({ resourceIds, resourceType, adAccountId, attributionWindow, startDate, endDate, workspace, timeStep, section, mode }) => {
        const params = new URLSearchParams({
            start_date: startDate,
            end_date: endDate,
            resource_type: resourceType as string,
            account_id: adAccountId.toString(),
            workspace: workspace.toString(),
            time_step: timeStep,
            mode,
        })
        resourceIds.forEach(rid => params.append("resource_ids", rid))
        attributionWindow.forEach(attr => params.append("attribution_window", attr))
        return ({
        url: `/${section}?${params}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      })},
    }),
    table: builder.query<any, QueryArg>({
      query: ({ resourceIds, resourceType, adAccountId, attributionWindow, startDate, endDate, workspace, mode }) => {
        const params = new URLSearchParams({
            start_date: startDate,
            end_date: endDate,
            resource_type: resourceType as string,
            account_id: adAccountId.toString(),
            workspace: workspace.toString(),
            mode,
        })
        resourceIds.forEach(rid => params.append("resource_ids", rid))
        attributionWindow.forEach(attr => params.append("attribution_window", attr))
        return ({
        url: `/table?${params}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      })},
    }),
    fatigue: builder.query<any, QueryArg>({
      query: ({ resourceIds, resourceType, adAccountId, attributionWindow, startDate, endDate, workspace, timeStep, mode }) => {
        const params = new URLSearchParams({
            start_date: startDate,
            end_date: endDate,
            resource_type: resourceType as string,
            account_id: adAccountId.toString(),
            workspace: workspace.toString(),
            time_step: timeStep,
            mode,
        })
        resourceIds.forEach(rid => params.append("resource_ids", rid))
        attributionWindow.forEach(attr => params.append("attribution_window", attr))
        return ({
        url: `/fatigue?${params}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      })},
    })
  }),
});

export const { useHookRateQuery, useTableQuery, useFatigueQuery } = hookRate;
export default hookRate;