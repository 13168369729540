import { Button, ButtonBase, Card, CardActions, CardContent, CardMedia, Container, Grid, MenuItem, Select, Stack, Typography, alpha, useTheme } from "@mui/material";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import { useSettingsContext } from "src/components/settings";
import TopCreatives from "../top-creatives";
import { ResourceDepth } from "src/components/generic-select/resource-select";
import AttributionSelect from "src/components/generic-select/attribution-select";
import { useEffect, useMemo, useState } from "react";
import { AttributionWindowClick, AttributionWindowView } from "src/context/api/shared/models";
import { useCurrentWorkspace } from "src/context/reducers/app-settings";
import { useTopCreativesQuery } from "src/context/api/ad-creatives";
import { fDate } from "src/utils/format-time";
import DateRangeSelect, { useDateRangeSelect } from "src/components/generic-select/date-range-select";
import { getOptions } from "src/context/reducers/attribution-settings/selectors";
import { parse } from "date-fns";
import GenericFilter from "src/components/generic-select";
import { useDispatch } from "react-redux";
import { resetAttributionSettings } from "src/context/reducers/attribution-settings";
import ButtonSelect from "src/components/generic-select/button-select";


export function TopCreativeDashboard() {
  const settings = useSettingsContext();
  const workspace = useCurrentWorkspace();
  const [groupAds, setGroupAds] = useState("ad_name")

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetAttributionSettings())
  }, [])

  const {attributionWindow, start: startDate, end: endDate, resourceType, resourceIds, timeStep, adAccountId} = getOptions()



  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={`Top Creative`}
        links={[{}]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Stack direction={"row"} spacing={2}>
            <GenericFilter/>
            <ButtonSelect multiple={false} value={groupAds} onChange={(value) => setGroupAds(value as any)} label="Group Ads" options={[
              { label: "Ad name", id: "ad_name" },
              { label: "Creative", id: "creative" },
            ]} />
          </Stack>
        </Grid>
        <TopCreatives
          type="creative"
          workspace={workspace?.id as number}
          start={parse(startDate, "yyyy-MM-dd", new Date())}
          end={parse(endDate, "yyyy-MM-dd", new Date())}
          adAccountId={adAccountId}
          attributionWindow={attributionWindow}
          grouping={groupAds as any}
          adType={["VIDEO", "IMAGE", "CAROUSEL", "INSTANT_EXPERIENCE"]}
          limit={8}
          resourceType={resourceType as any}
          resourceIds={resourceIds as string[]}
        />
        {/* <Grid item xs={12}>
          <TableDetails data={topCreatives.data?.top_creatives || []} cols={[
            { "ID": "Creative", path: "id" },
            { "ID": "ROAS", path: "data[\"purchase_roas.omni_purchase\"]" , format: (value: number) => value?.toFixed(2) || undefined },
            { "ID": "Click to purchase", path: "data[\"actions.omni_purchase\"]", format: fCurrency },
            { "ID": "CPA", path: "data[\"cost_per_action_type.omni_purchase\"]", format: fCurrency },]} />
        </Grid> */}
      </Grid>
    </Container>
  )
}