import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { QueryAdAccount, QueryAttribution, QueryDateRange, QueryMode, QueryWorkspace } from '../shared/query';
import { AttributionAttributed, AttributionCluster, AttributionTables } from './models';

type QueryArg = QueryDateRange & QueryAttribution;
interface ByResourceIds {
  resourceIds: string[];
  resourceType?: string;
}

const attribution = createApi({
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: BENLY_API_URL + '/attribution',
  }),
  reducerPath: 'api.attribution',
  tagTypes: ['attribution'],
  endpoints: (builder) => ({
    attribution: builder.query<AttributionCluster, QueryArg & ByResourceIds & QueryAdAccount & QueryWorkspace & QueryMode>({
      query: (args) => {
        let url = `/cluster?start_date=${args.startDate}&end_date=${args.endDate}`;
        url += ("&attribution_window=" + args.attributionWindow.join("&attribution_window="))
        url += ("&resource_ids=" + args.resourceIds.join("&resource_ids="))
        url += "&ad_account_id=" + args.adAccountId;
        url += "&resource_type=" + (args.resourceType || "account")
        url += "&workspace=" + args.workspace
        url += "&mode=" + args.mode
        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    }),
    tables: builder.query<AttributionTables, QueryArg & ByResourceIds & QueryAdAccount & QueryWorkspace & QueryMode>({
      query: (args) => {
        let url = `/tables?start_date=${args.startDate}&end_date=${args.endDate}`;
        url += ("&attribution_window=" + args.attributionWindow.join("&attribution_window="))
        url += ("&resource_ids=" + args.resourceIds.join("&resource_ids="))
        url += "&ad_account_id=" + args.adAccountId;
        url += "&resource_type=" + (args.resourceType || "account")
        url += "&workspace=" + args.workspace
        url += "&mode=" + args.mode
        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    }),
    attributed: builder.query<AttributionAttributed, QueryArg & ByResourceIds & QueryAdAccount & QueryWorkspace & QueryMode>({
      query: (args) => {
        let url = `/attributed?start_date=${args.startDate}&end_date=${args.endDate}`;
        url += ("&attribution_window=" + args.attributionWindow.join("&attribution_window="))
        url += ("&resource_ids=" + args.resourceIds.join("&resource_ids="))
        url += "&ad_account_id=" + args.adAccountId;
        url += "&resource_type=" + (args.resourceType || "account")
        url += "&workspace=" + args.workspace
        url += "&mode=" + args.mode
        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      }
    }),
  }),

});

export const { useAttributionQuery, useTablesQuery, useAttributedQuery } = attribution;
export default attribution;
