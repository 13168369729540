import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import explorer from "src/context/api/explorer";
import { Explorer } from "src/context/api/explorer/models";
import { RootState } from "src/context/store";

const ExplorerSlice = createSlice({
    name: "explorer_draft",
    initialState: {
        draft: null,
        adType: ["image", "video", "carousel"],
        adGroup: "creative",
        last_update: null,
        tagIds: []
    } as { draft: Explorer.Config | null, adType: string[] | null, adGroup: string | null, last_update: string | null, tagIds: number[] },
    reducers: {
        setDraftAction: (state, action: PayloadAction<Explorer.Config>) => {
            state.draft = action.payload;
        },
        setAdType: (state, action: PayloadAction<string[]>) => {
            state.adType = action.payload;
        },
        setAdGroup: (state, action: PayloadAction<string>) => {
            state.adGroup = action.payload;
        },
        setLastUpdate: (state, action: PayloadAction<"charts"|"table"|"cards"|"query"|"filters"|"all">) => {
            state.last_update = action.payload;
        },
        setTagIds: (state, action: PayloadAction<number[]>) => {
            state.tagIds = action.payload;
        },
    },
});

export default ExplorerSlice.reducer;

export const selectDraft = (state: RootState) => state.explorer_draft.draft;
export const selectAdType = (state: RootState) => state.explorer_draft.adType;
export const selectAdGroup = (state: RootState) => state.explorer_draft.adGroup;
export const selectLastUpdate = (state: RootState) => state.explorer_draft.last_update;
export const selectTagIds = (state: RootState) => state.explorer_draft.tagIds;

export const { setDraftAction, setAdType, setAdGroup, setLastUpdate, setTagIds } = ExplorerSlice.actions;