import { Box, Button, Modal, Stack, useTheme } from '@mui/material';
import Iconify from 'src/components/iconify';
import { useConfigQuery, useMetricsQuery } from 'src/context/api/explorer';
import { useAttributionOptions } from 'src/context/reducers/attribution-settings';
import { useBoolean } from 'src/hooks/use-boolean';
import { useParams } from 'src/routes/hooks';
import workspaceServices from 'src/services/workspaces';
import Metrics from './metrics';
import useExplorerConfiguration from 'src/hooks/use-explorer';
import { useDispatch } from 'react-redux';
import { setLastUpdate } from 'src/context/reducers/explorer/slice';

export default function QuickMetrics() {
  const theme = useTheme();
  const config = useExplorerConfiguration();
  const dispatch = useDispatch();
  const modal = useBoolean(false);

  return (
    <>
      <Stack
        p={1}
        spacing={1}
        alignItems={'center'}
        direction={{ xs: 'column', md: 'row' }}
        sx={{ border: 'dashed 1px ' + theme.palette.grey[300], borderRadius: 2 }}
      >
        {config?.raw?.cards.metrics.length} selected metrics:
        {config?.raw?.cards.metrics.map((metric, idx) => {
          return (
            <Button
              key={idx}
              endIcon={
                config.editable ? (
                  <Iconify
                    onClick={() => {
                      dispatch(setLastUpdate('cards'));
                      config.update({
                        ...config.raw,
                        cards: {
                          ...config.raw.cards,
                          metrics: config.raw.cards.metrics.filter((m) => m.name !== metric.name),
                        },
                      });
                    }}
                    icon={'solar:close-circle-bold'}
                  />
                ) : undefined
              }
              size="small"
              sx={{ backgroundColor: '#7635dc' }}
              variant="contained"
            >
              {metric.name}
            </Button>
          );
        })}
        {config.editable && (
          <Button
            onClick={modal.onTrue}
            startIcon={<Iconify icon={'solar:add-circle-bold'} />}
            variant="outlined"
          >
            Add metrics
          </Button>
        )}
      </Stack>
      <Modal onClose={modal.onFalse} open={modal.value}>
        <Box sx={{ position: 'absolute', top: '10%', left: '25%', width: "50%" }} component={'div'}>
          <Metrics
            sx={{
              width: '100%',
              backgroundColor: theme.palette.background.neutral,
              borderRadius: 2,
            }}
            onClose={modal.onFalse}
            max={5}
            type="cards"
            title="List of Metrics for your Cards"
          />
        </Box>
      </Modal>
    </>
  );
}
