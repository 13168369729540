import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { QueryAdAccount, QueryAttribution, QueryDateRange, QueryResourceIds, QueryWorkspace } from '../shared/query';
import { snakeCase } from 'src/utils/change-case';
import { formatQS } from 'src/utils/format-qs';


type QueryArg = QueryDateRange & QueryAdAccount & QueryAttribution & QueryWorkspace & QueryAttribution & QueryResourceIds & {
    level: string;
    creativeType: string;
}


const table = createApi({
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: BENLY_API_URL + '/table',
  }),
  reducerPath: 'api.table',
  tagTypes: ['table'],
  endpoints: (builder) => ({
    table: builder.query<any, QueryArg>({
      query: (query) => ({
        url: `/${query.level}?${formatQS({...query, level: undefined})}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
  }),
});

export const {
  useTableQuery,
} = table;
export default table;
