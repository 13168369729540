import { Button, ButtonBase, Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import Iconify from "../iconify";
import CustomPopover from "../custom-popover/custom-popover";
import { AttributionWindowClick, AttributionWindowView } from "src/context/api/shared/models";
import { useState } from "react";
import { usePopover } from "../custom-popover";

interface Props {
    variant?: any;
    attributionWindow: [AttributionWindowClick, AttributionWindowView];
    onWindowChange: (window: [AttributionWindowClick, AttributionWindowView]) => void;
}

export default function AttributionSelect({ attributionWindow, onWindowChange, ...props }: Props) {
    const [clickWindow, setClickWindow] = useState<string | AttributionWindowClick | null>(
        attributionWindow[0]
      );
      const [viewWindow, setViewWindow] = useState<string | AttributionWindowView | null>(
        attributionWindow[1]
      );
    const selectAttriutionWindowDisplay = usePopover();

    const selectClickWindow = (window: string) => () => setClickWindow(window);
    const selectViewWindow = (window: string) => () =>
      window === viewWindow ? setViewWindow(null) : setViewWindow(window);

    const apply = () => {
        onWindowChange([clickWindow as AttributionWindowClick, viewWindow as AttributionWindowView]);
        selectAttriutionWindowDisplay.onClose();
    }

    return (
        <>
            <ButtonBase
                onClick={selectAttriutionWindowDisplay.onOpen}
                sx={{
                    pl: 1,
                    py: 0.5,
                    pr: 0.5,
                    borderRadius: 1,
                    typography: 'subtitle2',
                    bgcolor: props.variant !== "light" ? 'background.neutral' : "white",
                }}
            >
                {attributionWindow.join('-') || 'Select attribution window'}
                <Iconify
                    width={16}
                    icon={
                        selectAttriutionWindowDisplay.open
                            ? 'eva:arrow-ios-upward-fill'
                            : 'eva:arrow-ios-downward-fill'
                    }
                    sx={{ ml: 0.5 }}
                />
            </ButtonBase>
            <CustomPopover
                open={selectAttriutionWindowDisplay.open}
                onClose={selectAttriutionWindowDisplay.onClose}
            >
                <Stack direction={'column'}>
                    <Stack margin={2} display={'flex'} direction={'row'}>
                        <Stack
                            sx={{ borderRight: 'solid 1px gray', px: 3, pt: 0 }}
                            display={'flex'}
                            direction={'column'}
                        >
                            <Typography mb={2} textAlign={'left'} fontWeight={'bold'} fontSize={14}>
                                Click Through
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={selectClickWindow('1d_click')}
                                        checked={clickWindow === '1d_click'}
                                    />
                                }
                                label="1 Day click"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={selectClickWindow('7d_click')}
                                        checked={clickWindow === '7d_click'}
                                    />
                                }
                                label="7 Day Click"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={selectClickWindow('28d_click')}
                                        checked={clickWindow === '28d_click'}
                                    />
                                }
                                label="28 Day Click"
                            />
                        </Stack>
                        <Stack ml={5} px={3} pt={0} marginLeft={0} display={'flex'} direction={'column'}>
                            <Typography mb={2} textAlign={'left'} fontWeight={'bold'} fontSize={14}>
                                View Through
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={selectViewWindow('1d_view')}
                                        checked={viewWindow === '1d_view'}
                                    />
                                }
                                label="1 Day View"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={selectViewWindow('7d_view')}
                                        checked={viewWindow === '7d_view'}
                                    />
                                }
                                label="7 Day View"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={selectViewWindow('28d_view')}
                                        checked={viewWindow === '28d_view'}
                                    />
                                }
                                label="28 Day View"
                            />
                        </Stack>
                    </Stack>
                    <Stack padding={3}>
                        <Typography variant={'subtitle2'}>7-day click and 1-day view (default view)</Typography>
                        <Typography variant="subtitle2">
                            You can select only one click and one wiew through at a time
                        </Typography>
                    </Stack>
                    <Stack alignItems={'flex-end'} direction={'row'} pt={0} pb={2} px={3}>
                        <Button onClick={selectAttriutionWindowDisplay.onClose}>Cancel</Button>
                        <Button
                            sx={{ ml: 2 }}
                            color="primary"
                            variant="contained"
                            onClick={apply}
                        >
                            Ok
                        </Button>
                    </Stack>
                </Stack>
            </CustomPopover>
        </>
    )
}