// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import { useTheme, alpha } from '@mui/material/styles';
// theme
import { bgGradient } from 'src/theme/css';
import { CurrentUserResponse } from 'src/context/api/auth';
import { mediaUrl } from 'src/utils/media-url';
import { stringAvatar } from 'src/utils/avatar';

// ----------------------------------------------------------------------

export default function ProfileCover({ email, profile = {} as CurrentUserResponse["profile"] }: CurrentUserResponse) {
  const theme = useTheme();

  const picture = profile?.picture 

  const userProfilePicture = picture?.type === "s3Object" ?  BENLY_API_URL + '/medias/' + picture.id : picture?.uri;

  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.primary.darker, 0.8),
          imgUrl: "assets/images/cover/#Img_Cover_L.4.png",
        }),
        height: 1,
        color: 'common.white',
      }}
    >
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          left: { md: 24 },
          bottom: { md: 24 },
          zIndex: { md: 10 },
          pt: { xs: 6, md: 0 },
          position: { md: 'absolute' },
        }}
      >
        {
          userProfilePicture
            ?
            <Avatar
              src={userProfilePicture}
              alt={profile?.firstname ? `${profile.firstname} ${profile.lastname}` : email}
              sx={{
                mx: 'auto',
                width: { xs: 64, md: 128 },
                height: { xs: 64, md: 128 },
                border: `solid 2px ${theme.palette.common.white}`,
              }}
            />
            :
            <Avatar
              sizes="large" {...stringAvatar(profile?.firstname ? `${profile.firstname} ${profile.lastname}` : email, {
                mx: 'auto',
                width: { xs: 64, md: 128 },
                height: { xs: 64, md: 128 },
                border: `solid 2px ${theme.palette.common.white}`,
              })}/>
        }

        <ListItemText
          sx={{
            mt: 3,
            ml: { md: 3 },
            textAlign: { xs: 'center', md: 'unset' },
          }}
          primary={profile?.firstname ? `${profile.firstname} ${profile.lastname}` : email}
          secondary={email}
          primaryTypographyProps={{
            typography: 'h4',
          }}
          secondaryTypographyProps={{
            mt: 0.5,
            color: 'inherit',
            component: 'span',
            typography: 'body2',
            sx: { opacity: 0.48 },
          }}
        />
      </Stack>
    </Box>
  );
}
