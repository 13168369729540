import { SyntheticEvent } from 'react';
import {
  Stack,
  TextField,
  Autocomplete,
  Typography,
  Box,
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
  AutocompleteChangeReason,
  useTheme,
} from '@mui/material';
import Iconify from 'src/components/iconify/iconify';
import { Theme } from '@mui/system';
import { m } from 'framer-motion';
import { useMetricsQuery } from 'src/context/api/explorer';
import { humanReadableOp } from 'src/utils/human-readable-op';
import { Explorer } from 'src/context/api/explorer/models';
import { useMediaQuery } from '@mui/material';
import useExplorerConfiguration from 'src/hooks/use-explorer';

export default function ConditionBuilderItem({
  query,
  index,
  onQueryChange,
  onDelete,
  onDeleteValue,
  handleAddFilter,
  handleAddValue,
}: {
  query: Explorer.ConfigQuery | Explorer.ConfigComparisonQuery;
  index: number;
  onQueryChange: (
    index: number,
    query: Explorer.ConfigQuery | Explorer.ConfigComparisonQuery
  ) => void;
  onDelete: (groupIndex: number, metricIndex: number) => void;
  handleAddFilter: (index: number) => void;
  onDeleteValue: (groupIndex: number, metricIndex: number, valueIndex: number) => void;
  handleAddValue: (groupIndex: number, metricIndex: number) => void;
}) {
  // const conditions = 'conditions' in query ? query.conditions : [];
  const isComparisonQuery = 'logical_operator' in query;
  const name = 'name' in query ? query.name : '';
  const logicalOperator = 'logical_operator' in query ? query.logical_operator : null;
  const { data: metrics = [] } = useMetricsQuery({ type: 'metrics' });
  const metricOptions = metrics.filter((metric) => metric.allow_filtering);
  const { raw } = useExplorerConfiguration();

  const handleOpChange = (
    groupIndex: number,
    nestedIndex: number,
    valueIndex: number,
    event: SelectChangeEvent<string>
  ) => {
    const group = query; // Access the correct group

    if ('query' in group && Array.isArray(group.query)) {
      const nestedQuery = group.query[nestedIndex]; // Access the correct nested query

      if ('values' in nestedQuery && Array.isArray(nestedQuery.values)) {
        const updatedValues = [...nestedQuery.values];
        const currentValue = updatedValues[valueIndex];
        const newOp = event.target.value;

        // Determine if the payload needs to be transformed
        const updatedPayload =
          newOp === "between"
            ? Array.isArray(currentValue.payload)
              ? currentValue.payload // Keep it as an array if it's already an array
              : [currentValue.payload, ""] // Transform into an array if it's not
            : Array.isArray(currentValue.payload)
              ? currentValue.payload[0] // If it's an array, revert to the first element as a string
              : currentValue.payload; // Keep as is if it's already a string

        // Update the `op` and `payload` accordingly
        updatedValues[valueIndex] = {
          ...currentValue,
          op: newOp,
          payload: updatedPayload,
        };

        // Update the nested query directly
        const updatedNestedQuery = {
          ...nestedQuery,
          values: updatedValues,
        };

        // Update the group with the modified nested query
        const updatedGroup = {
          ...group,
          query: group.query.map((q, idx) => (idx === nestedIndex ? updatedNestedQuery : q)),
        };

        // Update the top-level query
        onQueryChange(groupIndex, updatedGroup as any);
      } else {
        console.error(
          `Cannot handleOpChange: 'values' property does not exist on nested query at index ${nestedIndex}.`,
          nestedQuery
        );
      }
    } else {
      console.error(
        `Cannot handleOpChange: 'query' property does not exist on group at index ${groupIndex}.`,
        group
      );
    }
  };

  const handleValueChange = (
    groupIndex: number,
    nestedIndex: number,
    valueIndex: number,
    newValue: string | number,
    betweenOp: boolean | string,
  ) => {
    const group = query; // Access the correct group

    if ('query' in group && Array.isArray(group.query)) {
      const nestedQuery = group.query[nestedIndex]; // Access the correct nested query

      if ('values' in nestedQuery && Array.isArray(nestedQuery.values)) {
        const updatedValues = [...nestedQuery.values];
        const currentValue = updatedValues[valueIndex];

        let updatedPayload;
        if (typeof betweenOp === 'string') {
          // If betweenOp is a string, update the second value in the array
          updatedPayload = Array.isArray(currentValue.payload)
            ? currentValue.payload.map((val: any, i: number) =>
              i === 1 ? newValue.toString() : val // Fill the second value (index 1)
            )
            : ["", newValue.toString()]; // Initialize as an array with two strings
        } else if (typeof betweenOp === 'boolean') {
          // If betweenOp is a boolean, update the first value in the array
          updatedPayload = Array.isArray(currentValue.payload)
            ? currentValue.payload.map((val: any, i: number) =>
              i === 0 ? newValue.toString() : val // Fill the first value (index 0)
            )
            : [newValue.toString(), ""]; // Initialize as an array with two strings
        } else {
          // Handle unexpected case if needed
          console.error('Unexpected betweenOp value:', betweenOp);
        }

        updatedValues[valueIndex] = {
          ...currentValue,
          payload: updatedPayload,
        };

        // Update the nested query directly
        const updatedNestedQuery = {
          ...nestedQuery,
          values: updatedValues,
        };

        // Update the group with the modified nested query
        const updatedGroup = {
          ...group,
          query: group.query.map((q, idx) => (idx === nestedIndex ? updatedNestedQuery : q)),
        };

        // Update the top-level query
        onQueryChange(groupIndex, updatedGroup as any);
      } else {
        console.error(
          `Cannot handleValueChange: 'values' property does not exist on nested query at index ${nestedIndex}.`,
          nestedQuery
        );
      }
    } else {
      console.error(
        `Cannot handleValueChange: 'query' property does not exist on group at index ${groupIndex}.`,
        group
      );
    }
  };

  const handleMetricChange = (
    _event: SyntheticEvent<Element, Event>,
    newValue: string | null,
    _reason: AutocompleteChangeReason,
    nestedIndex: number
  ) => {
    const group = query; // Access the current group

    if (!newValue) {
      console.error("newValue is null or undefined, cannot update metric.");
      return;
    }

    // Find the full metric object that matches the newValue
    const selectedMetric = metricOptions.find((metric) => metric.name === newValue);

    if (!selectedMetric) {
      console.error(`Metric with name "${newValue}" not found in metricOptions.`);
      return;
    }

    if ('query' in group && Array.isArray(group.query)) {
      const updatedQuery = [...group.query]; // Clone the query array
      const nestedQuery = { ...updatedQuery[nestedIndex] }; // Clone the specific nested query

      // Update the nested query's metric with the selected metric object
      nestedQuery.metric = selectedMetric;

      updatedQuery[nestedIndex] = nestedQuery; // Replace the updated nested query

      const updatedGroup = {
        ...group,
        query: updatedQuery, // Replace the old query array with the updated one
      };

      onQueryChange(index, updatedGroup); // Trigger state update with the modified group
    } else {
      console.error(`'query' property does not exist or is not an array in the group`, group);
    }
  };

  const renderNestedQueries = (
    nestedQueries: Explorer.ConfigQuery[] | Explorer.ConfigComparisonQuery[]
  ) => {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
    return (
      <Stack
        direction="column"
        sx={{
          ...styles.nestedQueriesContainer,
          borderTop: (theme: Theme) => (index > 0 ? `1px solid ${theme.palette.divider}` : null),
        }}
      >
        {nestedQueries.map((nestedQuery, nestedIndex) => {
          return (
            <m.div
              key={`${index}-${nestedIndex}`}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              style={{ width: '100%' }}
            >
              <Stack sx={styles.groupRow} direction="row">
                {(nestedIndex === 0 && raw?.mode === "comparison") && (
                  <TextField
                    InputProps={{
                      sx: {
                        ...styles.field,
                        background: (theme: Theme) => `${theme.palette.grey[200]}`,
                        width: '150px',
                      },
                    }}
                    value={name}
                    placeholder="Group name"
                    onChange={(e) => onQueryChange(index, { ...query, name: e.target.value })}
                  />
                )}
                {(nestedIndex === 0 && raw?.mode === "analysis") &&
                  <Box width="50px" />
                }
                {nestedIndex > 0 ? (
                  logicalOperator ? (
                    <Typography sx={{
                      ...styles.operator,
                      width: raw?.mode === "analysis" ? "50px" : "150px",
                    }}
                      variant="body2"
                    >
                      {logicalOperator.toUpperCase()}
                    </Typography>
                  ) : (
                    <Box width="150px" />
                  )
                ) : null}
                {'metric' in nestedQuery && (
                  <>
                    <Autocomplete
                      sx={styles.autocompleteMetrics}
                      options={[...metricOptions.map((metric) => metric.name), " "]} // Add empty string as a valid option
                      value={nestedQuery.metric?.name || " "} // Ensure value is either a valid metric name or empty
                      onChange={(_event, newValue, reason) => {
                        handleMetricChange(_event, newValue, reason, nestedIndex);
                      }}
                      isOptionEqualToValue={(option, value) => option === value} // Ensure empty string is handled properly
                      renderInput={(params) => (
                        <TextField {...params} label="Metric" sx={{ ...styles.field }} />
                      )}
                    />
                    <Stack direction="column" spacing={2} alignItems="flex-start">
                      {nestedQuery.values.map((val, valueIndex) => (
                        <Stack
                          key={`value-stack-${index}-${nestedIndex}-${valueIndex}`}
                          direction="row"
                          spacing={2}
                          alignItems="flex-start"
                          sx={{ position: 'relative' }}
                        >
                          {valueIndex > 0 && (
                            <Typography sx={styles.operatorOr} variant="body2">
                              OR
                            </Typography>
                          )}
                          {!(typeof nestedQuery.metric === "string" && nestedQuery.metric === "Select") ?
                            <Select
                              sx={{
                                ...styles.field,
                                width: '120px',
                              }}
                              value={val.op || nestedQuery.op || ''} // Use the `op` from each value
                              onChange={(event) => {
                                handleOpChange(index, nestedIndex, valueIndex, event)
                              }}
                            >
                              {nestedQuery.metric?.op.map((operator) => (
                                <MenuItem key={operator} value={operator ?? ''}>
                                  {humanReadableOp(operator)}
                                </MenuItem>
                              ))}
                            </Select>
                            :
                            <TextField disabled sx={{
                              ...styles.field,
                              width: '120px',
                            }} />
                          }
                          <Stack
                            sx={styles.payloadFields}
                            direction="column"
                          >
                            <TextField
                              InputProps={{
                                sx: {
                                  ...styles.field,
                                  width: '100px',
                                },
                              }}
                              disabled={typeof nestedQuery.metric === "string"}
                              value={
                                Array.isArray(val.payload) ?
                                  val.payload[0]
                                  : val.payload || ""
                              } // Handle empty or undefined payloads gracefully
                              placeholder=""
                              onChange={(e) =>
                                handleValueChange(index, nestedIndex, valueIndex, e.target.value, val.op === "between")
                              }
                            />
                            {val.op === "between" &&
                              <TextField
                                InputProps={{
                                  sx: {
                                    ...styles.field,
                                    width: '100px',
                                  },
                                }}
                                value={
                                  Array.isArray(val.payload) ?
                                    val.payload[1]
                                    : val.payload || ""
                                } // Handle empty or undefined payloads gracefully
                                placeholder=""
                                onChange={(e) =>
                                  handleValueChange(index, nestedIndex, valueIndex, e.target.value, "second")
                                }
                              />
                            }
                          </Stack>
                          <Button
                            variant="outlined"
                            disabled={typeof nestedQuery.metric === "string"}
                            onClick={() => handleAddValue(index, nestedIndex)}
                          >
                            OR
                          </Button>

                          <Box
                            sx={styles.trashBinOr}
                            onClick={() => onDeleteValue(index, nestedIndex, valueIndex)}
                          >
                            <Iconify
                              width="18px"
                              icon="solar:trash-bin-trash-bold"
                              color={(theme) => theme.palette.grey[500]}
                            />
                          </Box>
                          {valueIndex === nestedQuery.values.length - 1 ?
                            nestedIndex === nestedQueries.length - 1 && (
                              <Button
                                sx={{
                                  ...styles.buttons,
                                  ...(!isLargeScreen ? styles.smallButton : ''),
                                  background: (theme: Theme) => `${theme.palette.grey[600]}`,
                                }}
                                variant="contained"
                                disabled={typeof nestedQuery.metric === "string"}
                                onClick={() => handleAddFilter(index)}
                                startIcon={
                                  <Iconify
                                    icon="mingcute:add-line"
                                    color={(theme) => theme.palette.common.white}
                                  />
                                }
                              >
                                {isLargeScreen && 'Add filter'}
                              </Button>
                            ) : null}
                        </Stack>
                      ))}
                    </Stack>
                  </>
                )}
                {/* <Box sx={styles.trashBin}
                  onClick={() => onDelete(index, nestedIndex)}
                >
                  <Iconify
                    width="18px"
                    icon="solar:trash-bin-trash-bold"
                    color={(theme) => theme.palette.grey[500]}
                  />
                </Box> */}
              </Stack>
            </m.div>
          );
        })}
      </Stack>
    );
  };
  return (
    <Stack direction="column">
      {isComparisonQuery &&
        Array.isArray(query.query) &&
        renderNestedQueries(query.query as Explorer.ConfigComparisonQuery[])}
    </Stack>
  );
}

const styles = {
  nestedQueriesContainer: {
    width: '100%',
    alignItems: 'flex-start',
    padding: '18px',
    gap: '12px',
  },
  groupRow: {
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: "16px",
  },
  operator: {
    width: '150px',
    textAlign: 'right',
    marginTop: "6px",
  },
  operatorOr: {
    position: 'absolute',
    width: '20px',
    top: '6px',
    left: '-34px',
  },
  field: {
    height: '34px',
    padding: '0',
    boxSizing: 'border-box',
    '& .MuiInputBase-input': {
      padding: '0px 8px',
      height: '34px',
    },
  },
  autocompleteMetrics: {
    width: '150px',
    height: '34px',
    '& .MuiAutocomplete-inputRoot': {
      padding: '0px 8px',
      height: '34px',
    },
    '& .MuiAutocomplete-input ': {
      boxSizing: 'border-box',
      padding: '0px 8px',
      height: '34px',
    },
  },
  payloadFields: {
    gap: '12px',
  },
  trashBin: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    width: '64px',
    cursor: 'pointer',
    marginTop: "10px",
  },
  trashBinOr: {
    display: 'flex',
    height: '34px',
    alignItems: 'center',
    justifyContent: 'center',
    width: '64px',
    cursor: 'pointer',
  },
  buttons: {
    width: '130px',
    height: '34px',
  },
  smallButton: {
    minWidth: 0,
    width: '40px',
    '& .MuiButton-startIcon': {
      margin: 0,
    },
  },
};
