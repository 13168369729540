import { presetValues } from "src/hooks/use-date-preset";
import { fDate } from "src/utils/format-time";

export interface PresetValues {
  last_7d: Value;
  last_14d: Value;
  last_28d: Value;
  last_30d: Value;
  last_90d: Value;
  today: Value;
  yesterday: Value;
  this_week_sun_td: Value;
  this_week_mon_td: Value;
  last_week_sun_sat: Value;
  last_week_mon_sun: Value;
  this_month: Value;
  last_month: Value;
  last_quarter: Value;
  this_year: Value;
  [Prop: string]: Value;
}

export type TimeStep = 'day'|'month'|'year'|'week'; 

interface Value {
  start: Date;
  end: Date;
  displayName: string;
  step: TimeStep;
}

interface InitialState {
  datePreset: keyof PresetValues;
  adsets?: string[];
  campaigns?: string[];
  account?: string[];
  ads?: string[];
  attributionWindow: [string | null, string | null];
  resourceTypeSelected?: string;
  timeStep: TimeStep;
  start?: number | string;
  end?: number | string;
  loading?: boolean;
  dateSelection: 'custom' | 'preset';
  mode: 'lead' | 'sales';
}

export const initialState: InitialState = {
  datePreset: 'last_14d',
  start: fDate(presetValues.last_14d.start, "yyyy-MM-dd"),
  end: fDate(presetValues.last_14d.end, "yyyy-MM-dd"),
  attributionWindow: ['7d_click', '1d_view'],
  timeStep: "day",
  dateSelection: 'preset',
  mode: 'sales',
};
