import { Button, ButtonBase, Card, CardActions, CardContent, CardMedia, Container, Grid, Stack, Typography, alpha, useTheme } from "@mui/material";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import { useSettingsContext } from "src/components/settings";
import { useLocales } from "src/locales";
import TopCreativePerformance from "../home-creative-performance";
import { topCreativeDetails, topCreatives, topLandingDetails } from "./_mocks";
import HomeSpendByTopCreative from "../home-spend-by-top-creative";
import TableDetails from "../table-detail";
import { fCurrency } from "src/utils/format-number";
import { useBoolean } from "src/hooks/use-boolean";
import ModalDetail from "../modal-detail";
import VideoDetail from "../detail-video";
import TopCreatives from "../top-creatives";
import DateRangeSelect, { useDateRangeSelect } from "src/components/generic-select/date-range-select";
import { ResourceDepth } from "src/components/generic-select/resource-select";
import AttributionSelect from "src/components/generic-select/attribution-select";
import { useMemo, useState } from "react";
import { AttributionWindowClick, AttributionWindowView } from "src/context/api/shared/models";
import { useCurrentWorkspace } from "src/context/reducers/app-settings";
import { useTopCreativesQuery } from "src/context/api/ad-creatives";
import { fDate } from "src/utils/format-time";
import { getOptions } from "src/context/reducers/attribution-settings";
import GenericFilter from "src/components/generic-select";


export default function TopLandingDashboard() {
  const settings = useSettingsContext();
  const workspace = useCurrentWorkspace();
  
  const {attributionWindow, start: startDate, end: endDate, resourceType, resourceIds, timeStep, adAccountId} = getOptions()
  
  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={`Top Landing`}
        links={[{}]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Stack direction={"row"} spacing={2}>
            <GenericFilter/>
          </Stack>
        </Grid>
        {/* <TopLanding
          workspace={workspace?.id as number}
          start={start}
          end={end}
          adAccountId={select.accounts.selected[0] as string}
          attributionWindow={attributionWindow}
          limit={8}
        /> */}
        {/* <Grid item xs={12}>
          <TableDetails data={topCreatives.data?.top_creatives || []} cols={[
            { "ID": "Creative", path: "id" },
            { "ID": "ROAS", path: "data[\"purchase_roas.omni_purchase\"]" , format: (value: number) => value.toFixed(2) },
            { "ID": "Click to purchase", path: "data[\"actions.omni_purchase\"]", format: fCurrency },
            { "ID": "CPA", path: "data[\"cost_per_action_type.omni_purchase\"]", format: fCurrency },]} />
        </Grid> */}
      </Grid>
    </Container>
  )
}