// utils
import { paramCase } from 'src/utils/change-case';
import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/account',
  DASHBOARD: '/dash',
  WORKSPACES: '/workspaces'
};

// ----------------------------------------------------------------------

export const paths = {
  profile: {
    root: ROOTS.AUTH,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    home: ROOTS.DASHBOARD + '/home',
    creative: ROOTS.DASHBOARD + '/creative',
    landing: ROOTS.DASHBOARD + '/landing',
    video: ROOTS.DASHBOARD + '/video',
    copy: ROOTS.DASHBOARD + '/copywriting',
    performance: ROOTS.DASHBOARD + '/performance/facebook',
    attribution: ROOTS.DASHBOARD + '/attribution',
    applications: {
      connections: {
        create: (type: string) => `/apps/${type}/connect`,
        app: (type: string) => `/apps/${type}`,
        root: `/apps`,
      },
    },
  },
  explorer: {
    root: '/explore',
    config: (name: string) => `/explore/${name}`,
  },
  workspaces: {
    root: ROOTS.WORKSPACES,
    workspace: (id: number) => `${ROOTS.WORKSPACES}/${id}`,
  }
};
