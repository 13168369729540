import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AdAccounts, Object } from './models';
import { QueryArg, QueryByType } from './query';


const resource = createApi({
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: BENLY_API_URL + '/marketing',
  }),
  reducerPath: 'api.marketing',
  tagTypes: ['marketing'],
  endpoints: (builder) => ({
    getResources: builder.query<{ resources: Object[] }, QueryArg>({
      query: ({ type, workspace,  }) => ({
        url: `/${type}/adaccounts?${new URLSearchParams({ workspace: workspace.toString() })}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    objects: builder.query<{ objects: Object[] }, QueryByType>({
      query: ({ type }) => ({
        url: `/objects/${type}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    getAdAccounts: builder.query<AdAccounts, null>({
      query: () => ({
        url: `/ad_accounts`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
  }),
});

export const { useObjectsQuery } = resource;
export default resource;