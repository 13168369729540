import { ButtonBase, Card, CardHeader, CardProps, MenuItem, Skeleton } from "@mui/material";
import { Stack } from "@mui/system";
import { ApexOptions } from "apexcharts";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Chart, { useChart } from "src/components/chart";
import CustomPopover, { usePopover } from "src/components/custom-popover";
import AdvancedPopover from "src/components/custom-popover/advanced-popover";
import Iconify from "src/components/iconify";
import { useChartsQuery } from "src/context/api/explorer";
import { Explorer } from "src/context/api/explorer/models";
import { AttributionWindow } from "src/context/api/shared/models";
import { useCurrentWorkspace } from "src/context/reducers/app-settings";
import { useAttributionOptions } from "src/context/reducers/attribution-settings";
import { setLastUpdate } from "src/context/reducers/explorer/slice";
import { useMarketingNumberFormat } from "src/hooks/marketing-format";
import useExplorerConfiguration, { Metric, useExplorer } from "src/hooks/use-explorer";
import { TimeStep } from "src/models/attribution";
import { useParams } from "src/routes/hooks";
interface Props extends CardProps {
  title?: string;
  subheader?: string;
  chart?: {
    categories?: string[];
    colors?: string[];
    series?: {
      type: string;
      options?: ApexOptions;
      data: {
        name: string;
        data: number[];
      }[];
    }[];
    options?: ApexOptions;
  };
}

export default function Analytics({ chart = {}, title, subheader, ...other }: Props) {
  const { colors, categories, options } = chart;

  const dispatch = useDispatch()

  const { draft, setDraft, ...config } = useExplorerConfiguration()


  const { analytics: { data: series, isFetching, isUninitialized, isError } } = useExplorer()
  const groupDataPopover = usePopover();

  const metrics = config?.metrics("charts")

  const formatter = useMarketingNumberFormat();

  const chartOptions = useChart({
    colors,
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    xaxis: {
      categories: [],
    },
    ...options,
    yaxis: {
      labels: {
        formatter: (value: number) => {
          return value ? formatter(metrics?.[0].output || "", value).compressed : "0";
        },
      },
    },
  });

  const updateConfig = useCallback((draft: Explorer.Config) => {
    setDraft(draft)
    if (draft) {
      config.update(draft)
    }
  }, [config])

  const handleMetricChange = useCallback(({ value }: { value: string }) => {
    dispatch(setLastUpdate("charts"))
    const metric = config.filters?.find((metric) => metric.output === value)
    if (metric) {
      updateConfig({
        ...draft as any,
        charts: {
          ...draft?.charts as any,
          metrics: [metric]
        }
      })
    }
  }, [config, updateConfig, draft])

  const handleTimeStepChange = useCallback((timeStep: TimeStep) => {
    dispatch(setLastUpdate("charts"))
    updateConfig({
      ...draft as any,
      charts: {
        ...draft?.charts as any,
        time_step: timeStep as string
      }
    })
  }, [config, updateConfig, draft])

  if ((!series?.length || series.length === 0) && !isFetching) {
    return null;
  }

  if (isError) {
    return null
  }

  return (
    <>
      <Card {...other}>
        <CardHeader
          title={title}
          subheader={subheader}
          action={
            config.editable && (
              <Stack direction={"row"} mb={1} spacing={1}>
                {
                  config.metrics("charts") &&
                  <AdvancedPopover
                    options={config.filters?.map((metric) => ({
                      label: metric.name,
                      value: metric.output
                    })) || []}
                    onSelect={handleMetricChange}
                    renderValue={(option) => option.label}
                    renderOption={(option) => option.label}
                    value={{
                      label: config.metrics("charts")?.[0].name as string,
                      value: config.metrics("charts")?.[0].output as string
                    }}
                  />
                }
                <ButtonBase
                  onClick={groupDataPopover.onOpen}
                  sx={{
                    ml: 1,
                    pl: 1,
                    py: 0.5,
                    pr: 0.5,
                    borderRadius: 1,
                    typography: 'subtitle2',
                    bgcolor: 'background.neutral',
                  }}
                >
                  {draft?.charts.time_step}

                  <Iconify
                    width={16}
                    icon={groupDataPopover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                    sx={{ ml: 0.5 }}
                  />
                </ButtonBase>
              </Stack>
            )
          }
        />
        {
          isUninitialized || isFetching ? (
            <Skeleton variant="rectangular" height={354} />
          ) : (
            <Chart dir="ltr" type="line" series={series?.map((serie) => {
              return ({
                name: (serie as any).type_id || serie.ad_name,
                type: draft?.mode === "comparison" ? "bar" : "line",
                data: serie.data[metrics?.[0].output || ""] || []
              })
            }) || []} options={Object.assign({}, chartOptions)} height={354} />
          )
        }
      </Card>

      <CustomPopover open={groupDataPopover.open} onClose={groupDataPopover.onClose} sx={{ width: 140 }}>
        {['day', 'week', 'month'].map((option, idx) => (
          <MenuItem
            key={idx}
            selected={draft?.charts.time_step === option}
            onClick={() => {
              handleTimeStepChange(option as TimeStep)
              groupDataPopover.onClose()
            }}
          >
            {option}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  )
}