import { Container, Grid, MenuItem, Select, Stack } from "@mui/material";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import { useSettingsContext } from "src/components/settings";
import { ResourceDepth } from "src/components/generic-select/resource-select";
import AttributionSelect from "src/components/generic-select/attribution-select";
import { useEffect, useState } from "react";
import { AttributionWindow, AttributionWindowClick, AttributionWindowView } from "src/context/api/shared/models";
import { useCurrentWorkspace } from "src/context/reducers/app-settings";
import { fDate } from "src/utils/format-time";
import DateRangeSelect, { useDateRangeSelect } from "src/components/generic-select/date-range-select";
import { VideoTable } from "../creative-table-video";
import { useTableQuery } from "src/context/api/table/api";
import { useMarketingNumberFormat } from "src/hooks/marketing-format";
import { useLocales } from "src/locales";
import TopCreatives from "../top-creatives";
import { getOptions } from "src/context/reducers/attribution-settings";
import { parse } from "date-fns";
import GenericFilter from "src/components/generic-select";
import { useDispatch } from "react-redux";
import { resetAttributionSettings } from "src/context/reducers/attribution-settings";
import ButtonSelect from "src/components/generic-select/button-select";


export function TopVideoDashboard() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetAttributionSettings())
  }, [])

  const { t } = useLocales()
  const settings = useSettingsContext();
  const workspace = useCurrentWorkspace();
  const [groupAds, setGroupAds] = useState("ad_name")

  const formatter = useMarketingNumberFormat()
  const {attributionWindow, start: startDate, end: endDate, resourceType, resourceIds, timeStep, adAccountId} = getOptions()

  function applyFilter({
    inputData,
    comparator,
    filters,
  }: {
    inputData: any[];
    comparator: (a: any, b: any) => number;
    filters: { name: string | string[] };
  }) {
    const { name } = filters;
    const stabilizedThis = inputData.map((el, index) => [el, index] as const);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    inputData = stabilizedThis.map((el) => el[0]);

    if (name.length > 0) {
      inputData = inputData.filter((row) => row.creative_name.includes(name));
    }

    return inputData;
  }

  const table = useTableQuery({
    workspace: workspace?.id as number,
    attributionWindow: attributionWindow as AttributionWindow,
    startDate,
    endDate,
    resourceIds: resourceIds,
    resourceType: resourceType,
    level: "creative",
    adAccountId: adAccountId,
    creativeType: "video"
  })


  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={`Top Videos`}
        links={[{}]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Stack direction={"row"} spacing={2}>
            <GenericFilter/>
            <ButtonSelect multiple={false} value={groupAds} onChange={(value) => setGroupAds(value as any)} label="Group Ads" options={[
              { label: "Ad name", id: "ad_name" },
              { label: "Creative", id: "creative" },
            ]} />
          </Stack>
        </Grid>
        <TopCreatives
          type="video"
          grouping={groupAds as any}
          adType={["VIDEO"]}
          workspace={workspace?.id as number}
          start={parse(startDate, "yyyy-MM-dd", new Date())}
          end={parse(endDate, "yyyy-MM-dd", new Date())}
          adAccountId={adAccountId}
          attributionWindow={attributionWindow}
          limit={8}
          resourceType={resourceType as any}
          resourceIds={resourceIds as string[]}
        />
      </Grid>
    </Container>
  )
}