import { SocialType } from "../social";

enum Type {
    AD = "ad",
    ADSET = 'adset',
    CAMPAIGN = 'campaign',
    AD_ACCOUNT = 'account',
}

export { Type as ObjectType }

export interface Object {
    id: number;
    name: string;
    external_id: string;
    children?: (Object[]) | null;
    parent?: Object;
    parent_id?: null | number;
    currency?: string
}

export interface AdAccounts {
    [prop: string]: {
        id: number;
        name: string;
    }[]
}