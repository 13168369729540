import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { useSnackbar } from "src/components/snackbar"
import { Workspace, useCreateWorkspaceMutation, useGetWorkspacesQuery, useUpdateWorkspaceMutation, workspaces } from "src/context/api/workspaces"
import { setCurrentWorkspace, useCurrentWorkspace } from "src/context/reducers/app-settings"
import { storeObjects } from "src/context/reducers/attribution-settings"


export default function workspaceServices() {
    const [, setSearchParams] = useSearchParams();
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const [create, createRequest] = useCreateWorkspaceMutation()
    const [update, updateRequest] = useUpdateWorkspaceMutation()
    const { data, ...getWorkspaces } = useGetWorkspacesQuery(null)
    const workspace = useCurrentWorkspace()

    useEffect(() => {
        if (createRequest.isSuccess) {
            dispatch(workspaces.util.updateQueryData("getWorkspaces", null, (workspaceList) => {
                workspaceList.push(createRequest.data as any)
            }) as any)
            enqueueSnackbar("Workspace " + createRequest.data.name + " created successfuly")
        }
        else if (createRequest.isError) {
            enqueueSnackbar("Error creating workspace", { variant: "error" })
        }
    }, [createRequest])

    useEffect(() => {
        if (updateRequest.isSuccess) {
            dispatch(workspaces.util.updateQueryData("getWorkspace", { id: updateRequest.data.id }, (workspace) => {
                Object.assign(workspace, updateRequest.data)
            }) as any)
            dispatch(workspaces.util.updateQueryData("getWorkspaces", null, (workspaces) => {
                workspaces[workspaces.findIndex((workspace) => workspace.id === updateRequest.data.id)] = updateRequest.data as any
            }) as any)
            enqueueSnackbar("Workspace " + updateRequest.data.name + " updated successfuly")
        }
        else if (updateRequest.isError) {
            enqueueSnackbar("Error updating workspace", { variant: "error" })
        }
    }, [updateRequest])


    const set = (workspace: Workspace) => {
        setSearchParams({ workspace: workspace.id.toString() });
        dispatch(storeObjects(["account", []]))
        dispatch(storeObjects(["campaigns", []]))
        dispatch(storeObjects(["adsets", []]))
        dispatch(storeObjects(["ads", []]))
        dispatch(setCurrentWorkspace(workspace))
    }

    return { create, update, list: data, current: { workspace, set}, reload: getWorkspaces.refetch, fetching: getWorkspaces.isFetching, pendingUpdate: updateRequest.isLoading || createRequest.isLoading }
}