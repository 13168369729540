export function humanReadableOp(op: string | null) {
  switch (op) {
    case 'eq':
      return 'Equals';
    case 'contains':
      return 'Contains';
    case 'gt':
      return 'Greater Than';
    case 'lt':
      return 'Less Than';
    case 'gte':
      return 'Greater Than or Equal';
    case 'lte':
      return 'Less Than or Equal';
    case 'between':
      return 'Between';
    default:
      return op;
  }
}
