import { Stack, Button } from '@mui/material';
import { m } from 'framer-motion';
import { Theme } from '@mui/system';
import Iconify from 'src/components/iconify/iconify';
import useExplorerConfiguration from 'src/hooks/use-explorer';
import ConditionBuilderItem from './condition-builder-item';
import { Explorer } from 'src/context/api/explorer/models';

interface ConditionBuilderProps {
  onQueryChange: (
    index: number,
    query: Explorer.ConfigQuery | Explorer.ConfigComparisonQuery
  ) => void;
  onAdd: () => void;
  onDelete: (groupIndex: number, metricIndex: number) => void;
  onDeleteValue: (groupIndex: number, metricIndex: number, valueIndex: number) => void;
  handleAddFilter: (index: number) => void;
  handleAddValue: (groupIndex: number, metricIndex: number) => void;
  query: Explorer.Config['query'];
}

export default function ConditionBuilder({
  query,
  onQueryChange,
  onAdd,
  onDelete,
  onDeleteValue,
  handleAddFilter,
  handleAddValue,
}: ConditionBuilderProps) {
  const explorer = useExplorerConfiguration();
  return (
    <Stack direction='column'>
      {query.map((q, index) => (
        <m.div
          key={index}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
        >
          <ConditionBuilderItem
            query={q}
            index={index}
            onQueryChange={onQueryChange}
            onDelete={onDelete}
            handleAddFilter={handleAddFilter}
            handleAddValue={handleAddValue}
            onDeleteValue={onDeleteValue}
          />
        </m.div>
      ))}
      <Stack sx={styles.lowerSection} direction='row'>
        <Button
          sx={styles.buttons}
          variant='contained'
          onClick={onAdd}
          startIcon={
            <Iconify
              icon="mingcute:add-line"
              color={(theme) => theme.palette.primary.main}
            />
          }
        >
          {explorer.raw?.mode === "comparison" ?
            "Add group"
            : "Add OR"
          }
        </Button>
        <Button
          sx={styles.buttons}
          variant='contained'
          color='primary'
          onClick={() => explorer.update(explorer.draft as any)}
        >
          Load data
        </Button>
      </Stack>
    </Stack>
  );
}

const styles = {
  lowerSection: {
    padding: 2,
    borderTop: (theme: Theme) => `1px solid ${theme.palette.divider}`,
    justifyContent: 'space-between',
  },
  buttons: {
    width: '134px',
    height: '34px',
  },
};