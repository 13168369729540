import { Workspace, useGetWorkspaceObjectsQuery } from "src/context/api/workspaces";
import { useCurrentWorkspace } from "src/context/reducers/app-settings";
import ButtonSelect from "./button-select";
import { useCallback, useMemo, useRef, useState } from "react";
import { Skeleton } from "@mui/material";
import { fDate } from "src/utils/format-time";
import { useLocales } from "src/locales";
import Select, { Option, useSelect } from "../select/advanced";
import { storeObjects, useAttributionOptions } from "src/context/reducers/attribution-settings";
import { useDispatch } from "react-redux";

export enum ResourceDepth {
    AD_ACCOUNT = "account",
    CAMPAIGN = "campaign",
    ADSET = "adset",
    AD = "ad"
}

const depthToKey = (depth: ResourceDepth) => {
    return depth === ResourceDepth.AD_ACCOUNT ? "account" : `${depth}s` as const
}

interface Props {
    variant?: any;
    depth: ResourceDepth;
    onApply?: (value: string[], type: ResourceDepth) => void
}


export function useObjects(
    start: Date,
    end: Date,
    attribution: [string, string],
) {
    const workspace = useCurrentWorkspace() as Workspace;
    const { data: objects_, isFetching, isUninitialized } = useGetWorkspaceObjectsQuery(
        {
            id: workspace?.id,
            start: fDate(start, "yyyy-MM-dd"),
            end: fDate(end, "yyyy-MM-dd"),
            attribution
        },
        { skip: !workspace, refetchOnMountOrArgChange: true }
    );
    return {
        objects: objects_,
        isFetching,
        isUninitialized,
        objectByType: useCallback((type: ResourceDepth) => {
            const objects = new Set(objects_?.map(o => o[`${type}_external_id`]))
    
            return Array.from(objects).map((id) => {
                const object = objects_?.find(object => object[`${type}_external_id`] === id)
    
                if (!object) return
    
                return {
                    id: object[`${type}_id`],
                    name: object[`${type}_name`],
                    external_id: object[`${type}_external_id`],
                }
            }).filter(object => !!object) as any[]
        }, [objects_])
    }
}

export function useObjectsSelect<T extends "account">(
    start: Date,
    end: Date,
    attribution: [string, string],
    depth: T
): ReturnType<typeof useSelect<Option, false>>

export function useObjectsSelect<T extends Omit<ResourceDepth, ResourceDepth.AD_ACCOUNT>>(
    start: Date,
    end: Date,
    attribution: [string, string],
    depth: T
): ReturnType<typeof useSelect<Option, true>>

export function useObjectsSelect(
    start: Date,
    end: Date,
    attribution: [string, string],
    depth: ResourceDepth
) {
    const { isFetching, objectByType } = useObjects(start, end, attribution)
    const selectedObjects = useAttributionOptions(depthToKey(depth))
    const objects = objectByType(depth)

    const options = useMemo(() => objects.map(object => ({
        id: object.external_id,
        label: object.name
    })), [objects])

    const defaultSelected: typeof depth extends ResourceDepth.AD_ACCOUNT ? typeof options[number] : typeof options = useMemo(() => {
        if (!objects || objects.length === 0) return []
        if (depth === ResourceDepth.AD_ACCOUNT) {
            if (selectedObjects && selectedObjects.length > 0) {
                return options.find(object => object.id === selectedObjects[0])
            }
            return options[0] as any
        }
        else if (selectedObjects && selectedObjects.length > 0) {
            return selectedObjects.map(id => options.find(object => object.id === id) as any)
        }

        return []
    }, [selectedObjects, objects])

    const dispatch = useDispatch()

    return useSelect({
        multiple: depth !== ResourceDepth.AD_ACCOUNT,
        searchable: true,
        options,
        defaultSelected,
        onChange(...args) {
            dispatch(storeObjects([depthToKey(depth), args.map(arg => arg.id)]))
        },
    })
    
}