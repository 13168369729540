import { ResponsiveFunnel } from '@nivo/funnel';
import {ResponsiveBar} from '@nivo/bar';
import {ResponsiveWaffle} from '@nivo/waffle';
import { Box, Card, CardHeader, Grid, Typography } from '@mui/material';
import { rgbToHex } from '@mui/material';
import useNivo from 'src/components/chart/advanced/use-nivo';
import { ReactNode } from 'react';

interface Data {
  id: string;
  label: string;
  value: any
}

interface BarData {
  [prop: string]: number
}

interface Cluster {
  gender: Data[][],
  total: Data[],
  age: Data[][],
}



interface Props {
  cluster: Cluster;
  title: string | string[] | ReactNode;
  subheader?: string;
}

const colors = ['#c7e9c0', '#a1d99b', '#74c477', '#41ab5d', '#238b46'];


export default function AnalyticsClusterizedReport(props: Props) {
  const options = useNivo({})
  return (
    <Card>
      <CardHeader title={props.title} subheader={props.subheader} />
      <Box sx={{ p: 3, pb: 5 }}>
        <Grid justifyContent={'center'} container spacing={3}>
          <Grid sx={{ height: 850 }} item xs={6}>
            <ResponsiveFunnel
              {...options.funnel}
              margin={{ top: 105 }}
              data={props.cluster.total}
              colors={colors}
            />
          </Grid>
          <Grid container item xs={6} spacing={0}>
            <Grid margin={0} padding={0} item justifyContent={"center"} xs={4}>
              <Typography textAlign={"center"} justifyContent={"center"} variant="h6">Gender</Typography>
            </Grid>
            <Grid margin={0} padding={0} item justifyContent={"center"} xs={8}>
              <Typography textAlign={"center"} justifyContent={"center"} variant="h6">Age</Typography>
            </Grid>
            {props.cluster.total.map((_, idx) => {
              const first = idx === 0;
              const last = idx === props.cluster.total.length;
              return (
                <Grid
                  sx={{ height: first ? 185 : 144 }}
                  key={'charts-cluster-' + idx}
                  container
                  item
                  spacing={0}
                >
                  <Grid sx={{ height: first ? 185 : 144 }} item xs={4}>
                    <ResponsiveWaffle
                      {...options.waffle}
                      margin={{ top: first ? 70 : 25 }}
                      padding={1}
                      borderRadius={3}
                      rows={5}
                      columns={5}
                      data={props.cluster.gender[idx]}
                      total={props.cluster.gender[idx]?.reduce((prev, d) => prev + d.value, 0) || 0}
                      colors={["#5bff9a", "#007868"]}
                    />
                  </Grid>
                  <Grid sx={{ height: first ? 185 : 144 }} item xs={8}>
                    <ResponsiveBar
                      {...options.bar}
                      margin={{ top: first ? 70 : 25 }}
                      enableGridY={false}
                      enableGridX={true}
                      enableLabel={false}
                      padding={0.3}
                      axisTop={
                        first
                          ? {
                              tickSize: 0,
                              tickPadding: 10,
                              tickRotation: -90,
                            }
                          : null
                      }
                      axisLeft={null}
                      isInteractive={true}
                      motionConfig="wobbly"
                      colors={colors[idx]}
                      data={props.cluster.age[idx] as any}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}