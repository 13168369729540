import { ApexOptions } from 'apexcharts';
// @mui
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Card, { CardProps } from '@mui/material/Card';
// components
import Chart, { useChart } from 'src/components/chart';
import { Skeleton } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends CardProps {
  loading?: boolean;
  title?: string;
  subheader?: string;
  action?: React.ReactNode;
  chart: {
    stroke?: any; 
    labels?: string[];
    categories?: string[];
    colors?: string[];
    series: {
      name: string;
      type: string;
      fill?: string;
      data: number[];
    }[];
    options?: ApexOptions;
  };
}

export default function AnalyticsWebsiteVisits({ title, subheader, action, chart, ...other }: Props) {
  const { labels = [], colors, series, options, categories = [], stroke } = chart;

  const chartOptions = useChart({
    colors,
    plotOptions: {
      bar: {
        columnWidth: '16%',
      },
    },
    stroke,
    fill: {
      type: series.map((i) => i.fill) as string[],
    },
    labels,
    xaxis: Object.assign({
      categories, labels: {rotate: 0}
    }, !categories ? {
      type: 'datetime',
      labels: {
        datetimeFormatter: {
          month: "MMM"
        },
        datetimeUTC: false,
        format: "MMM"
      }
    } : {}) as any,
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (value: number) => {
          if (typeof value !== 'undefined') {
            return `${value.toFixed(0)}`;
          }
          return value;
        },
      },
      x: {
        format: "MMMM"
      }
    },
    ...options,
  });
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} action={action} />

      <Box sx={{ p: 3, pb: 1 }}>
        {
          other.loading
          ?
          <Skeleton variant="rectangular" width="100%" height={364} />
          :
          <Chart dir="ltr" type="line" series={series} options={Object.assign({}, chartOptions, series)} height={364} />
        }
      </Box>
    </Card>
  );
}
