import { FormControlLabel, Stack } from "@mui/material";

import { styled, switchClasses } from "@mui/material";

import { Switch } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectMode,  setMode } from "src/context/reducers/attribution-settings";

export default function SwitchMode({}) {
    const dispatch = useDispatch()
    const mode = useSelector(selectMode)

    const handleChange = (event: React.SyntheticEvent, checked: boolean) => {
        dispatch(setMode(checked ? 'lead' : 'sales'));
    }


    return (
        <Stack>
            <FormControlLabel control={<SwitchTextTrack checked={mode === 'lead'} onChange={handleChange} />} label="Lead mode" />
        </Stack>
    )
}


const SwitchTextTrack = styled(Switch)({
    marginLeft: 5,
    width: 56, // 80 * 0.7
    height: 33.6, // 48 * 0.7
    padding: 5.6, // 8 * 0.7
    [`& .${switchClasses.switchBase}`]: {
      padding: 7.7, // 11 * 0.7
      color: "#ff6a00",
      paddingLeft: 5.15, // 7.35 * 0.7
      paddingTop: 6.125, // 8.75 * 0.7
    },
    [`& .${switchClasses.thumb}`]: {
      width: 16.47, // 22.1 * 0.7
      height: 16.47, // 22.1 * 0.7
      margin: "2.1px",
      marginLeft: "auto",
      backgroundColor: "#fff",
    },
    [`& .${switchClasses.track}`]: {
      background: "linear-gradient(to right, error.main, error.light)",
      opacity: "1 !important",
      borderRadius: 20,
      position: "relative",
      "&:before, &:after": {
        display: "inline-block",
        position: "absolute",
        top: "50%",
        width: "50%",
        transform: "translateY(-50%)",
        color: "#fff",
        textAlign: "center",
        fontSize: "0.75rem",
        fontWeight: 500,
      },
      "&:before": {
        content: '"On"',
        left: 4,
        opacity: 0,
      },
      "&:after": {
        content: '"Off"',
        right: 3,
      },
    },
    [`& .${switchClasses.checked}`]: {
      [`&.${switchClasses.switchBase}`]: {
        color: "#185a9d",
        transform: "translateX(22.745px)", // 25.35 * 0.7
        "&:hover": {
          backgroundColor: "rgba(24,90,257,0.08)",
        },
      },
      [`& .${switchClasses.thumb}`]: {
        backgroundColor: "#fff",
      },
      [`& + .${switchClasses.track}`]: {
        background: "linear-gradient(to right, primary.main, primary.light)",
        "&:before": {
          opacity: 1,
        },
        "&:after": {
          opacity: 0,
        },
      },
    },
  });
