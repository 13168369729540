export const getProviders = () => {
    return {
        facebook: {
            name: "Meta",
            img: "/assets/icons/socials/ic_meta.svg",
            available: true,
        },
        tiktok: {
            name: "TikTok",
            img: "/assets/icons/socials/ic_tiktok.svg",
            available: true,
        },
        youtube: {
            name: "YouTube",
            img: "/assets/icons/socials/ic_youtube.svg",
            available: false,
        },
        dv_360: {
            name: "Display & Video 360",
            img: "/assets/icons/socials/ic_dv_360.svg",
            available: false,
        },
        google_ads: {
            name: "Google Ads",
            img: "/assets/icons/socials/ic_google_ads.svg",
            available: false,
        },
    }
}
