import { Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { UploadAvatar } from "src/components/upload";
import { useSnackbar } from "src/components/snackbar";
import { useCurrentUserQuery } from "src/context/api/auth";
import { useUploadMutation } from "src/context/api/medias";
import { fData } from "src/utils/format-number";
import { useLocales } from "src/locales";

export default function Avatar() {
    const [upload, uploadResult] = useUploadMutation();
    const [preview, setPreview] = useState<File | null>(null);
    const { data: user } = useCurrentUserQuery();
    const snackbar = useSnackbar();
    const {t} = useLocales()

    const handleDrop = useCallback(
        (acceptedFiles: File[]) => {
            const file = acceptedFiles[0];

            setPreview(
                Object.assign({}, file, {
                    preview: URL.createObjectURL(file),
                })
            );

            if (file && user?.profile?.id) {
                const form = new FormData();
                form.append('file', file, file.name);
                form.append('file_name', file.name);
                form.append('resource_type', 'profile_picture');
                form.append('resource_id', String(user?.profile.id));

                upload(form);
                snackbar.enqueueSnackbar(t('Uploading'), {
                    variant: 'info',
                });
            }
            else {
                snackbar.enqueueSnackbar(t('Error uploading'), {
                    variant: 'error',
                });
            }
        },
        [user, setPreview, upload]
    );


    useEffect(() => {
        if (uploadResult.isSuccess) {
            snackbar.enqueueSnackbar(t('Uploaded successfully'), {
                variant: 'success',
            });
        }
        else if (uploadResult.isError) {
            snackbar.enqueueSnackbar(t('Error uploading'), {
                variant: 'error',
            });
        }
    }
        , [uploadResult.isSuccess, uploadResult.isError, snackbar]);
    const picture = user?.profile?.picture

    const userProfilePicture = picture?.type === "s3Object" ? BENLY_API_URL + '/medias/' + picture.id : picture?.uri;


    return (
        <UploadAvatar
            maxSize={3145728}
            onDrop={handleDrop}
            file={
                preview ||
                (userProfilePicture || null)
            }
            helperText={
                <Typography
                    variant="caption"
                    sx={{
                        mt: 3,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.disabled',
                    }}
                >
                    {t("Allowed")} *.jpeg, *.jpg, *.png, *.gif
                    <br /> {t("max size of")} {fData(3145728)}
                </Typography>
            }
        />
    )
}