import { Stack } from "@mui/material";

export default function EmptyResult() {
  return (
    <Stack
      pl={3}
      my={2}
      height={500}
      direction={'row'}
      width={'100%'}
      justifyContent={'center'}
      sx={{ backgroundColor: '#f9f9f9' }}
      alignItems={'center'}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 300 350"
        preserveAspectRatio="xMidYMid meet"
        style={{ maxHeight: 350 }}
      >
        <rect width="100%" height="350" fill="#f9f9f9" />

        <g transform="translate(30, 90)">
          <rect x="0" y="0" width="90" height="30" rx="5" ry="5" fill="#b0bec5" />
          <rect x="0" y="50" width="70" height="30" rx="5" ry="5" fill="#b0bec5" />
          <rect x="0" y="100" width="110" height="30" rx="5" ry="5" fill="#b0bec5" />
        </g>

        <g transform="translate(160, 80)">
          <circle cx="50" cy="50" r="40" fill="none" stroke="#90a4ae" stroke-width="7" />
          <line
            x1="75"
            y1="75"
            x2="105"
            y2="105"
            stroke="#90a4ae"
            stroke-width="7"
            stroke-linecap="round"
          />
        </g>

        <text x="30" y="310" font-family="Arial, sans-serif" font-size="24" fill="#333">
          No Results Found
        </text>
        <text x="30" y="340" font-family="Arial, sans-serif" font-size="16" fill="#777">
          Try adjusting the filters
        </text>
      </svg>
    </Stack>
  );
}
