import React, { useState } from 'react';
import {
  Avatar,
  ButtonBase,
  MenuItem,
  TextField,
  Button,
  Divider,
  InputAdornment,
} from '@mui/material';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { mediaUrl } from 'src/utils/media-url';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Workspace } from 'src/context/api/workspaces';
import { getInitials } from 'src/utils/format-string';
import { useTranslation } from 'react-i18next';
import Iconify from 'src/components/iconify';
import { useTheme } from '@mui/material/styles';
import workspaceServices from 'src/services/workspaces';
import Scrollbar from 'src/components/scrollbar';

export default function WorkspacePopover() {
  const dispatch = useDispatch();
  const popover = usePopover();
  const workspace = workspaceServices();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();

  const [searchQuery, setSearchQuery] = useState('');

  const filteredWorkspaces = workspace.list?.filter((workspace: Workspace) =>
    workspace.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const displayedWorkspaces = filteredWorkspaces;

  const handleMenuItemClick = (newWorkspace: Workspace) => {
    popover.onClose();
    setSearchQuery('');
    workspace.current.set(newWorkspace);
  };

  const handleViewAllClick = () => {
    popover.onClose();
    navigate('/workspaces');
  };

  return (
    <>
      <ButtonBase
        onClick={popover.onOpen}
        disableRipple
        sx={{
          pl: 0,
          py: 0,
          pr: 0,
          borderRadius: 1,
          typography: 'subtitle2',
          bgcolor: 'transparent',
        }}
      >
        {workspace.current?.workspace ? (
          <>
            <Avatar
              src={
                workspace.current.workspace.logos
                  ? mediaUrl(workspace.current.workspace.logos)
                  : undefined
              }
              alt={workspace.current.workspace.name}
              sx={{
                width: 25,
                height: 25,
                p: 2,
                mr: 1,
                fontSize: 14,
              }}
            >
              {getInitials(workspace.current.workspace.name)}
            </Avatar>
            {workspace.current.workspace.name}
          </>
        ) : (
          'Select Workspace'
        )}
        <Iconify sx={{ ml: 1, color: theme.palette.text.secondary }} icon="carbon:chevron-sort" />
      </ButtonBase>

      <CustomPopover
        open={popover.open}
        onClose={() => {
          popover.onClose();
          setSearchQuery('');
        }}
        sx={{ width: 215, p: 0 }}
        arrow="top-left"
      >
        <TextField
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder={'Search workspace'}
          fullWidth
          variant="outlined"
          size="small"
          sx={{ p: 1 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon={'eva:search-fill'} />
              </InputAdornment>
            ),
          }}
        />
        <Scrollbar sx={{ maxHeight: 200 }}>
          {displayedWorkspaces?.map((workspace: Workspace) => (
            <MenuItem key={workspace.id} onClick={() => handleMenuItemClick(workspace)}>
              <Avatar
                src={workspace.logos ? mediaUrl(workspace.logos) : undefined}
                alt={workspace.name}
                sx={{
                  mr: 1,
                  width: 30,
                  height: 30,
                  p: 2,
                  fontSize: 14,
                }}
              >
                {getInitials(workspace.name)}
              </Avatar>
              {workspace.name}
            </MenuItem>
          ))}
        </Scrollbar>

        <Divider />

        <MenuItem style={{ height: 40 }} onClick={handleViewAllClick}>
          <Button fullWidth>{t('nav.workspaces.all')}</Button>
        </MenuItem>
      </CustomPopover>
    </>
  );
}
