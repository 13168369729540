import React, { useState } from 'react';
import { Card, FormControl, InputLabel, MenuItem, Select, useTheme } from '@mui/material';
import { fDate } from 'src/utils/format-time';
import { fCurrency } from 'src/utils/format-number';
import AnalyticsWebsiteVisits from 'src/sections/overview/analytics/analytics-website-visits';
import { CreativeAd } from 'src/models/creative';
import { useMarketingNumberFormat } from 'src/hooks/marketing-format';

const genders: { [key: string]: string } = {
  female: 'Female',
  male: 'Male',
  unknown: 'Unspecified',
};

const ageGroups: string[] = ['18-24', '25-34', '35-44', '45-54', '55-64', '65+'];

interface ChartSeries {
  name: string;
  type: 'line' | 'area' | 'bar';
  fill: string;
  data: number[];
}

interface ChartDataItem {
  title: string;
  action?: React.ReactNode;
  chart: {
    options?: object;
    labels: string[];
    series: ChartSeries[];
    colors: string[];
  };
}

interface ChartCardProps {
  chart: ChartDataItem;
}

export function getChartData(
  type: 'video' | 'creative' | 'landing',
  data: CreativeAd,
  labels: Date[],
  currency: string,
): ChartDataItem[] {
  const [metric, setMetric] = useState<string>('impressions');
  const theme = useTheme();
  const chartData: ChartDataItem[] = [];

  const formatter = useMarketingNumberFormat();

  const commonOptions = {
  };

  if (!data) {
    return [];
  }

  chartData.push({
    title:
      type === 'creative'
        ? 'Creative Performance'
        : type === 'video'
          ? 'Video Performance'
          : type === 'landing'
            ? 'Landing Page Performance'
            : 'Performance',
    chart: {
      options: {
        ...commonOptions,
        tooltip: undefined,
        yaxis: ["spend", "roas"].map((metric) => ({
          opposite: metric === "roas",
          labels: {
            formatter: (val: number) => {
              if (!val) {
                return "0";
              }
              return formatter(metric, val).compressed;
            },
          },
        })),
      },
      series: [
        {
          name: 'Spend',
          type: 'bar',
          fill: 'gradient',
          data: data.spend || [],
        },
        {
          name: 'ROAS',
          type: 'area',
          fill: 'gradient',
          data: data.roas || [],
        },
      ],
      colors: [theme.palette.primary.main, theme.palette.warning.main],
    },
  } as any);

  if (type === 'creative') {
    chartData.push({
      title: 'Creative Revenue',
      chart: {
        options: {
          tooltip: undefined,
          yaxis: ["conversions_value", "aov"].map((metric) => ({
            opposite: metric === "conversions_value",
            labels: {
              formatter: (val: number) => {
                if (!val) {
                  return "0";
                }
                return formatter(metric, val).compressed;
              },
            },
          })),
        },
        series: [
          {
            name: 'Purchase Conversion Value',
            type: 'area',
            fill: 'gradient',
            data: data.revenue || [],
          },
          {
            name: 'AOV',
            type: 'area',
            fill: 'gradient',
            data: data.aov || [],
          },
        ],
        colors: [theme.palette.info.main, theme.palette.error.main],
      },
    } as any);
  }

  if (type === 'video') {
    chartData.push({
      title: 'Video Plays',
      chart: {
        options: {
          ...commonOptions,
          tooltip: undefined,
          yaxis: ["video_plays"].map((metric) => ({
            opposite: metric === "video_plays",
            labels: {
              formatter: (val: number) => {
                if (!val) {
                  return "0";
                }
                return formatter(metric, val).compressed;
              },
            },
          })),
        },
        series: [
          {
            name: 'Video Plays',
            type: 'area',
            fill: 'gradient',
            data: data.video_plays || [],
          },
        ],
        colors: [theme.palette.info.main, theme.palette.error.main],
      },
    } as any);
  }

  if (type === 'landing') {
    chartData.push({
      title: 'Landing Page Conversion',
      chart: {
        options: {
          ...commonOptions,
          tooltip: undefined,
          yaxis: ["atc_ratio", "conversions"].map((metric) => ({
            opposite: metric === "conversions",
            labels: {
              formatter: (val: number) => {
                if (!val) {
                  return "0";
                }
                return formatter(metric, val).compressed;
              },
            },
          })),
        },
        series: [
          {
            name: 'Click to ATC Ratio',
            type: 'area',
            fill: 'gradient',
            data: data.atc || [],
          },
          {
            name: 'Conversions',
            type: 'area',
            fill: 'gradient',
            data: data.conversions || [],
          },
        ],
        colors: [theme.palette.info.main, theme.palette.error.main],
      },
    } as any);
  }

  chartData.push({
    title: 'Insights',
    action: (
      <FormControl sx={{ m: 2 }}>
        <InputLabel id="metric-select-label">Metric</InputLabel>
        <Select
          size="small"
          labelId="metric-select-label"
          label="Metric"
          id="metric-select"
          value={metric}
          onChange={(event) => setMetric(event.target.value as string)}
        >
          <MenuItem value="impressions">Impressions</MenuItem>
          <MenuItem value="roas">ROAS</MenuItem>
          <MenuItem value="revenue">Revenue</MenuItem>
        </Select>
      </FormControl>
    ),
    chart: {
      labels: ageGroups,
      series: ['male', 'female'].map((gender) => {
        const dataSeries: number[] = ageGroups.map((ageGroup) => {
          const value = data.breakdowns?.[ageGroup]?.[gender]?.[metric] ?? 0;
          return value;
        });
        return {
          name: genders[gender],
          type: 'bar',
          fill: 'gradient',
          data: dataSeries,
        };
      }),
      colors: [theme.palette.info.main, theme.palette.error.main],
      options: {
        tooltip: {
          y: {
            formatter: (val: number) => {
              if (!val) {
                return "0";
              }
              return formatter(metric, val).compressed;
            },
          },
        },
        yaxis: {
          labels: {
            formatter: (val: number) => {
              if (!val) {
                return "0";
              }
              return val >= 1000 ? `${(val / 1000).toFixed(1)}K` : val.toFixed(0);
            }
          },
        },
      },
    },
  });

  return chartData;
}

export function ChartCard({ chart }: ChartCardProps) {
  return (
    <Card>
      <AnalyticsWebsiteVisits title={chart.title} action={chart.action} chart={chart.chart} />
    </Card>
  );
}
