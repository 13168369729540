import { Box, Button, Card, CardContent, CardMedia, Grid, Skeleton, Stack, Tooltip, TooltipProps, Typography, alpha, useTheme } from "@mui/material";
import { useRef } from "react";
import Iconify from "src/components/iconify";
import { useCreativePreviewQuery } from "src/context/api/ad-creatives";
import { useMetricsQuery } from "src/context/api/explorer";
import { Explorer } from "src/context/api/explorer/models";
import { useMarketingNumberFormat } from "src/hooks/marketing-format";
import { useBoolean } from "src/hooks/use-boolean";
import useExplorerConfiguration, { useExplorer } from "src/hooks/use-explorer";
import CreativeDetail from "../overview/dashboards/creative-detail";
import { useLocales } from "src/locales";
import EmptyResult from "src/components/no-data/empty-result";

interface CardProps {
    id: number
    metrics: { [prop: string]: any[] }
    applyColor?: boolean
    isCopy?: boolean
    copy?: string
    name?: string
    resourceIds?: string[]
}

export default function Creative({ id: creativeId, metrics, applyColor, isCopy, copy, name, resourceIds }: CardProps) {
    const { t } = useLocales()
    const ref = useRef<HTMLDivElement>(null)
    const { data } = useCreativePreviewQuery({ creativeId }, { skip: !!isCopy })
    const [previewType, preview] = data || (isCopy ? ["copy", copy] : ["image", null] as any)

    const modal = useBoolean(false)

    const theme = useTheme()

    const formatter = useMarketingNumberFormat()

    const loading = useBoolean(isCopy ? false : true)

    const component = {
        "copy": () => <CardContent onClick={() => modal.onTrue()} sx={{ cursor: "pointer", minHeight: 500, backgroundColor: theme.palette.grey[50] }}>
            <Tooltip title={preview}>
                <Typography>
                    {preview}
                </Typography>
            </Tooltip>
        </CardContent>,
        // Try to display loader while image is not displayed
        "image": () => <CardMedia onLoad={() => loading.onFalse()} onClick={() => modal.onTrue()} sx={{ cursor: "pointer", height: loading.value ? 0 : 500, padding: -1, marginBottom: "20px" }} component={"img"} width={"100%"} height={"auto"} src={preview} />,
        "video": () => <CardMedia onLoad={() => loading.onFalse()} onClick={() => modal.onTrue()} className="container" sx={{ cursor: "pointer", zIndex: -10, position: "relative", height: loading.value ? 0 : 500, width: "100%", borderRadius: 1, padding: -1, marginBottom: "20px", border: "none" }} component={"iframe"} src={preview} />,
        "preview": () => <CardMedia onLoad={() => loading.onFalse()} onClick={() => modal.onTrue()} className="container" sx={{ cursor: "pointer", zIndex: -10, position: "relative", height: loading.value ? 0 : 500, width: "100%", borderRadius: 1, padding: -1, marginBottom: "20px", border: "none" }} component={"iframe"} src={preview} />,
        "unavailable": () => <CardMedia onLoad={() => loading.onFalse()} onClick={() => modal.onTrue()} sx={{ cursor: "pointer", height: loading.value ? 0 : 500, width: "auto", borderTopRadius: 1, borderBottomRadius: 1, padding: -1, marginBottom: "20px", position: "relative" }} component={"img"} src={"https://placehold.co/500?text=Preview+Unavailable"} />
    }

    const cardHeight = 500 * 32 * Object.keys(metrics).length + 164

    const { data: metrics_ } = useMetricsQuery({ type: "metrics" })

    const boxSx = (color: string) => {
        return {
            backgroundColor: color,
            borderRadius: "8px",
            padding: 0.5,
            display: "inline-block",
            minWidth: "60px",
            color: alpha("#0000", 0.70),
        }
    }

    const coloredMetrics = ["hook_rate"]

    const foundBoxColor = (metric: string, value: number) => {
        if (applyColor !== true || !coloredMetrics.includes(metric)) {
            return "white"
        }
        else if (value < 20) {
            return alpha("#FF5630", 0.16)
        }
        else if (value < 30) {
            return alpha("#FFAB00", 0.16)
        }
        else {
            return alpha("#00A76F", 0.16)
        }
    }

    const getMetricData = (metricKey: string) => {
        return metrics_?.find((elem) => {
            return (elem.output === metricKey || elem.metric === metricKey)
        })
    }

    const displayMetricName = (metricKey: string) => {
        const maxMetricNameLength = 29
        const metricName = getMetricData(metricKey)?.name as string
        return (metricName?.length > maxMetricNameLength) ? t(`${metricName.slice(0, maxMetricNameLength - 3)}...`) : t(metricName)
    }

    const displayMetricTooltip = (metricKey: string) => {
        const metricName = getMetricData(metricKey)?.name as string
        const metricDescription = getMetricData(metricKey)?.description as string
        return (
            <>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{t(metricName)}</Typography>
                <Typography variant="caption">{t(metricDescription)}</Typography>
            </>
        )
    }

    const OneLineTooltip = ({ title, children, fontWeight, variant, placement = "bottom"}: { title: string, children: any, fontWeight: string, variant: any , placement?: TooltipProps["placement"]}) => {
        return (
            <Tooltip title={<Typography fontWeight={fontWeight} variant={variant}>{title}</Typography>} placement={placement}>
                {children}
            </Tooltip>
        )
    }

    return (
        <Card sx={{ maxHeight: cardHeight }}>
            {loading.value && <Skeleton variant="rounded" height={500} />}
            {component[previewType as keyof typeof component]()}
            <Stack padding={2} paddingY={0} direction={"row"} alignItems={"center"}>
                <OneLineTooltip title={name || ""} variant="body2" fontWeight="normal">
                    <Typography onClick={modal.onTrue} fontWeight={"bold"}>{name?.slice(0, 35)}...</Typography>
                </OneLineTooltip>
            </Stack>
            <CardContent sx={{ zIndex: 99999999, backgroundColor: "white" }}>
                <Stack direction={"row"}>
                    <Stack direction={"column"} flexGrow={1} spacing={1} justifyContent={"left"}>
                        {Object.values(metrics).map(([key,], idx) => {
                            return (
                                <Box key={`${key}_${idx}`} sx={boxSx('white')}>
                                    <Tooltip title={displayMetricTooltip(key)}>
                                        <Typography> {displayMetricName(key)} </Typography>
                                    </Tooltip>
                                </Box>)
                        })}
                    </Stack>
                    <Stack spacing={1} direction={'column'} justifyContent={'right'}>
                        {Object.entries(metrics).map(([metric, [, value]], idx) => {
                            return (
                                <Box key={`${metric}_${value}_${idx}`} sx={boxSx(foundBoxColor(metric, value))}>
                                    <OneLineTooltip title={formatter(metric, value).raw} variant="body2" fontWeight="bold" placement="right">
                                        <Typography justifyContent={"right"} textAlign={"center"}> {formatter(metric, value).compressed} </Typography>
                                    </OneLineTooltip>
                                </Box>
                            )

                        })}
                    </Stack>
                </Stack>
                <Button onClick={modal.onTrue} sx={{ marginTop: 1 }} fullWidth variant={"outlined"} endIcon={<Iconify icon={"mdi:eye"} />}>
                    View details
                </Button>
            </CardContent>
            <CreativeDetail id={creativeId} type={"creative"} open={modal.value} onClose={modal.onFalse} resourceIds={resourceIds as string[]} />
        </Card>
    )
}


type CreativeType = "creative" | "video" | "copy"

interface ListProps {
    type: CreativeType
}


export function CreativeList({ }: ListProps) {
    const config = useExplorerConfiguration()

    const { cards: { data, isLoading, isUninitialized, isFetching, isError } } = useExplorer()

    const metricsData = (cardData: Explorer.TopAdsCreative[number]) => Object.fromEntries(((config.metrics("cards") || [])
        .map((configMetric) => [configMetric.output, [configMetric.output, Object.entries(cardData).find(([metric,]) =>
            (configMetric.output === metric) || (configMetric.metric === metric))?.[1]]]) as [string, [string, any]][]))

    const nbMetrics: number = config?.draft?.cards.metrics.length ?? 1
    const nbLines = ((data?.length ?? 0) <= 4 ? 1 : 2)
    const height: number = (500 + 32 * nbMetrics + 164) * nbLines + nbLines * 20
    const pixelHeight: string = `${height}px`;

    return (
        <Grid pl={isError ? 2 : 0} container spacing={2} sx={{ height: pixelHeight }}>
            {
                isError || (data?.length === 0 && !isFetching && !isUninitialized) && (
                    <EmptyResult />
                )
            }
            {
                isUninitialized || isFetching ? (
                    [...Array(4)].map((_, index) => (
                        <Grid item xs={12} md={3} key={index}>
                            <Skeleton variant="rounded" height={500} />
                        </Grid>
                    ))
                ) : !isError && (
                    data?.map((cardData, idx) => {
                        return (
                            <Grid key={idx} item xs={12} md={3}>
                                <Creative name={cardData.ad_name} id={cardData.creative_pk} resourceIds={cardData.breakdowns?.map((b: any) => b.id)} metrics={metricsData(cardData)} />
                            </Grid>
                        )
                    })
                )
            }
        </Grid>
    )
}