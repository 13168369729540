import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import { useWorkspace } from "src/providers/workspace";
import HookRate from "src/sections/hook-rate/views";

export function DashboardHookRate() {
  useWorkspace(true)

  const {t} = useLocales()
  return (
    <>
      <Helmet>
        <title>{t('home')}</title>
      </Helmet>

      <HookRate />
    </>
  );
}