import { BarSvgProps, BarDatum } from '@nivo/bar';
import { FunnelSvgProps, FunnelDatum } from '@nivo/funnel';
import { WaffleDatum, WaffleSvgProps } from '@nivo/waffle';
import { HeatMapSvgProps, HeatMapDatum } from '@nivo/heatmap';
import { Theme } from '@nivo/core';
import { useTheme } from '@mui/material';
import { merge } from 'lodash';
import { bgBlur } from 'src/theme/css';

interface Props<T extends object> {
  waffle?: Partial<WaffleSvgProps<WaffleDatum>>;
  bar?: Partial<BarSvgProps<BarDatum>>;
  funnel?: Partial<FunnelSvgProps<FunnelDatum>>;
  heatmap?: Partial<HeatMapSvgProps<HeatMapDatum, T>>;
}

export default function useNivo<T extends object>(props: Props<T>) {
  const theme = useTheme();

  const MARGINS = {
    top: 10,
    bottom: 10,
    left: 5,
    right: 5,
  };
  const COLORS = [
    theme.palette.primary.main,
    theme.palette.warning.main,
    theme.palette.info.main,
    theme.palette.error.main,
    theme.palette.success.main,
    theme.palette.warning.dark,
    theme.palette.success.darker,
    theme.palette.info.dark,
    theme.palette.info.darker,
  ];
  const THEME: Theme = {
    // textColor: theme.palette.text.primary,
    background: theme.palette.background.paper,
    tooltip: {
      container: {
        background: bgBlur({color: theme.palette.background.default}).backgroundColor,
        backdropFilter: bgBlur({color: theme.palette.background.default}).backdropFilter,
        borderRadius: theme.shape.borderRadius * 1.25,
      },
    },
  };
  const waffle: Partial<WaffleSvgProps<WaffleDatum> & { theme: Theme }> = {
    colors: COLORS,
    theme: THEME,
  };
  const funnel: Partial<FunnelSvgProps<FunnelDatum>> = {
    theme: THEME,
    colors: COLORS,
    motionConfig: 'wobbly',
    beforeSeparatorLength: 100,
    beforeSeparatorOffset: 20,
    afterSeparatorLength: 100,
    afterSeparatorOffset: 20,
    currentPartSizeExtension: 10,
    currentBorderWidth: 40,
    borderWidth: 20,
    valueFormat: '>-.4s',
    labelColor: {
      from: 'color',
      modifiers: [['darker', 3]],
    },
  };
  const bar: Partial<BarSvgProps<BarDatum>> = {
    theme: THEME,
    colors: COLORS,
  };
  const heatmap: Partial<HeatMapSvgProps<HeatMapDatum, T>> = {
    theme: THEME,
  };

  return merge<Props<any>, Props<any>>({ waffle, bar, funnel, heatmap }, props);
}
