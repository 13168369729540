import { Route, Routes } from 'react-router';
import AccountGeneral from 'src/pages/account';
import { useRouter } from './hooks';
import { useEffect } from 'react';
import OverviewAnalyticsPage from 'src/pages/analytics/performance';
import OverViewDatasources from 'src/sections/overview/datasources/view/datasources';
import NewDatasource from 'src/sections/overview/datasources/view/new-datasource';
import Home from 'src/pages/dashboards/home';
import { DashboardVideo } from 'src/pages/dashboards/videos';
import { DashboardCreative } from 'src/pages/dashboards/creative';
import { DashboardLanding } from 'src/pages/dashboards/landing';
import AttributionPage from 'src/pages/analytics/attribution';
import Workspace from 'src/pages/workspaces';
import Join from 'src/pages/workspaces/join';
import Users from 'src/pages/workspaces/users';
import { DashboardCopy } from 'src/pages/dashboards/copy';
import { DashboardHookRate } from 'src/pages/hook-rate';
import { DashboardAdfatgue } from 'src/pages/ad-fatigue';
import Builds from 'src/pages/builder/builds';
import Build from 'src/sections/explorer/view/explore';

function Redirect() {
  const router = useRouter()

  useEffect(() => {
    router.replace("/dash/performance/facebook")
  }, [])

  return null
}

export default function Router() {
  return (
    <Routes>
      <Route path="/dash/home" element={<Home />} />
      <Route path="/dash/video" element={<DashboardVideo />} />
      <Route path="/dash/creative" element={<DashboardCreative />} />
      <Route path="/dash/landing" element={<DashboardLanding />} />
      <Route path="/dash/copywriting" element={<DashboardCopy />} />
      <Route path="/account" element={<AccountGeneral />} />
      <Route path="/dash/attribution" element={<AttributionPage />} />
      <Route path="/apps" element={<OverViewDatasources />} />
      <Route path="/dash/performance/:type/*" element={<OverviewAnalyticsPage />} />
      <Route path="/datasources/facebook/new" element={<NewDatasource />} />
      <Route path='/workspaces' element={<Workspace />} />
      <Route path='/workspaces/:id/invitations/:token' element={<Join />} />
      <Route path='/workspaces/:id/invitations/:token' element={<Join />} />
      <Route path='/workspaces/:id' element={<Users />} />
      <Route path='/hook-rate' element={<DashboardHookRate />} />
      <Route path='/fatigue' element={<DashboardAdfatgue />} />
      <Route path="/explore" element={<Builds/>}/>
      <Route path='/explore/:name' element={<Build/>} />
      <Route path="*" element={<Redirect />} />
    </Routes>
  );
}