import { m } from 'framer-motion';
import { useState, useEffect } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
//
import Logo from '../logo';
import SvgColor from '../svg-color';
import { keyframes, Typography } from '@mui/material';

// ----------------------------------------------------------------------

// Animation should decrease width of the bar and move it to the left then increase width then re decrease width and move it to the right
const animation = keyframes`
  0% {
    width: 5px;
    transform: translateX(0);
  }
  50% {
    width: calc(62 * 2px);
    transform: translateX(0);
  }
  100% {
    width: 5px;
    transform: translateX(calc(62 * 2px - 5px));
  }
`;

export default function SplashScreen({ sx, ...other }: BoxProps) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box display={'flex'} alignItems={'center'} gap={1}>
        <Box
          component={'div'}
          width={62 * 2}
          height={5}
          borderRadius={2}
          sx={{
            mb: 1.7,
            mt: 'auto',
            p: 0,
            pt: 'auto',
          }}
        >
          <Box
            component={'div'}
            width={5}
            height={5}
            borderRadius={2}
            sx={{ backgroundColor: 'primary.main', animation: `${animation} 1s infinite` }}
          />
        </Box>
        <Typography ml={-62 * 2.2 + 'px'} color="black" fontSize={62}>
          benly
        </Typography>
        <Box
          mb={2}
          mt={'auto'}
          ml={-1}
          p={0}
          pt={'auto'}
          component={'div'}
          width={7}
          height={7}
          borderRadius={10}
          sx={{ backgroundColor: 'primary.main', mb: 1, mt: 'auto', ml: -2.5, p: 0, pt: 'auto' }}
        />
      </Box>
    </Box>
  );
}
