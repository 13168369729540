import { Action, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./state";
import { Workspace } from "src/context/api/workspaces";
import { RootState } from "src/context/store";

const appSlice = createSlice({
    name: "appSettings",
    initialState,
    reducers: {
        setCurrentWorkspace: (state, action: PayloadAction<Workspace|undefined|null>) => {
            state.currentWorkspace = action.payload;
        },
        resetCurrentWorkspace: (state) => {
            state.currentWorkspace = null;
        }
    },
});


export default appSlice.reducer;

export const { setCurrentWorkspace, resetCurrentWorkspace } = appSlice.actions;
