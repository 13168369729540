import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GenericSelect from 'src/components/generic-select';
import ButtonSelect from 'src/components/generic-select/button-select';
import { Tags, useGetTagsQuery } from 'src/context/api/tags';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import {
  selectAdGroup,
  selectAdType,
  setAdGroup,
  setAdType,
  setLastUpdate,
  selectTagIds,
  setTagIds,
} from 'src/context/reducers/explorer/slice';

interface Option {
  id: any;
  label: string;
}

export default function Filters() {
  const dispatch = useDispatch();

  const adType = useSelector(selectAdType);
  const group = useSelector(selectAdGroup);

  const workspace = useCurrentWorkspace();

  const { data: tags } = useGetTagsQuery({
    workspace: workspace?.id || -1,
  }) as { data: Tags };
  
  const arangedTags: Option[] = useMemo(() => {
    return (tags || []).map((tag) => {
      return {
        label: tag.name,
        id: tag.id,
      };
    });
  }, [tags]);

  const selectedTags: number[] = useSelector(selectTagIds);

  return (
    <>
      <GenericSelect variant="light" />
      <ButtonSelect
        multiple={true}
        label="Ad type"
        value={adType}
        options={[
          {
            label: 'Video',
            id: 'VIDEO',
          },
          {
            label: 'Image',
            id: 'IMAGE',
          },
          {
            label: 'Carousel',
            id: 'CAROUSEL',
          },
          {
            label: 'Feed',
            id: 'DPA',
          },
          {
            label: 'Instant Experience',
            id: 'INSTANT_EXPERIENCE',
          },
        ]}
        onApply={(value) => {
          dispatch(setAdType(value as any));
          dispatch(setLastUpdate('all'));
        }}
      />
      <ButtonSelect
        multiple={false}
        label="Group by"
        value={group}
        options={[
          {
            label: 'Creative',
            id: 'creative',
          },
          {
            label: 'Ad name',
            id: 'ad_name',
          },
        ]}
        onApply={(value) => {
          dispatch(setAdGroup(value as any));
          dispatch(setLastUpdate('all'));
        }}
      />
      <ButtonSelect
        multiple={true}
        label="Tags"
        value={selectedTags}
        options={arangedTags || []}
        onApply={(value) => {
          dispatch(setTagIds(value));
        }}
      />
    </>
  );
}
