import {
  Box,
} from '@mui/material';
import { Container } from '@mui/system';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import Connect from '../connect-form';

export default function NewDatasource() {
  const settings = useSettingsContext();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={'Datasources'}
        links={[{ name: 'Datasources', href: "/apps" }, {name: 'New'}]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <Box margin={{ top: 2, bottom: 4, left: 0, right: 0 }}>
        <Connect/>
      </Box>
    </Container>
  );
}
