import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
import { useWorkspace } from "src/providers/workspace";
import { TopCopyDashboard } from "src/sections/overview/dashboards/views/dashboard-top-copy";

export function DashboardCopy() {
  useWorkspace(true)

  const { t } = useLocales()
  return (
    <>
      <Helmet>
        <title>{t('home')}</title>
      </Helmet>

      <TopCopyDashboard />
    </>
  );
}