import { useState, useEffect, useMemo, useRef } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useSettingsContext } from 'src/components/settings';
import { useWorkspace } from 'src/providers/workspace';
import QuickMetrics from '../quick-metrics';
import { CreativeList } from '../card';
import Analytics from '../analytics';
import { Table } from '../table';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import useExplorerConfiguration, { useProcessConfiguration, useExplorer } from 'src/hooks/use-explorer';
import Filters from '../filters';
import { SplashScreen } from 'src/components/loading-screen';
import { useDispatch } from 'react-redux';
import { resetAttributionSettings } from 'src/context/reducers/attribution-settings';
import ConditionBuilder from '../condition-builder';
import { cloneDeep } from 'lodash';
import { Explorer } from 'src/context/api/explorer/models';
import normalizeQuery from 'src/utils/normalize-query';
import InfoBubble from 'src/components/info-bubble';

export default function Explore() {
  useWorkspace(true);

  const dispatch = useDispatch();
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const [infoBubbleVisible, setInfoBubbleVisible] = useState({
    firstTime: true,
    noResults: true,
  });

  useEffect(() => {
    dispatch(resetAttributionSettings());
  }, []);

  const settings = useSettingsContext();
  const theme = useTheme();
  const toggleFilters = useBoolean(true);
  const ref = useRef<HTMLDivElement>(null);

  const { draft, setDraft, ...config } = useExplorerConfiguration();
  const {
    table: { data, isError, isFetching },
  } = useExplorer();
  const { processed } = useProcessConfiguration(config.raw);

  const onQueryChange = (index: number, query: any) => {
    const newDraft = cloneDeep(draft);

    if (!newDraft) return;

    if (!newDraft.query[index]) {
      newDraft.query.push(query);
    } else {
      newDraft.query[index] = query;
    }
    setDraft(newDraft);
  };

  const onAdd = () => {
    const newQuery = {
      logical_operator: null,
      name: "",
      query: [
        {
          metric: "Select",
          op: "",
          values: [
            {
              logical_operator: null,
              op: "",
              payload: "",
              type: "literal",
              exact_type: "",
            },
          ],
        },
      ],
    };
    onQueryChange(draft?.query.length ?? 0, newQuery);
  };

  const handleAddFilter = (groupIndex: number) => {
    const newDraft = cloneDeep(draft);

    if (!newDraft || !newDraft.query[groupIndex]) {
      return;
    }

    const group = newDraft.query[groupIndex];
    if ('logical_operator' in group) {
      group.query.push({
        logical_operator: null,
        metric: "Select",
        op: null,
        values: [
          {
            op: "",
            payload: "",
            type: "literal",
            exact_type: "",
          },
        ],
      } as any);
      group.logical_operator = Explorer.ConditionalOperator.AND;
      onQueryChange(groupIndex, {
        ...group,
        query: group.query,
      });
    }
  };

  const handleAddValue = (groupIndex: number, metricIndex: number) => {
    const newDraft = cloneDeep(draft);

    if (!newDraft || !newDraft.query[groupIndex]) return;

    const group = newDraft.query[groupIndex];
    if ('query' in group && Array.isArray(group.query)) {
      const metric = group.query[metricIndex];

      if ('values' in metric) {
        metric.values.push({
          op: "",
          payload: '',
          type: "literal",
          exact_type: "",
          logical_operator: null,
        });
      }

      group.query[metricIndex] = {
        ...metric,
        logical_operator: Explorer.ConditionalOperator.OR,
      };

      onQueryChange(groupIndex, group);
    }
  };

  const onDeleteValue = (groupIndex: number, metricIndex: number, valueIndex: number) => {
    if (draft) {
      const newDraft = cloneDeep(draft); // Clone the draft for immutability
      const group = newDraft.query[groupIndex];

      if ('query' in group && Array.isArray(group.query)) {
        const metric = group.query[metricIndex];

        if ('values' in metric && Array.isArray(metric.values)) {
          // Remove the specified value by its index
          metric.values.splice(valueIndex, 1);

          // If the `values` array becomes empty, remove the metric
          if (metric.values.length === 0) {
            group.query.splice(metricIndex, 1);

            // If the group becomes empty
            if (group.query.length === 0) {
              // Check if it's the last group
              if (newDraft.query.length === 1) {
                // Clear the group instead of deleting it
                group.query.push({
                  logical_operator: null,
                  metric: "Select",
                  op: null,
                  values: [
                    {
                      op: "",
                      payload: "",
                      type: "literal",
                      exact_type: "",
                    },
                  ],
                } as any);
                group.logical_operator = null;
                group.name = "";
              } else {
                // Otherwise, remove the group
                newDraft.query.splice(groupIndex, 1);
              }
            }
          }
        }

        setDraft(newDraft); // Update the state with the modified draft
      } else {
        console.error(`Invalid group or query structure at index ${groupIndex}`);
      }
    }
  };

  const onDelete = (groupIndex: number, metricIndex: number) => {
    if (draft) {
      const newDraft = cloneDeep(draft);
      const group = newDraft.query[groupIndex];

      if ('query' in group && Array.isArray(group.query)) {
        group.query.splice(metricIndex, 1);

        // If the group becomes empty, remove the group
        if (group.query.length === 0) {
          newDraft.query.splice(groupIndex, 1);
        }

        setDraft(newDraft);
      }
    }
  };

  const mode = useMemo(() => {
    return config.raw?.mode || 'analysis';
  }, [config.raw]);

  useEffect(() => {
    if (config.raw) {
      const normalizedRaw = {
        ...config.raw,
        query: normalizeQuery(cloneDeep(config.raw.query)), // Deep clone before normalization
      };
      setDraft(normalizedRaw);
      setInfoBubbleVisible((prev) => ({ ...prev, noResults: true }));
      setHasFetchedData(true);
    }
  }, [config.raw]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Stack sx={{
        height: "73px",
        alignItems: 'flex-start',
        justifyContent: 'space-between',
      }}>
        <TextField
          sx={{
            width: '300px',
          }}
          InputProps={{
            disableUnderline: true,
            sx: {
              '& .MuiInputBase-input': {
                fontWeight: 'bold',
                fontSize: '24px',
                color: theme.palette.text.primary,
              },
            },
          }}
          variant="standard"
          placeholder="Name your dashboard"
          value={draft?.name || ''}
          onChange={(event) => {
            setDraft({ ...draft, name: event.target.value } as any);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              (event.target as HTMLInputElement).blur();
            }
          }}
        />
        <TextField
          sx={{
            width: '300px',
          }}
          InputProps={{
            disableUnderline: true,
            sx: {
              '& .MuiInputBase-input': {
                fontSize: '14px',
                color: theme.palette.text.primary,
              },
            },
          }}
          variant="standard"
          placeholder="Add a description to your dashboard"
          value={draft?.description || ''}
          onChange={(event) => {
            setDraft({ ...draft, description: event.target.value } as any);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              (event.target as HTMLInputElement).blur();
            }
          }}
        />
      </Stack>
      <Stack component={'div'} ref={ref} spacing={2} position={'relative'}>
        <Stack
          mt={4}
          p={3}
          spacing={1}
          sx={{ backgroundColor: theme.palette.grey[200], borderRadius: 2 }}
          justifyContent={'flex-start'}
          direction={{ xs: 'column', md: 'row' }}
        >
          {config.loading || !processed ? <SplashScreen /> : <Filters />}
          <Button
            onClick={toggleFilters.onToggle}
            sx={{ marginLeft: 1 }}
            variant={'contained'}
            endIcon={<Iconify icon={'mdi:filter-outline'} />}
          >
            Filters
          </Button>
        </Stack>
        <Accordion
          sx={{ '&.MuiAccordion-root': { '::before': { display: 'none' } } }}
          expanded={toggleFilters.value}
          defaultExpanded
        >
          <AccordionSummary
            sx={{ '&.MuiAccordionSummary-root': { display: 'none' } }}
          ></AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <ConditionBuilder
              query={draft?.query || []}
              onAdd={onAdd}
              onDelete={onDelete}
              onQueryChange={onQueryChange}
              handleAddFilter={handleAddFilter}
              handleAddValue={handleAddValue}
              onDeleteValue={onDeleteValue}
            />
          </AccordionDetails>
        </Accordion>

        {(config.raw?.mode === 'comparison' && config.raw?.query?.length === 0) &&
          <InfoBubble onClose={() => setInfoBubbleVisible(prev => ({ ...prev, firstTime: false }))}>
            <Typography>
              Compare groups of creatives based on campaign names, ad sets, ads, or ad types. Group and analyze data to understand performance differences and identify trends across your marketing efforts. Perfect for comparative analysis and optimization.
            </Typography>
          </InfoBubble>
        }
        {((hasFetchedData && !isFetching && !data?.length || isError) && infoBubbleVisible.noResults) &&
          <InfoBubble onClose={() => setInfoBubbleVisible(prev => ({ ...prev, noResults: false }))}>
            <Typography variant="h6">
              No Results Found
            </Typography>
            <Typography>
              It looks like there are no data matching your filters or conditions.
            </Typography>
            <Typography>
              Here's what you can do:
              <li>Double-check your filters and ensure they align with your intended search criteria.</li>
              <li>Try broadening your conditions to capture more data.</li>
            </Typography>
          </InfoBubble>
        }

        {mode === 'analysis' && (
          <Stack direction={'column'} spacing={2}>
            <QuickMetrics />
            <CreativeList type="creative" />
            <Analytics sx={{ mt: 2 }} />
            <Table />
          </Stack>
        )}
      </Stack>
      {(mode === 'comparison' && config.raw?.query?.length > 0) && (
        <Stack direction={'column'} spacing={2}>
          <Analytics sx={{ mt: 2 }} />
          <Table />
        </Stack>
      )}
    </Container>
  );
}


