import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Workspace } from "src/context/api/workspaces";
import { useLocales } from "src/locales";
import * as Yup from "yup";

interface WorkspaceCreateOrUpdateRequest {
    name: string;
    editors?: string[];
}

interface WorkspaceDatasourceConnectRequest {
    datasources: {value: number}[];
    workspace: Workspace | null;
}

export function useWorkspaceCreateOrUpdateForm() {
    const {t} = useLocales()
    const defaultValues: WorkspaceCreateOrUpdateRequest = {
        name: '',
        editors: []
    }

    const schema = Yup.object({
        name: Yup.string().required(t("Name must not be empty.")),
        editors: Yup.array(Yup.string().required()).optional()
    })

    return useForm({
        defaultValues,
        resolver: yupResolver(schema)
    })
}

export function useWorkspaceDatasourceForm() {
    const defaultValues: WorkspaceDatasourceConnectRequest = {
        datasources: [],
        workspace: null,
    }

    const schema = Yup.object({
        datasources: Yup.array(Yup.object({value: Yup.number()})),
        workspace: Yup.mixed().required().nullable()
    })

    return useForm({
        defaultValues,
        resolver: yupResolver(schema)
    })
}