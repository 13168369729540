import { Card, Stack, Typography, Box, Divider, Link, CardActionArea, Select, MenuItem, CircularProgress } from '@mui/material';
import { DetailResponse, useCreativePreviewQuery } from 'src/context/api/ad-creatives';
import { useEffect, useRef, useState } from 'react';
import TagManagementModal from 'src/components/tag-manager/tag-management-modal';

interface OverviewProps {
  id: number;
  detail?: DetailResponse;
}

export default function CreativeDetailOverview({ id, detail }: OverviewProps) {
  const cardRef = useRef<HTMLDivElement | null>(null);
  const [cardWidth, setCardWidth] = useState(0);
  const [adFormat, setAdFormat] = useState("FACEBOOK_PROFILE_FEED_MOBILE");

  useEffect(() => {
    if (cardRef.current) {
      setCardWidth(cardRef.current.offsetWidth);
    }
  }, [cardRef]);

  useEffect(() => {
    const handleResize = () => {
      if (cardRef.current) {
        setCardWidth(cardRef.current.offsetWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const {
    data: preview = [],
    isLoading,
    isUninitialized,
    isFetching,
  } = useCreativePreviewQuery({
    creativeId: id,
    previewType: 'preview',
    height: 675,
    width: cardWidth,
    ad_format: adFormat,
  });

  const [type, previewUrl] = preview || [];

  if (!detail) {
    return null;
  }

  const previewComponent = (<Box
    sx={{
      width: 345,
      height: 750,
      border: 'none',
    }}
  >
    <iframe
      src={previewUrl}
      width="345"
      height="750"
      style={{ border: 'none', width: '100%', height: '100%' }}
      title="Facebook Ads Preview"
    ></iframe>
  </Box>);

  return (
    <Card ref={cardRef} sx={{ width: '100%' }}>
      <Box display="flex" alignItems="center" sx={{ p: 2}}>
      <TagManagementModal externalAdId={detail.ad_ids[0]}/>
      </Box>
      <Box sx={{ p: 2 }}>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle2" color="textSecondary">
            Ad ID's
          </Typography>
        </Box>
        {
          detail.ad_ids.map((adId) => (
            <Link
              key={adId}
              color="primary"
              underline="always"
              sx={{ fontWeight: 'bold', fontSize: '16px', mr: 1 }}
            >
              {adId}
            </Link>
          ))
        }
      </Box>
      <Divider />
      {(isLoading || isUninitialized || isFetching ) && (
        <Box width={345} height={500} display="flex" alignItems="center" justifyContent="center">
              <CircularProgress sx={{ color: 'textSecondary' }}/>
        </Box>
      )}
      {previewComponent}
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: 2 }}>
        <Typography variant="subtitle2" color="textSecondary">
          Preview Type
        </Typography>
        <Select value={adFormat} onChange={(e) => setAdFormat(e.target.value)} fullWidth>
          <MenuItem value="FACEBOOK_PROFILE_FEED_MOBILE">FACEBOOK FEED</MenuItem>
          <MenuItem value="FACEBOOK_STORY_MOBILE">FACEBOOK STORIES</MenuItem>
          <MenuItem value="FACEBOOK_REELS_MOBILE">FACEBOOK REELS</MenuItem>
        </Select>
      </Stack>
    </Card>
  );
}
