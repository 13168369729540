import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, IconButton, InputAdornment, Link, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useForm } from 'react-hook-form';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import Iconify from 'src/components/iconify';
import { useLocales } from 'src/locales';
import { RouterLink } from 'src/routes/components';
import * as Yup from 'yup';
import * as jose from "jose"
import { useSearchParams } from 'src/routes/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResetPasswordMutation } from 'src/context/api/auth';
import { useEffect } from 'react';

export default function ResetPassword() {
    const { t } = useLocales();
    const schema = Yup.object({
        password: Yup.string().min(8).required('Password is required'),
        password_confirmation: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match').required('Password confirmation is required'),
        token: Yup.string().required()
    });
    const [reset, resetResult] = useResetPasswordMutation()
    const newPasswordToggle = useBoolean(false)
    const confirmPasswordToggle = useBoolean(false)
    const params = useSearchParams()
    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            password: '',
            password_confirmation: '',
            token: params.get("token") as string
        },
    });
    const {
        formState: { isSubmitting },
    } = methods;


    useEffect(() => {
        if (resetResult.isSuccess) {
            window.location.href = "/"
        }
    }, [resetResult])

    return (
        <FormProvider methods={methods} onSubmit={methods.handleSubmit(reset)}>
            <Stack spacing={2} sx={{ mb: 5 }}>
                <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
                    <Typography variant="h4">
                        Request sent successfully!
                    </Typography>

                    <Stack direction="row" spacing={0.5}>
                        <Typography textAlign={"center"} fontSize={12} variant="subtitle2">
                            Please enter your new password.
                        </Typography>
                    </Stack>
                    {
                        resetResult.isError && (

                            <Stack direction="row" spacing={0.5}>
                                <Alert severity='error'>
                                    Password and confirmation must be equivalent
                                </Alert>
                            </Stack>
                        )
                    }
                </Stack>
                <TextField name='email' label={t('auth.form.signin.Email')}
                    disabled
                    value={params.has("token") ? jose.decodeJwt(params.get("token") as string).email as string : ""} />
                <RHFTextField type={newPasswordToggle.value ? "text" : "password"} fullWidth name='password'
                    autoComplete="new-password" label="Password" helperText="Must be minimum 8+"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={newPasswordToggle.onToggle} edge="end">
                                    <Iconify icon={newPasswordToggle.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }} />
                <RHFTextField type={confirmPasswordToggle.value ? "text" : "password"} fullWidth name='password_confirmation'
                    autoComplete="new-password" label="Password confirmation"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={confirmPasswordToggle.onToggle} edge="end">
                                    <Iconify icon={confirmPasswordToggle.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }} />
                <LoadingButton
                    color="inherit"
                    size="large"
                    type="submit"
                    variant="contained"
                    fullWidth
                    loading={isSubmitting}
                >
                    {t('auth.form.resquest_reset')}
                </LoadingButton>
            </Stack>
            <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
                <Iconify icon={"heroicons:chevron-left-16-solid"} /> <Link fontSize={13} fontWeight={"bold"} sx={{ textDecoration: "none" }} color={"black"} href="/auth/signin">Return to signin</Link>
            </Stack>
        </FormProvider>
    );
}
