import { Card, CardHeader, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useCallback, useState } from "react";
import Scrollbar from "src/components/scrollbar";
import { emptyRows, getComparator, TableEmptyRows, TableHeadCustom, TableNoData, TablePaginationCustom, useTable } from "src/components/table";
import { useTableQuery } from "src/context/api/hook-rate";
import { useLocales } from "src/locales";
import { useMarketingNumberFormat } from "src/hooks/marketing-format";
import TableToolbar from "./table-toolbar";
import { get } from "lodash";
import { useCreativePreviewQuery } from "src/context/api/ad-creatives";


interface Props {
    data?: any
    TABLE_HEAD: {
        id: string;
        label: string;
        format?: any;
        alignRight: boolean;
        sort: boolean;
    }[],
    applyFilters: (...args: any[]) => any
    filters: any;
    sx?: any;
    title?: string
}

export function VideoTable({
    data,
    TABLE_HEAD,
    applyFilters,
    filters: filters_,
    sx,
    title
}: Props) {
    const { t } = useLocales()
    const table = useTable({ defaultRowsPerPage: 20, defaultDense: true });
    const [filters, setFilters] = useState(filters_);
    const formatter = useMarketingNumberFormat()

    const dataFiltered = applyFilters({
        inputData: [].concat(data) || [],
        comparator: getComparator(table.order, table.orderBy),
        filters: filters as any,
    })

    const handleFilters = useCallback(
        (name: string, value: string | string[]) => {
            table.onResetPage();
            setFilters((prevState: any) => ({
                ...prevState,
                [name]: value,
            }));
        },
        [table]
    );

    const denseHeight = table.dense ? 52 : 72;

    const renderTable = (
        <TableContainer>
            <Scrollbar>
                <Table
                    size={table.dense ? 'small' : 'medium'}
                    sx={{ minWidth: 960 }}
                >
                    <TableHeadCustom
                        order={table.order}
                        orderBy={table.orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={Object.keys(data || {})?.length || 0}
                        onSort={table.onSort}
                    />

                    <TableBody>
                        {
                            dataFiltered.slice(
                                table.page * table.rowsPerPage,
                                table.page * table.rowsPerPage + table.rowsPerPage
                            ).map((row: any, idx: number) =>
                            (
                                <TableRow key={idx}>
                                    {TABLE_HEAD.map(({ id, alignRight, format }, idx) => (<TableCell key={idx} align={alignRight ? "right" : "left"}>{format === true ? formatter(id, parseFloat(get(row, id)) || 0).compressed : format ? format(row) : get(row, id)}</TableCell>))}
                                </TableRow>
                            ))
                        }
                        <TableEmptyRows
                            height={denseHeight}
                            emptyRows={emptyRows(table.page, table.rowsPerPage, Object.keys(data || {}).length || 0)}
                        />

                        <TableNoData notFound={!dataFiltered.length} />
                    </TableBody>
                </Table>
            </Scrollbar>
        </TableContainer>
    )

    return (
        <>
            <Card sx={{ width: "100%", ml: 2, ...sx }}>
                <CardHeader title={title} />
                <TableToolbar
                    filters={filters}
                    onFilters={handleFilters}
                />
                {
                    dataFiltered?.length === 0 ? (
                        <Skeleton variant="rectangular" width="100%">
                            {renderTable}
                        </Skeleton>
                    ) : (
                        renderTable
                    )
                }
                <TablePaginationCustom
                    count={dataFiltered.length}
                    page={table.page}
                    rowsPerPage={table.rowsPerPage}
                    onPageChange={table.onChangePage}
                    onRowsPerPageChange={table.onChangeRowsPerPage}
                    rowsPerPageOptions={[10, 20, 50]}
                />
            </Card>
        </>
    )
}